import React from 'react';

import { FirebaseApp, initializeApp } from '@firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getFunctions, httpsCallable, HttpsCallableOptions } from '@firebase/functions';

import { isDevEnv } from '~/helpers/common';
import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';
import { addSentryBreadcrumb } from '~/helpers/monitoring/common';

import { CloudFunction } from '~/constants/functionName';

// TODO [STRL-1368]: check Security Rules to ensure the keys don't expose excessive access
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMEN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID, // for Firebase JS SDK v7.20.0 and later, measurementId is optional
};

const DEFAULT_CLOUD_FUNCTION_REGION = 'europe-west2';

let app: FirebaseApp | undefined;

export function useInitFirebase() {
  const [initFinished, setInitFinished] = React.useState<boolean>(false);

  React.useEffect(() => {
    // Initialize Firebase
    app = initializeApp(firebaseConfig);
    // will be enabled again in https://linear.app/joinfluffy/issue/INSR-264
    // initAppCheck(app);
    setInitFinished(true);
  }, []);

  return { initFinished };
}

export function getHttpsCallableFunction(functionName: CloudFunction, options?: HttpsCallableOptions) {
  addSentryBreadcrumb({
    category: 'event_breadcrumb',
    message: 'Get https callable function',
    data: {
      functionName,
      options,
    },
    level: 'info',
  });

  const functions = getFunctions(app, DEFAULT_CLOUD_FUNCTION_REGION);
  return httpsCallable(functions, functionName, options);
}

function initAppCheck(app: FirebaseApp | undefined) {
  if (!process.env.REACT_APP_FIREBASE_APP_CHECK_PUBLIC_SITE_KEY) {
    captureAndLogException(
      `[Firebase AppCheck]: env variable "REACT_APP_FIREBASE_APP_CHECK_PUBLIC_SITE_KEY" is not found`,
      'Error',
    );
    return;
  }

  if (isDevEnv()) {
    if (!process.env.REACT_APP_FIREBASE_APP_CHECK_DEBUG_TOKEN) {
      captureAndLogException(
        `[Firebase AppCheck]: env variable "REACT_APP_FIREBASE_APP_CHECK_DEBUG_TOKEN" is not found`,
        'Error',
      );
      return;
    }

    // @ts-ignore
    window.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_FIREBASE_APP_CHECK_DEBUG_TOKEN;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(process.env.REACT_APP_FIREBASE_APP_CHECK_PUBLIC_SITE_KEY),
    // (Optional argument) if true, the SDK automatically refreshes App Check tokens as needed
    isTokenAutoRefreshEnabled: true,
  });
}
