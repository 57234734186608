import React from 'react';
import { useField } from 'formik';

import { BinaryQuestion } from '~/components/questions/BinaryQuestion';

import {
  EXISTING_POLICY_FINISH_DATE,
  HAS_ALREADY_INSURANCE_POLICY,
  QuoteFormValues,
} from '~/components/quote-form/quote-form.schema';

import { useInsuringPetsCount } from '~/hooks/insurance-data-provider/useInsuringPetsCount';

interface HasAlreadyAPolicyQuestionProps {
  onActionButtonPress: (value: boolean) => void;
}

export const HasAlreadyAPolicyQuestion: React.FC<HasAlreadyAPolicyQuestionProps> = function HasAlreadyAPolicyQuestion({
  onActionButtonPress,
}) {
  const petsCount = useInsuringPetsCount();

  const [, , existingPolicyFinishDateFieldHelpers] =
    useField<QuoteFormValues[typeof EXISTING_POLICY_FINISH_DATE]>(EXISTING_POLICY_FINISH_DATE);

  const onAnswerSelect = React.useCallback(
    (value: boolean) => {
      if (!value) {
        existingPolicyFinishDateFieldHelpers.setValue(null);
      }

      onActionButtonPress(value);
    },
    [existingPolicyFinishDateFieldHelpers, onActionButtonPress],
  );

  const question = React.useMemo(
    () => `Do you already have an insurance policy elsewhere for your ${petsCount > 1 ? 'pets' : 'pet'}?`,
    [petsCount],
  );

  return (
    <BinaryQuestion<boolean>
      name={HAS_ALREADY_INSURANCE_POLICY}
      questionText={question}
      options={[
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ]}
      onSelect={onAnswerSelect}
    />
  );
};
