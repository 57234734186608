import type { QuoteFormValues } from '~/components/quote-form/quote-form.schema';
import {
  POSTCODE_FIELD,
  ADDRESS_LINE_1_FIELD,
  ADDRESS_LINE_2_FIELD,
  ADDRESS_LINE_3_FIELD,
} from '~/components/quote-form/quote-form.schema';

export type AddressSearchMode = 'lookup' | 'manual';
export const SEARCH_MODE_TOGGLE_OPTION: Record<AddressSearchMode, AddressSearchMode> = {
  lookup: 'manual',
  manual: 'lookup',
};
export const SEARCH_MODE_TOGGLE_LABEL: Record<AddressSearchMode, string> = {
  lookup: 'Enter address manually',
  manual: 'Back to lookup address',
};

export const MANDATORY_ADDRESS_LINE_PARAMS: {
  formikName: keyof QuoteFormValues;
  placeholder?: string;
}[] = [
  { formikName: POSTCODE_FIELD, placeholder: 'Postcode' },
  { formikName: ADDRESS_LINE_1_FIELD, placeholder: 'Address' },
];

export const ADDRESS_LINE_PARAMS: {
  formikName: keyof QuoteFormValues;
  placeholder?: string;
}[] = [
  ...MANDATORY_ADDRESS_LINE_PARAMS,
  { formikName: ADDRESS_LINE_2_FIELD, placeholder: 'Address line 2' },
  { formikName: ADDRESS_LINE_3_FIELD, placeholder: 'Address line 3' },
];
