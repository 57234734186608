import { LtqSearchParams } from '~/constants/attribution/ltqSearchParams';
import { OwnAttributionSearchParams } from '~/constants/attribution/ownAttributionSearchParams';

// we don't remove own "source" param, because it's handled in another place
export const ATTRIBUTION_SEARCH_PARAMS_TO_CLEAN = [
  OwnAttributionSearchParams.utmSource,
  OwnAttributionSearchParams.utmMedium,
  OwnAttributionSearchParams.utmCampaign,
  OwnAttributionSearchParams.utmContent,
  LtqSearchParams.utmSource,
  LtqSearchParams.utmMedium,
  LtqSearchParams.utmCampaign,
  LtqSearchParams.utmContent,
  LtqSearchParams.source,
  LtqSearchParams.referrer,
  LtqSearchParams.fluffyReferralId,
];
