import { useSearchParams } from 'react-router-dom';

import { ExternalPurchaseUrlSearchParams } from '@shared/constants/urlSearchParams';

export function useSearchParamsPolicyNo(): Optional<string> {
  const [searchParams] = useSearchParams();

  if (!searchParams.has(ExternalPurchaseUrlSearchParams.PolicyNo)) {
    return;
  }

  return searchParams.get(ExternalPurchaseUrlSearchParams.PolicyNo)!;
}
