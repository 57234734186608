import { UserTraits } from '@segment/analytics-core/src/events/interfaces';

import { getSegmentTraitsStoredLocally } from '~/helpers/analytics/localSegmentTraits';
import { identify } from '~/helpers/analytics/identify';

import { LocalStorageKey } from '~/constants/localStorageKey';

export interface AttributionQueryParams {
  referrer: string | null;
  utmSource: string | null;
  utmMedium: string | null;
  utmCampaign: string | null;
  utmContent: string | null;
  source: string | null;
  originalSource: string | null;
  fluffyReferralId: string | null;
}

export async function trackFirstTouchAttributionIfNeeded(
  attributionEntries: Array<[keyof AttributionQueryParams, string | null]>,
) {
  const segmentAnonymousId = localStorage.getItem(LocalStorageKey.SegmentAnonymousId);
  const segmentUserId = localStorage.getItem(LocalStorageKey.SegmentUserId);

  // if "null", something is broken with Segment, because anonymousId should be set by default right from the beginning,
  // but we will try to save the data
  const currentUserId = segmentUserId || segmentAnonymousId;

  const segmentLocalTraits = getSegmentTraitsStoredLocally();

  if (currentUserId && hasFirstTouchAttribution(segmentLocalTraits)) {
    return;
  }

  await trackTouchAttribution(attributionEntries, 'firstTouch');
}

export function trackLastTouchAttribution(attributionEntries: Array<[keyof AttributionQueryParams, string | null]>) {
  return trackTouchAttribution(attributionEntries, 'lastTouch');
}

type AttributionTraitsPrefix = 'firstTouch' | 'lastTouch';

async function trackTouchAttribution(
  attributionEntries: Array<[keyof AttributionQueryParams, string | null]>,
  traitsPrefix: AttributionTraitsPrefix,
) {
  // transform Array with traits entries to Object traits
  const touchAttributionTraits = attributionEntries.reduce((result, [key, value]) => {
    result[`${traitsPrefix}_${key}`] = value;
    return result;
  }, {} as Record<`${AttributionTraitsPrefix}_${keyof AttributionQueryParams}`, string | null>);

  await identify(undefined, touchAttributionTraits);
}

const FIRST_TOUCH_TRAITS_PREFIX: `${AttributionTraitsPrefix}_` = 'firstTouch_';

function hasFirstTouchAttribution(traits: UserTraits | null): boolean {
  if (!traits) {
    return false;
  }

  return Object.keys(traits).some((key) => key.startsWith(FIRST_TOUCH_TRAITS_PREFIX));
}
