import * as Sentry from '@sentry/react';

type LogLevel = 'Error' | 'Warn';

export function captureAndLogException(error: Error | unknown, logLevel: LogLevel) {
  let errorToCapture: Error;

  if (error instanceof Error) {
    errorToCapture = error;
  } else if (typeof error === 'string') {
    errorToCapture = new Error(error);
  } else {
    errorToCapture = new Error(JSON.stringify(error));
  }

  Sentry.captureException(errorToCapture);

  switch (logLevel) {
    case 'Error':
      console.error(JSON.stringify(errorToCapture));
      break;
    case 'Warn':
      console.warn(JSON.stringify(errorToCapture));
      break;
  }
}
