import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { PolicyCodes, isSupportedProductCode } from '@joinfluffy/common';

import type { StorageQuoteDto } from '@shared/models/product';
import { ExternalPurchaseUrlSearchParams } from '@shared/constants/urlSearchParams';

import { useUiStateContextPatchUiState } from '~/hooks/useUiStateContextUpdateUi';
import { useInvokeErrorModal } from '~/hooks/useInvokeErrorModal';

import { fetchQuoteById } from '~/helpers/quote/quoteApi/quoteApi';
import { cleanLocallyStoredAllFormData } from '~/helpers/quote/locallyStoredValues';

import { QUOTE_NOT_FOUND } from '~/constants/errorMessage';
import { FirebaseFunctionsStatusCodes } from '~/constants/firebaseFunctionsStatusCodes';

interface ExternalQuoteRedirectParams {
  setQuoteResponseData: (response: StorageQuoteDto, selectedPolicy?: PolicyCodes, isExternalCall?: boolean) => void;
}

export function useExternalQuoteRedirect({ setQuoteResponseData }: ExternalQuoteRedirectParams) {
  const patchUiState = useUiStateContextPatchUiState();
  const { invokeErrorModal } = useInvokeErrorModal();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { quoteId } = useParams();

  const handleQuoteResponse = React.useCallback(
    (response: StorageQuoteDto, isExternalCall?: boolean) => {
      const policyCode = searchParams.get(ExternalPurchaseUrlSearchParams.PolicyCode);

      const selectedPolicy = policyCode && isSupportedProductCode(policyCode) ? policyCode : undefined;

      setQuoteResponseData(response, selectedPolicy, isExternalCall);

      patchUiState({ quoteForm: { isCalendarView: true } });
      navigate('/quote', { replace: true });
    },
    [searchParams, setQuoteResponseData, patchUiState, navigate],
  );

  React.useEffect(() => {
    if (!quoteId) {
      return;
    }

    patchUiState({ isQuoteDataForExternalRouteLoading: true, isLoading: true });

    const isExternalRedirect = Boolean(searchParams.get(ExternalPurchaseUrlSearchParams.Source));

    if (isExternalRedirect) {
      cleanLocallyStoredAllFormData();
    }

    fetchQuoteById(quoteId)
      .then((res) => handleQuoteResponse(res, isExternalRedirect))
      .catch((e) => {
        if (e.code === FirebaseFunctionsStatusCodes.NOT_FOUND) {
          e.message = QUOTE_NOT_FOUND;
        }

        invokeErrorModal({ error: e });
      })
      .finally(() => {
        patchUiState({ isQuoteDataForExternalRouteLoading: false, isLoading: false });
      });
  }, [quoteId, patchUiState, handleQuoteResponse, invokeErrorModal, searchParams]);
}
