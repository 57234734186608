import React from 'react';
import { useField } from 'formik';

import { Box, Stack } from '@chakra-ui/react';

import { findPetBreedByName } from '@joinfluffy/common';

import { ActionButton } from '~/components/common/ActionButton';
import { AutoCompleteInput } from '~/components/form/auto-complete-input/AutoCompleteInput';
import { ChooseOptionFormikItemsList } from '~/components/questions/ChooseOptionFormikItemsList';
import { TitleText } from '~/components/common/TitleText';
import { QuestionStack } from '~/components/common/QuestionStack';
import { CAT_OR_DOG_FIELD, QuoteFormValues } from '~/components/quote-form/quote-form.schema';

import { useFormikInsuranceAvailableBreedOptions } from '~/hooks/useFormikInsuranceAvailableBreedOptions';
import { useKeyEnterScreenHandler } from '~/hooks/common/useKeyEnterScreenHandler';
import { useInvokeModal } from '~/hooks/useInvokeModal';
import { useQuoteFormBackPressHandler } from '~/hooks/useQuoteFormBackPressHandler';

import { trackEvent } from '~/helpers/analytics/trackEvent';

import { AnalyticsEvent } from '~/constants/analyticsEvent';
import { ModalTypes } from '~/constants/modalTypes';

interface BreedQuestionProps {
  formikName: string;
  fullListQuestionText: string; // label for question input
  petSizeQuestionText: string;
  placeholder?: string;
  actionButtonLabel: string;
  onActionButtonClick: (value?: string) => void;
}

export const BreedQuestion: React.FC<BreedQuestionProps> = function BreedQuestion({
  formikName,
  fullListQuestionText,
  petSizeQuestionText,
  placeholder,
  actionButtonLabel,
  onActionButtonClick,
}) {
  const { invokeModal } = useInvokeModal();

  const [formikField, meta, formikHelpers] = useField(formikName);

  const initialRenderValueRef = React.useRef<string>(formikField.value ?? '');
  // we don't use `React.useCallback` here not to trigger dependant hooks,
  // because this method depends on formikHelpers, which is new on every render
  const resetFieldValueIfNeeded = () => {
    if (formikField.value !== initialRenderValueRef.current) {
      formikHelpers.setValue('', false);
    }
  };

  const [isPickingMixedBreed, setIsPickingMixedBreed] = React.useState(false);
  const [isPickingBaseCrossBreed, setIsPickingBaseCrossBreed] = React.useState(false);
  const isMainBreedListOpen = !isPickingMixedBreed && !isPickingBaseCrossBreed;

  const openPickingMixedBreed = () => {
    resetFieldValueIfNeeded();
    setIsPickingMixedBreed(true);
  };

  const openPickingCrossBreed = () => {
    resetFieldValueIfNeeded();
    setIsPickingBaseCrossBreed(true);
  };

  const openMainBreedList = () => {
    resetFieldValueIfNeeded();
    setIsPickingMixedBreed(false);
    setIsPickingBaseCrossBreed(false);
  };

  useQuoteFormBackPressHandler({ shouldExecuteCustomHandler: !isMainBreedListOpen, customHandler: openMainBreedList });

  const selectBreedHandler = React.useCallback(
    (value?: string) => {
      const breed = findPetBreedByName(formikField.value);

      if (breed?.aliasToBreedId === 'N/A' || breed?.id === 'Decline') {
        trackEvent({ eventName: AnalyticsEvent.QuoteSelectNotSupportedBreed, breed: breed.breedName });
        invokeModal(ModalTypes.NotSupportedBreed, { breed });
      } else {
        onActionButtonClick(value);
      }
    },
    [formikField.value, invokeModal, onActionButtonClick],
  );

  // don't use `React.useMemo` here, because we will update .value / .error fields on every render
  const isActionDisabled = Boolean(!meta.value || meta.error);
  useKeyEnterScreenHandler({ actionToCall: selectBreedHandler, isActionDisabled });

  const breedOptions = useFormikInsuranceAvailableBreedOptions({ isPickingMixedBreed, isPickingBaseCrossBreed });

  const [catOrDogFormikField] = useField<QuoteFormValues[typeof CAT_OR_DOG_FIELD]>(CAT_OR_DOG_FIELD);
  const petLabel = React.useMemo(() => {
    switch (catOrDogFormikField.value) {
      case 'cat':
        return 'cat';
      case 'dog':
        return 'dog';
      default:
        return 'pet';
    }
  }, [catOrDogFormikField.value]);

  return (
    <QuestionStack minW={320}>
      {/* We place the title outside for better UI, when the input is visible */}
      {isMainBreedListOpen && <TitleText text={fullListQuestionText} />}

      <Stack spacing={4} width="100%" alignItems="center">
        {isMainBreedListOpen ? (
          <AutoCompleteInput
            name={formikName}
            options={breedOptions}
            placeholder={placeholder}
            variant="flushed"
            size="md"
          />
        ) : (
          <>
            {/* We place the title inside for better UI, when we don't have the input */}
            <TitleText text={petSizeQuestionText} />

            <ChooseOptionFormikItemsList
              name={formikName}
              options={breedOptions}
              onSelect={selectBreedHandler}
              style={{ paddingTop: '44px' }}
            />
          </>
        )}

        <Box>
          <Stack width="100%" marginTop="24px" alignItems="center">
            {isMainBreedListOpen ? (
              <>
                <ActionButton onPress={openPickingMixedBreed} label={`I have a mix-breed ${petLabel}`} isSecondary />
                {catOrDogFormikField.value === 'dog' && (
                  <ActionButton
                    onPress={openPickingCrossBreed}
                    label={`I have a cross-breed ${petLabel}`}
                    isSecondary
                  />
                )}
              </>
            ) : (
              <ActionButton onPress={openMainBreedList} label="Back to breed list" isSecondary />
            )}
          </Stack>
        </Box>
      </Stack>

      {isMainBreedListOpen && (
        <ActionButton onPress={selectBreedHandler} label={actionButtonLabel} disabled={isActionDisabled} />
      )}
    </QuestionStack>
  );
};
