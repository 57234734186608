import React from 'react';

import { MIN_AGE_FOR_INSURANCE_IN_WEEKS } from '@shared/constants/validation';

import { BaseModal } from '~/components/common/BaseModal';

import { ModalTypes } from '~/constants/modalTypes';

const IMAGE_PATH = '/images/dogImages/38-dog-bubbles/38-dog-bubbles-1080.png';

interface ConfirmPetIsOldEnoughModalProps {
  isOpen: boolean;
  onClose: () => void;
  confirmationHandler: () => void;
  rejectionHandler?: () => void;
}

export const ConfirmPetIsOldEnoughModal: React.FC<ConfirmPetIsOldEnoughModalProps> =
  function ConfirmPetIsOldEnoughModal({ isOpen, onClose, confirmationHandler, rejectionHandler }) {
    const onActionButtonPress = React.useCallback(() => {
      confirmationHandler();
      onClose();
    }, [confirmationHandler, onClose]);

    const onCloseButtonPress = React.useCallback(() => {
      rejectionHandler?.();
      onClose();
    }, [rejectionHandler, onClose]);

    return (
      <BaseModal
        type={ModalTypes.ConfirmPetIsOldEnough}
        isOpen={isOpen}
        imgSrc={IMAGE_PATH}
        title={`Please confirm your pet is older than ${MIN_AGE_FOR_INSURANCE_IN_WEEKS} weeks old`}
        description="We can’t insure pets if they’re younger. But please come back in a couple of weeks."
        onClose={onCloseButtonPress}
        actionButtonLabel="Confirm"
        onActionButtonPress={onActionButtonPress}
        secondaryActionButtonLabel="Close"
        onSecondaryActionButtonPress={onCloseButtonPress}
      />
    );
  };
