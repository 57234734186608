import { getAnalyticsBrowser } from '~/helpers/analytics/analytics';
import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';
import { addSentryBreadcrumb } from '~/helpers/monitoring/common';

export async function identify(email?: string, traits?: object) {
  const analyticsBrowser = getAnalyticsBrowser();

  let normalizedEmail = email;

  if (normalizedEmail) {
    normalizedEmail = normalizedEmail.toLowerCase();
  }

  if (analyticsBrowser) {
    addSentryBreadcrumb({
      category: 'event_breadcrumb',
      message: 'Segment.identify method called',
      data: {
        email: normalizedEmail,
        traits,
      },
      level: 'info',
    });

    try {
      await analyticsBrowser.identify(normalizedEmail, { email: normalizedEmail, ...traits });
    } catch (error) {
      addSentryBreadcrumb({
        category: 'event_breadcrumb',
        message: 'Segment.identify method error caught',
        data: {
          email: normalizedEmail,
          traits,
          caughtErrorFromTrySection: error,
        },
        level: 'debug',
      });

      captureAndLogException('Segment.identify method error caught', 'Error');
    }
  } else {
    captureAndLogException('[identify]: Analytics browser is not initialized', 'Warn');
  }
}
