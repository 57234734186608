import React from 'react';

import { Stack, StackProps } from '@chakra-ui/react';

export const QuestionStack: React.FC<React.PropsWithChildren<StackProps>> = function QuestionStack({
  children,
  ...stackProps
}) {
  return (
    <Stack spacing={{ base: '80px', lg: '132px' }} align="center" {...stackProps}>
      {children}
    </Stack>
  );
};
