import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { UtmSource } from '@shared/constants/utmSource';

import type { AttributionQueryParams } from '~/helpers/analytics/trackAttributionAnalytics';
import {
  trackFirstTouchAttributionIfNeeded,
  trackLastTouchAttribution,
} from '~/helpers/analytics/trackAttributionAnalytics';
import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';
import { trackEvent } from '~/helpers/analytics/trackEvent';

import { AnalyticsEvent } from '~/constants/analyticsEvent';
import { ATTRIBUTION_SEARCH_PARAMS_TO_CLEAN } from '~/constants/attribution/attributionSearchParamsToClean';
import { LtqSearchParams } from '~/constants/attribution/ltqSearchParams';
import { OwnAttributionSearchParams } from '~/constants/attribution/ownAttributionSearchParams';

export function useVisitAttribution() {
  const [searchParams, setSearchParams] = useSearchParams();
  const wasAttributionHandledRef = React.useRef(false);

  React.useEffect(() => {
    async function handler() {
      // get Attribution params
      const ownUtmQueryParams = getOwnUtmQueryParams(searchParams);
      const ltqQueryParams = getLandingToQuotingQueryParams(searchParams);

      // parse Attribution params
      const filledOwnQueryParamsEntries = getNotEmptyEntries(ownUtmQueryParams);
      const filledLtqQueryParamsEntries = getNotEmptyEntries(ltqQueryParams);

      if (filledLtqQueryParamsEntries.length) {
        // handle ltq (Landing-to-Quoting) attribution params only
        await trackFirstTouchAttributionIfNeeded(filledLtqQueryParamsEntries);
        await trackLastTouchAttribution(filledLtqQueryParamsEntries);

        // track fluffyReferralId if exists
        if (ltqQueryParams.fluffyReferralId) {
          trackEvent({
            eventName: AnalyticsEvent.QuoteStartReferralFlow,
            referralCode: ltqQueryParams.fluffyReferralId,
          });
        }
      } else if (filledOwnQueryParamsEntries.length) {
        // handle own attribution params
        await trackFirstTouchAttributionIfNeeded(filledOwnQueryParamsEntries);
        await trackLastTouchAttribution(filledOwnQueryParamsEntries);
      }

      // clear search params
      let searchParamsUpdated = false;
      ATTRIBUTION_SEARCH_PARAMS_TO_CLEAN.forEach((paramToRemove) => {
        if (searchParams.has(paramToRemove)) {
          searchParams.delete(paramToRemove);
          searchParamsUpdated = true;
        }
      });

      if (searchParamsUpdated) {
        setSearchParams(searchParams, { replace: true });
      }
    }

    if (!wasAttributionHandledRef.current) {
      wasAttributionHandledRef.current = true;
      handler().catch((err) => {
        captureAndLogException(
          `Error during handling attribution params: "${JSON.stringify(err instanceof Error ? err.message : err)}"`,
          'Error',
        );
      });
    }
  }, [searchParams, setSearchParams]);
}

function getReferrerHostname() {
  const ownReferrer = document.referrer;
  if (!ownReferrer) {
    return null;
  }

  const ownReferrerUrl = new URL(ownReferrer);
  return ownReferrerUrl.hostname;
}

function getOwnUtmQueryParams(searchParams: URLSearchParams): AttributionQueryParams {
  return {
    referrer: getReferrerHostname(),
    utmSource: searchParams.has(OwnAttributionSearchParams.utmSource)
      ? searchParams.get(OwnAttributionSearchParams.utmSource)
      : null,
    utmMedium: searchParams.has(OwnAttributionSearchParams.utmMedium)
      ? searchParams.get(OwnAttributionSearchParams.utmMedium)
      : null,
    utmCampaign: searchParams.has(OwnAttributionSearchParams.utmCampaign)
      ? searchParams.get(OwnAttributionSearchParams.utmCampaign)
      : null,
    utmContent: searchParams.has(OwnAttributionSearchParams.utmContent)
      ? searchParams.get(OwnAttributionSearchParams.utmContent)
      : null,
    source: searchParams.has(OwnAttributionSearchParams.source)
      ? searchParams.get(OwnAttributionSearchParams.source)
      : null,
    originalSource: searchParams.has(OwnAttributionSearchParams.source)
      ? searchParams.get(OwnAttributionSearchParams.source)
      : null,

    // we handle it only if the user came from our landing page (ltq flow)
    fluffyReferralId: null,
  };
}

function getLandingToQuotingQueryParams(searchParams: URLSearchParams): AttributionQueryParams {
  const attributionQueryParams: AttributionQueryParams = {
    referrer: searchParams.has(LtqSearchParams.referrer) ? searchParams.get(LtqSearchParams.referrer) : null,
    utmSource: searchParams.has(LtqSearchParams.utmSource) ? searchParams.get(LtqSearchParams.utmSource) : null,
    utmMedium: searchParams.has(LtqSearchParams.utmMedium) ? searchParams.get(LtqSearchParams.utmMedium) : null,
    utmCampaign: searchParams.has(LtqSearchParams.utmCampaign) ? searchParams.get(LtqSearchParams.utmCampaign) : null,
    utmContent: searchParams.has(LtqSearchParams.utmContent) ? searchParams.get(LtqSearchParams.utmContent) : null,

    // for the Awin case (?source=aw on landing)
    source: searchParams.has(LtqSearchParams.source) ? searchParams.get(LtqSearchParams.source) : null,

    // for the Awin case (?source=FACEBOOK&ltq-source=aw in web-app)
    originalSource: searchParams.has(OwnAttributionSearchParams.source)
      ? searchParams.get(OwnAttributionSearchParams.source)
      : null,

    // for the Awin case (?source=FACEBOOK&ltq-source=aw in web-app)
    fluffyReferralId: searchParams.has(LtqSearchParams.fluffyReferralId)
      ? searchParams.get(LtqSearchParams.fluffyReferralId)
      : null,
  };

  if (attributionQueryParams.fluffyReferralId) {
    // when user came with fluffy_referral_id,
    // forcing to set the right utm_source as FluffyReferral
    // (in case the utm param was removed or changed for some reason)
    attributionQueryParams.utmSource = UtmSource.FluffyReferral;
  }

  return attributionQueryParams;
}

function getNotEmptyEntries(inputObj: AttributionQueryParams) {
  return Object.entries(inputObj).filter(([, value]) => Boolean(value)) as Array<
    [keyof AttributionQueryParams, string | null]
  >;
}
