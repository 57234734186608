import React from 'react';
import { useField } from 'formik';

import type { CatOrDog } from '@joinfluffy/common';

import { BinaryQuestion } from '~/components/questions/BinaryQuestion';

import { useFormikInsuranceFormDisplayFormatPetName } from '~/hooks/useFormikInsuranceFormDisplayFormatPetName';

import {
  CAT_OR_DOG_FIELD,
  QuoteFormQuestionType,
  QuoteFormValues,
  PET_BREED_FIELD,
} from '~/components/quote-form/quote-form.schema';

interface PetTypeQuestionProps {
  onSelect: ({ currentQuestionType }: { currentQuestionType: QuoteFormQuestionType }, value?: string | boolean) => void;
}

export function PetTypeQuestion({ onSelect }: PetTypeQuestionProps) {
  const petName = useFormikInsuranceFormDisplayFormatPetName();

  const [field] = useField<QuoteFormValues[typeof CAT_OR_DOG_FIELD]>(CAT_OR_DOG_FIELD);
  const [petBreedField, , petBreedFieldHelpers] = useField<QuoteFormValues[typeof PET_BREED_FIELD]>(PET_BREED_FIELD);

  const petTypeValueRef = React.useRef<CatOrDog | null>(field.value);

  const onPetTypeSelect = (value?: string | boolean) => {
    if (petTypeValueRef.current !== value && petBreedField.value !== '') {
      const typedValue = value as CatOrDog;
      petTypeValueRef.current = typedValue;
      // Clean selected pet breed
      petBreedFieldHelpers.setValue('');
    }

    onSelect({ currentQuestionType: 'PET_TYPE' }, value);
  };

  return (
    <BinaryQuestion<CatOrDog>
      name={CAT_OR_DOG_FIELD}
      questionText={`Is ${petName} a cat or a dog?`}
      options={[
        { label: 'Dog', value: 'dog' },
        { label: 'Cat', value: 'cat' },
      ]}
      onSelect={onPetTypeSelect}
    />
  );
}
