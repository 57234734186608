import moment from 'moment-timezone';

import { INTERNAL_DATE_FORMAT } from '@joinfluffy/common';

import { AnyObject } from 'yup/lib/types';

import { getTodaysDate } from '~/helpers/date/date';

export function checkIsPolicyStartDateValid(value?: string | null, context?: AnyObject) {
  if (!context) {
    return false;
  }

  const date = moment(value, INTERNAL_DATE_FORMAT);

  return date.isValid();
}

export function checkPolicyStartDateIsNotEarlierThanToday(value?: string | null, context?: AnyObject) {
  if (!context) {
    return false;
  }

  return moment(value, INTERNAL_DATE_FORMAT).isSameOrAfter(getTodaysDate());
}

export function checkPetPolicyStartDateIsNotLaterThan30Days(value?: string | null, context?: AnyObject) {
  if (!context) {
    return false;
  }

  return moment(value, INTERNAL_DATE_FORMAT).isSameOrBefore(getTodaysDate().add(30, 'days'));
}
