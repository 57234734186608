import React from 'react';

import { Box, Flex, Spinner } from '@chakra-ui/react';

import { Colors } from '~/constants/colors';
import { GlobalStyleVariables } from '~/constants/globalStyleVariables';

type OverlayCoverMode = 'screen' | 'container';
export const OverlayLoader: React.FC<{ coverMode?: OverlayCoverMode }> = function OverlayLoader(props) {
  const coverMode = props.coverMode ?? 'screen';

  return (
    <Flex
      position={coverMode === 'screen' ? 'fixed' : 'absolute'}
      direction="column"
      alignItems="center"
      justifyContent="center"
      w="100%"
      h="100%"
      left={0}
      top={0}
      zIndex={GlobalStyleVariables.OverlayLoaderZIndex}
    >
      <Box position="absolute" w="100%" h="100%" bg={Colors.White} opacity={0.4} />
      <Spinner color={Colors.Orange} />
    </Flex>
  );
};
