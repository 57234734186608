export enum AnalyticsEvent {
  QuoteQuotingOpen = 'quote_quoting_open',
  QuotePetNameConfirmed = 'quote_pet_name_confirmed',
  QuotePetTypeConfirmed = 'quote_pet_type_confirmed',
  QuotePetGenderConfirmed = 'quote_pet_gender_confirmed',
  QuotePetBreedConfirmed = 'quote_pet_breed_confirmed',
  QuotePetAgeFullConfirmed = 'quote_pet_age_full_confirmed',
  QuotePetIsSpayedConfirmed = 'quote_pet_isspayed_confirmed',
  QuotePetIsMicrochipedConfirmed = 'quote_pet_ismicrochipped_confirmed',
  QuoteOwnerPostcodeConfirmed = 'quote_owner_postcode_confirmed',
  QuoteOwnerEmailConfirmed = 'quote_owner_email_confirmed',
  QuoteOwnerAddressConfirmed = 'quote_owner_address_confirmed ',
  QuoteOwnerInfoConfirmed = 'quote_owner_info_confirmed',
  QuoteExistingPolicyFinishDateConfirmed = 'quote_existing_policy_finish_date',
  QuoteOptionalPhoneNumberConfirmed = 'quote_optional_phone_number_confirmed',
  QuoteOwnerOverEighteenConfirmed = 'quote_owner_over_eighteen_confirmed',
  QuoteOwnerDateOfBirthConfirmed = 'quote_owner_date_of_birth_confirmed',
  QuotePolicyStartDateConfirmed = 'quote_policy_start_date_confirmed',
  QuotePolicyStartDateWasInThePast = 'quote_policy_start_date_was_in_the_past',
  QuotePetPurchaseValueConfirmed = 'quote_pet_purchasevalue_confirmed',
  QuotePricingPlanConfirmed = 'quote_pricing_plan_confirmed',
  QuotePolicySummaryConfirmed = 'quote_policy_summary_confirmed',
  QuotePolicyDeclarationConfirmed = 'quote_policy_declaration_confirmed',
  QuotePurchaseClick = 'quote_purchase_click',
  QuoteNavigateToPartnersURL = 'quote_navigate_to_partners_url',
  QuoteNavigatedBackToThankYouPage = 'quote_navigated_back_to_thank_you_page',
  QuoteHasAlreadyInsurancePolicy = 'quote_has_already_insurance_policy',
  QuoteDiscountCodeConfirmed = 'quote_discount_code_confirmed',
  QuotePreferredBenefitsConfirmed = 'quote_preferred_benefits_confirmed',
  QuoteSelectNotSupportedBreed = 'quote_select_not_supported_breed',
  QuoteViewPolicyPlansPage = 'quote_view_policy_plans_page',
  QuoteOpenJoinBreedWaitListLink = 'quote_open_join_breed_waitlist_link',
  QuoteOpenJoinPostcodeWaitListLink = 'quote_open_join_postcode_waitlist_link',
  QuotePaymentTypeConfirmed = 'quote_payment_type_confirmed',
  QuoteAddPetDetailsClick = 'quote_add_pet_details',
  QuoteEditPetDetailsClick = 'quote_edit_pet_details',
  QuoteRemovePetDetailsClick = 'quote_remove_pet_details',
  QuoteNumOfPetsConfirmed = 'quote_num_of_pets_confirmed',
  QuoteOpenReferralLinkModalButtonClicked = 'quote_get_referral_link_button_clicked',
  QuoteCopyReferralLinkButtonClicked = 'quote_copy_referral_link_button_clicked',
  QuoteReferralLinkGenerated = 'quote_referral_link_generated',
  QuoteShareReferralLinkButtonClick = 'quote_share_referral_link_button_click',

  // misc
  QuoteOpenChat = 'quote_open_chat',
  QuoteCloseChat = 'quote_close_chat',
  QuoteClickFormResetButton = 'quote_click_form_reset_button',
  QuoteConfirmFormReset = 'quote_confirm_form_reset',
  QuoteToggleManualAddressSearch = 'quote_toggle_manual_address_search',

  // modal
  QuoteModalOpen = 'quote_modal_open',
  QuoteModalClose = 'quote_modal_close',
  QuoteModalExecutePrimaryAction = 'quote_modal_execute_primary_action',
  QuoteModalExecuteSecondaryAction = 'quote_modal_execute_secondary_action',

  // attribution
  QuoteStartReferralFlow = 'quote_start_referral_flow',
}

export enum AdEventType {
  Lead,
  AddToCard,
  Purchase,
}

type AdKeys = {
  redditKey: string;
  googleAdsKey: string;
};

export const AD_EVENTS: Record<AdEventType, AdKeys> = {
  [AdEventType.Lead]: {
    redditKey: 'Lead',
    googleAdsKey: 'AW-434805021/gL-fCMaZ4pgYEJ2yqs8B',
  },
  [AdEventType.AddToCard]: {
    redditKey: 'AddToCart',
    googleAdsKey: 'AW-434805021/Kl3HCO3l3JgYEJ2yqs8B',
  },
  [AdEventType.Purchase]: {
    redditKey: 'Purchase',
    googleAdsKey: 'AW-434805021/epzgCKnm3JgYEJ2yqs8B',
  },
};
