import React from 'react';

import {
  fetchAddressAutocompleteSuggestions,
  LookupSuggestion,
} from '~/components/questions/lookup-address-question/addressLookup';

import { debounce } from '~/helpers/common';
import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';

export function useAddressAutocomplete(inputValue = '') {
  const [lastInput, setLastInput] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [lastResultSuggestions, setLastResultSuggestions] = React.useState<LookupSuggestion[] | null>(null);

  const inputHandler = React.useCallback(async (nextValue: string) => {
    if (!nextValue) {
      setLastInput('');
      setLastResultSuggestions([]);
      return;
    }

    try {
      setLastInput(nextValue);
      setIsLoading(true);

      const suggestions = await fetchAddressAutocompleteSuggestions(nextValue);
      setLastResultSuggestions(suggestions);
    } catch (err) {
      captureAndLogException(`Error while handling input of address autocomplete: "${JSON.stringify(err)}"`, 'Error');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const debouncedInputHandler = React.useMemo(() => debounce(inputHandler, 500), [inputHandler]);

  React.useEffect(() => {
    debouncedInputHandler(inputValue);
  }, [debouncedInputHandler, inputValue]);

  return {
    lastInput,
    lastResultSuggestions,
    isLoading,
  };
}
