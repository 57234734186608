import React from 'react';
import { Image, Text, Progress, Box, Stack } from '@chakra-ui/react';

import { TitleText } from '~/components/common/TitleText';

import { useProgressBarPercentageValue } from '~/hooks/useProgressBarPercentageValue';

import { Colors } from '~/constants/colors';

const IMG_URL = '/images/dogImages/03-goal-achieving-dog/03-goal-achieving-dog-1080.png';

const LOADING_DURATION = 15000; // 15 seconds in millis
const STEPS_COUNT = 1000; // for smooth loading during 15 seconds;
const MAX_PERCENTAGE = 95;

export const PaymentPageLoaderView: React.FC = function PaymentPageLoaderView() {
  const { loaderPercentage } = useProgressBarPercentageValue(LOADING_DURATION, STEPS_COUNT, MAX_PERCENTAGE);

  return (
    <Stack spacing="32px" align="center" justify="center">
      <Box>
        <TitleText text="Redirecting to the payment page" />
      </Box>
      <Image src={IMG_URL} boxSize="400px" fit="contain" />
      <Progress
        borderRadius="10px"
        height="10px"
        width={{ base: '90%', lg: '600px' }}
        bg="#EBE0DB"
        colorScheme="orange"
        hasStripe
        isAnimated
        value={loaderPercentage}
      />
      <Text
        paddingBottom="20px"
        fontSize="16px"
        lineHeight="20px"
        color={Colors.Brown}
        fontWeight="700"
        maxWidth="700px"
      >
        The payment page is powered by Fluffy’s partner Insure Your Paws, which is a trading name of Cover-More Blue
        Insurance Services Limited and you are subject to the terms and conditions of that site.
      </Text>
    </Stack>
  );
};
