import moment from 'moment-timezone';

import {
  findPetBreedById,
  findPetBreedByName,
  petGenders,
  petSubTypes,
  type PetPedigree,
  INTERNAL_DATE_FORMAT,
} from '@joinfluffy/common';

import { CustomerDto, PartialCustomerDto, PetPolicyDto } from '@shared/models/dto';
import { PaymentType } from '@shared/constants/paymentType';

import {
  ADDRESS_LINE_1_FIELD,
  EMAIL_FIELD,
  POLICY_START_DATE_FIELD,
  PET_DATE_OF_BIRTH_FIELD,
  CAT_OR_DOG_FIELD,
  GENDER_FIELD,
  IS_NEUTERED_FIELD,
  PET_VALUE_FIELD,
  PET_BREED_FIELD,
  PET_NAME_FIELD,
  POSTCODE_FIELD,
  OPTIONAL_PHONE_NUMBER_FIELD,
  IS_MICROCHIPPED_FIELD,
  QuoteFormPetValues,
  EXISTING_POLICY_FINISH_DATE,
  ADDRESS_LINE_2_FIELD,
  ADDRESS_LINE_3_FIELD,
} from '~/components/quote-form/quote-form.schema';

import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';
import { getPetTypeByPetTypeValue } from '~/helpers/pet';
import { cleanPetValueCurrency } from '~/helpers/pet/petValue';
import { getPetPedigreeFromPetBreed } from '~/helpers/pet/petPedigree/petPedigree';

import {
  DATE_OF_OWNER_BIRTH_FIELD,
  FIRST_NAME_FIELD,
  CustomerFormValues,
  LAST_NAME_FIELD,
  TITLE_FIELD,
  PAYMENT_TYPE_FIELD,
} from '~/components/customer-form/customer-form.schema';
import { PHONE_NUMBER_FIELD } from '~/components/customer-form/customer-form.schema';
import { FilledQuoteFormValuesSchema } from '~/providers/insurance-data-provider/types';

export interface DtoResultSchema {
  customerDto: PartialCustomerDto;
  petPolicyDtos: PetPolicyDto[];
  existingPolicyFinishDate?: string;
}

export interface CreateQuoteDtoFromFormsValuesResultSchema {
  customerDto: CustomerDto;
  petPolicyDtos: PetPolicyDto[];
  paymentType: PaymentType;
}

export function createPartialQuoteDtosFromFormValues(
  formValues: FilledQuoteFormValuesSchema,
): DtoResultSchema | undefined {
  try {
    const { formPetValues, formCustomerValues } = validateQuoteFormValues(formValues);

    const customerDto: PartialCustomerDto = {
      email: formCustomerValues.email,
      postcode: formCustomerValues.postcode,
      mobileNumber: formCustomerValues.optionalMobileNumber,
      address1: formCustomerValues.address1,
      address2: formCustomerValues.address2,
      address3: formCustomerValues.address3,
    };

    const petPolicyDtos: PetPolicyDto[] = formPetValues.map((petValues) => ({
      pet: {
        type: petValues.petTypeValue,
        gender: petValues.genderValue,
        pedigree: petValues.petPedigreeValue,
        name: petValues.petName,
        dateOfBirth: petValues.formattedPetDateOfBirth,
        isChipped: petValues.isMicroChipped,
        isNeutered: petValues.isNeuteredValue,
        purchaseValue: petValues.petValue,
        breedId: petValues.petBreedId,
      },
      policyStartDate: formCustomerValues.formattedPolicyStartDate,
    }));

    const existingPolicyFinishDate = formValues[EXISTING_POLICY_FINISH_DATE]
      ? formValues[EXISTING_POLICY_FINISH_DATE]
      : undefined;

    return { customerDto, petPolicyDtos, existingPolicyFinishDate };
  } catch (e) {
    captureAndLogException(
      '[createPartialQuoteDtosFromFormValues]: exception when trying to create DTOs: ' + JSON.stringify(e),
      'Error',
    );
  }
}

export function createQuoteDtoFromFormsValues(
  quoteFormValues: FilledQuoteFormValuesSchema,
  customerFormValues: CustomerFormValues,
): CreateQuoteDtoFromFormsValuesResultSchema | undefined {
  try {
    const { formPetValues, formCustomerValues } = validateQuoteFormValues(quoteFormValues);

    const { title, ownerDateOfBirth, firstName, lastName, phoneNumber, paymentType } =
      validateCustomerFormValues(customerFormValues);

    const customerDto: CustomerDto = {
      firstName,
      lastName,
      title: title!,
      dateOfBirth: ownerDateOfBirth.format(INTERNAL_DATE_FORMAT),
      mobileNumber: phoneNumber,
      email: formCustomerValues.email,
      postcode: formCustomerValues.postcode,
      address1: formCustomerValues.address1,
      address2: formCustomerValues.address2,
      address3: formCustomerValues.address3,
    };

    const petPolicyDtos: PetPolicyDto[] = formPetValues.map((petValues) => ({
      pet: {
        type: petValues.petTypeValue,
        gender: petValues.genderValue,
        pedigree: petValues.petPedigreeValue,
        name: petValues.petName,
        dateOfBirth: petValues.formattedPetDateOfBirth,
        isChipped: petValues.isMicroChipped,
        isNeutered: petValues.isNeuteredValue,
        purchaseValue: petValues.petValue,
        breedId: petValues.petBreedId,
      },
      policyStartDate: formCustomerValues.formattedPolicyStartDate,
    }));

    return { customerDto, petPolicyDtos, paymentType };
  } catch (e) {
    captureAndLogException(
      '[createQuoteDtoFromFormsValues]: exception when trying to create DTOs: ' + JSON.stringify(e),
      'Error',
    );

    return;
  }
}

function validateQuoteFormValues(formValues: FilledQuoteFormValuesSchema) {
  const formPetValues = formValues.pets.map((pet) => validateQuoteFormPetValues(pet));
  const formCustomerValues = validateQuoteFormCustomerValues(formValues);

  return {
    formPetValues,
    formCustomerValues,
  };
}

function validateCustomerFormValues(customerFormValues: CustomerFormValues) {
  const title = customerFormValues[TITLE_FIELD];
  const ownerDateOfBirth = moment(customerFormValues[DATE_OF_OWNER_BIRTH_FIELD], INTERNAL_DATE_FORMAT, true);
  const firstName = customerFormValues[FIRST_NAME_FIELD];
  const lastName = customerFormValues[LAST_NAME_FIELD];
  const phoneNumber = customerFormValues[PHONE_NUMBER_FIELD];
  const paymentType = customerFormValues[PAYMENT_TYPE_FIELD];

  let hasMissingValues = false;

  if (title === null) {
    console.error('[validateCustomerFormValues] Missing title value');
    hasMissingValues = true;
  }

  if (!ownerDateOfBirth.isValid()) {
    captureAndLogException(
      '[validateCustomerFormValues]: invalid ownerDateOfBirth value: ' + customerFormValues[DATE_OF_OWNER_BIRTH_FIELD],
      'Error',
    );
    hasMissingValues = true;
  }

  if (firstName.length === 0) {
    console.error('[validateCustomerFormValues] Missing firstName value');
    hasMissingValues = true;
  }

  if (lastName.length === 0) {
    console.error('[validateCustomerFormValues] Missing lastName value');
    hasMissingValues = true;
  }

  if (phoneNumber.length === 0) {
    console.error('[validateCustomerFormValues] Missing phoneNumber value');
    hasMissingValues = true;
  }

  if (!paymentType) {
    console.error('[validateCustomerFormValues] Missing paymentType value');
    hasMissingValues = true;
  }

  if (hasMissingValues) {
    throw 'Some of the values from the CustomerForm are missing or are invalid. Inspect above log outputs to determine the particular values causing the issue';
  }

  return {
    title,
    ownerDateOfBirth,
    firstName,
    lastName,
    phoneNumber,
    paymentType: paymentType!,
  };
}

function validateQuoteFormPetValues(formValues: QuoteFormPetValues) {
  const gender = petGenders.find((gender) => gender.gender == formValues[GENDER_FIELD]);
  const isNeutered = formValues[IS_NEUTERED_FIELD];
  const petValue = cleanPetValueCurrency(formValues[PET_VALUE_FIELD]);
  const petName = formValues[PET_NAME_FIELD];
  const isMicroChipped = formValues[IS_MICROCHIPPED_FIELD];

  let petBreed = findPetBreedByName(formValues[PET_BREED_FIELD]);
  if (petBreed?.aliasToBreedId) {
    petBreed = findPetBreedById(petBreed.aliasToBreedId);
  }

  const petPedigree: PetPedigree | undefined = getPetPedigreeFromPetBreed(petBreed);

  const petType = getPetTypeByPetTypeValue(formValues[CAT_OR_DOG_FIELD]);
  const petSubType = petSubTypes.find(({ type, pedigree }) => type === petType?.value && pedigree === petPedigree);

  const petDateOfBirthValue = formValues[PET_DATE_OF_BIRTH_FIELD];
  const petDateOfBirth = moment(petDateOfBirthValue, INTERNAL_DATE_FORMAT, true);

  // TODO: [INSR-40] could we be better off with a separate exception for each field?
  let hasMissingValues = false;

  if (!petType) {
    console.error('Missing petType value');
    hasMissingValues = true;
  }

  if (!petSubType) {
    console.error('Missing petSubType value');
    hasMissingValues = true;
  }

  if (gender === null) {
    console.error('Missing gender value');
    hasMissingValues = true;
  }

  if (petName.length === 0) {
    console.error('Missing petName value');
    hasMissingValues = true;
  }

  if (isNeutered === null) {
    console.error('Missing isNeutered value');
    hasMissingValues = true;
  }

  if (Number.isNaN(petValue) || Number(petValue) < 0) {
    console.error('Invalid petValue value');
    hasMissingValues = true;
  }

  if (isMicroChipped === null) {
    console.error('Missing isMicrochipped value');
    hasMissingValues = true;
  }

  if (!petBreed) {
    console.error('Missing petBreed value');
    hasMissingValues = true;
  }

  if (!petDateOfBirth.isValid()) {
    captureAndLogException('invalid petDateOfBirth value: ' + formValues[PET_DATE_OF_BIRTH_FIELD], 'Error');
    hasMissingValues = true;
  }

  if (formValues[IS_NEUTERED_FIELD] === null) {
    console.error('Missing isNeutered value');
    hasMissingValues = true;
  }

  if (hasMissingValues) {
    throw 'Some of the values from the InsuranceForm are missing or are invalid. Inspect above log outputs to determine the particular values causing the issue';
  }

  return {
    petTypeValue: petType!.value,
    petName,
    petPedigreeValue: petSubType!.pedigree,
    genderValue: gender!.gender,
    isNeuteredValue: Boolean(isNeutered),
    isMicroChipped: Boolean(isMicroChipped),
    petValue: Number(petValue),
    petBreedId: petBreed!.id,
    formattedPetDateOfBirth: petDateOfBirth.format(INTERNAL_DATE_FORMAT),
  };
}

function validateQuoteFormCustomerValues(formValues: FilledQuoteFormValuesSchema) {
  const email = formValues[EMAIL_FIELD];
  const optionalMobileNumber = formValues[OPTIONAL_PHONE_NUMBER_FIELD];
  const address1 = formValues[ADDRESS_LINE_1_FIELD].trim();
  const address2 = formValues[ADDRESS_LINE_2_FIELD].trim();
  const address3 = formValues[ADDRESS_LINE_3_FIELD].trim();
  const postcode = formValues[POSTCODE_FIELD].trim();

  const policyStartDate = moment(formValues[POLICY_START_DATE_FIELD], INTERNAL_DATE_FORMAT, true);

  let hasMissingValues = false;

  if (email.length === 0) {
    console.error('Missing email value');
    hasMissingValues = true;
  }

  if (!postcode) {
    console.error('Missing postcode value');
    hasMissingValues = true;
  }

  if (!address1) {
    console.error('Missing address value');
    hasMissingValues = true;
  }

  if (!policyStartDate.isValid()) {
    captureAndLogException('invalid policyStartDate value: ' + formValues[POLICY_START_DATE_FIELD], 'Error');
    hasMissingValues = true;
  }

  if (hasMissingValues) {
    throw 'Some of the values from the InsuranceForm are missing or are invalid. Inspect above log outputs to determine the particular values causing the issue';
  }

  return {
    email,
    optionalMobileNumber,
    postcode,
    address1,
    address2,
    address3,
    formattedPolicyStartDate: policyStartDate.format(INTERNAL_DATE_FORMAT),
  };
}
