import React from 'react';
import { Stack } from '@chakra-ui/react';
import { EmailShareButton, FacebookShareButton, TwitterShareButton } from 'react-share';

import { TitleText } from '~/components/common/TitleText';
import { ShareButton } from '~/components/modal/referral-link-modal/social-media-share-actions/share-button/ShareButton';

import { trackEvent } from '~/helpers/analytics/trackEvent';

import { SocialShareType } from '~/configs/socials';

import { AnalyticsEvent } from '~/constants/analyticsEvent';

import { ReactComponent as EmailIcon } from '~/assets/icons/email.svg';

interface SocialMediaShareActionsProps {
  referralUrl: string;
}

const FACEBOOK_IMG_SRC = '/images/icons/facebook.png';
const TWITTER_IMG_SRC = '/images/icons/twitter.png';

const OFFER_SUBJECT = '£25 Voucher with Fluffy Pet insurance';
const OFFER_COPY =
  'Get a £25 Amazon voucher when you purchase Fluffy Pet Insurance via my unique referral link!\n\nEach policy comes with free pet training, round-the-clock vet support, and sweet discounts on pet food! 🎉';

export const SocialMediaShareActions: React.FC<SocialMediaShareActionsProps> = function SocialMediaShareActions({
  referralUrl,
}) {
  const getTrackEventHandler = (source: SocialShareType) => () => {
    trackEvent({ eventName: AnalyticsEvent.QuoteShareReferralLinkButtonClick, ['social_destination']: source });
  };

  return (
    <Stack spacing={{ base: '24px', lg: '40px' }}>
      <TitleText style={{ w: '100%', textAlign: 'center' }} text="Or simply share on socials" />

      <Stack direction={{ base: 'column', lg: 'row' }} spacing="16px">
        <EmailShareButton
          subject={OFFER_SUBJECT}
          body={OFFER_COPY}
          url={referralUrl}
          beforeOnClick={getTrackEventHandler('email')}
        >
          <ShareButton iconContainerStyle={{ width: '34px', height: '27px' }} label="Email" Icon={EmailIcon} />
        </EmailShareButton>
        <FacebookShareButton url={referralUrl} beforeOnClick={getTrackEventHandler('facebook')}>
          <ShareButton
            iconContainerStyle={{ width: '40px', height: '40px' }}
            label="Facebook"
            Icon={FACEBOOK_IMG_SRC}
          />
        </FacebookShareButton>
        <TwitterShareButton title={OFFER_COPY} url={referralUrl} beforeOnClick={getTrackEventHandler('twitter')}>
          <ShareButton iconContainerStyle={{ width: '38px', height: '35px' }} label="Twitter" Icon={TWITTER_IMG_SRC} />
        </TwitterShareButton>
      </Stack>
    </Stack>
  );
};
