import React from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { IntercomLauncher } from '~/components/intercom/intercom-launcher/IntercomLauncher';

import { Colors } from '~/constants/colors';
import { GlobalStyleVariables } from '~/constants/globalStyleVariables';

export const FloatingContainer: React.FC<React.PropsWithChildren> = function FloatingContainer({ children }) {
  return (
    <>
      <IntercomLauncher withFloatingContainer />

      <Flex
        position={{ base: 'fixed' }}
        bottom={{ base: 0 }}
        left={{ base: 0 }}
        display="flex"
        flexDirection="column"
        align="center"
        w="100%"
        zIndex={GlobalStyleVariables.FloatingContentZIndex}
      >
        <Box w="100%" height="32px" background="linear-gradient(180deg, rgba(255, 247, 244, 0) 0%, #FFF7F4 100%)" />
        <Box w="100%" bg={{ base: Colors.PrimaryBackground }} paddingBottom={{ base: '16px' }}>
          {children}
        </Box>
      </Flex>
    </>
  );
};
