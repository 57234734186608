import React from 'react';

import { useClipboard } from 'use-clipboard-copy';

import { Box, Flex, Input, Text } from '@chakra-ui/react';

import { ModalWrapper } from '~/components/common/ModalWrapper';
import { TitleText } from '~/components/common/TitleText';
import { SocialMediaShareActions } from '~/components/modal/referral-link-modal/social-media-share-actions/SocialMediaShareActions';

import { useToaster } from '~/providers/toaster-provider/ToasterProvider';

import { trackEvent } from '~/helpers/analytics/trackEvent';

import { ModalTypes } from '~/constants/modalTypes';
import { Colors } from '~/constants/colors';
import { AnalyticsEvent } from '~/constants/analyticsEvent';

import { ReactComponent as CopyIcon } from '~/assets/icons/copy-icon.svg';

interface ReferralLinkModalProps {
  type: ModalTypes;
  isOpen: boolean;
  link: string;
  onClose: () => void;
}

const INPUT__BORDER_STYLES = {
  borderWidth: '2px',
  borderRightWidth: '0px',
  borderColor: Colors.Brown,
  borderLeftRadius: '16px',
  borderRightRadius: 0,
  bg: Colors.White,
};

export const ReferralLinkModal: React.FC<ReferralLinkModalProps> = function ReferralLinkModal({
  type,
  isOpen,
  link,
  onClose,
}) {
  const clipboard = useClipboard();
  const { showToast } = useToaster();

  const copyLinkAndShowToast = React.useCallback(() => {
    trackEvent({ eventName: AnalyticsEvent.QuoteCopyReferralLinkButtonClicked });

    clipboard.copy(link);

    showToast('Copied to clipboard');
  }, [clipboard, link, showToast]);

  return (
    <ModalWrapper
      type={type}
      isOpen={isOpen}
      onClose={onClose}
      actionButtonLabel="Close"
      onActionButtonPress={onClose}
      modalContentStyle={{
        padding: { base: '32px', lg: '48px' },
      }}
    >
      <Flex direction="column" align="center" w="100%">
        <Box>
          <TitleText
            style={{ maxW: { base: '280px', lg: '336px' }, align: 'center' }}
            text="Copy & share your unique referral code"
          />
        </Box>

        <Flex height="52px" w="100%" padding={{ lg: '0 80px' }} margin={{ base: '32px 0px', lg: '64px 0px' }}>
          <Input
            value={link}
            isReadOnly
            w="100%"
            h="100%"
            padding="8px 12px"
            fontSize="20px"
            lineHeight="32px"
            fontWeight="700"
            noOfLines={1}
            {...INPUT__BORDER_STYLES}
            _hover={INPUT__BORDER_STYLES}
            _focusVisible={INPUT__BORDER_STYLES}
          />
          <Flex
            as="button"
            onClick={copyLinkAndShowToast}
            backgroundColor={Colors.Orange}
            padding="8px 8px 8px 12px"
            borderTopRightRadius="16px"
            borderBottomRightRadius="16px"
            align="center"
          >
            <Box>
              <Text align="center" fontSize="16px" lineHeight="32px" fontWeight="700" color={Colors.White}>
                Copy
              </Text>
            </Box>

            <Box marginLeft="4px" w="20px" h="20px">
              <CopyIcon color={Colors.White} width="100%" height="100%" />
            </Box>
          </Flex>
        </Flex>

        <SocialMediaShareActions referralUrl={link} />
      </Flex>
    </ModalWrapper>
  );
};
