import React from 'react';
import { useField } from 'formik';

import { Stack } from '@chakra-ui/react';

import { Currency } from '@shared/constants/currency';

import { ActionButton } from '~/components/common/ActionButton';
import { FormikInput } from '~/components/form/formik-input/FormikInput';
import { TitleText } from '~/components/common/TitleText';
import { QuestionStack } from '~/components/common/QuestionStack';
import { PET_VALUE_FIELD } from '~/components/quote-form/quote-form.schema';

import { currencyFormatter } from '~/helpers/formatter/formatter';

import { useFormikInsuranceFormDisplayFormatPetName } from '~/hooks/useFormikInsuranceFormDisplayFormatPetName';

interface PetValueQuestionProps {
  actionButtonLabel: string;
  onActionButtonClick: (value?: string) => void;
}

export const PetValueQuestion: React.FC<PetValueQuestionProps> = function PetValueQuestion({
  actionButtonLabel,
  onActionButtonClick,
}) {
  const [, meta, formikHelpers] = useField(PET_VALUE_FIELD);

  const petName = useFormikInsuranceFormDisplayFormatPetName();

  const onGotPetForFreeClick = () => {
    const petValue = currencyFormatter('0');

    formikHelpers.setTouched(true);
    formikHelpers.setValue(petValue);

    onActionButtonClick(petValue);
  };

  return (
    <QuestionStack>
      <TitleText text={`How much did you pay/donate for ${petName}?`} />

      <Stack spacing={4} align="center">
        <FormikInput
          name={PET_VALUE_FIELD}
          placeholder={Currency.GBP}
          inputFormatter={currencyFormatter}
          keyEnterHandler={onActionButtonClick}
          variant="outline"
          size="md"
        />

        <ActionButton
          htmlFor={PET_VALUE_FIELD}
          onPress={onGotPetForFreeClick}
          label="I got my pet for free"
          isSecondary
        />
      </Stack>

      <ActionButton
        onPress={onActionButtonClick}
        label={actionButtonLabel}
        disabled={Boolean(!meta.value || meta.error)}
      />
    </QuestionStack>
  );
};
