import React from 'react';

import { Transaction } from '@sentry/types';

import { startSentryTransaction } from '~/helpers/monitoring/common';

export function useCurrentQuestionInstrumentation(currentQuestion?: string) {
  React.useEffect(() => {
    let questionTransaction: Transaction | undefined;
    if (currentQuestion) {
      const currentQuestionName = currentQuestion.toLowerCase();

      questionTransaction = startSentryTransaction(`${currentQuestionName}_transaction`);
    }

    return () => {
      questionTransaction?.finish();
    };
  }, [currentQuestion]);
}
