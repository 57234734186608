import React from 'react';
import { Button, ButtonProps, FormLabel, Text, TextProps } from '@chakra-ui/react';

import { Colors } from '~/constants/colors';
import { GlobalStyleVariables } from '~/constants/globalStyleVariables';

interface ActionButtonProps {
  label: string;
  isSecondary?: boolean;
  disabled?: boolean;
  style?: ButtonProps;
  labelStyle?: TextProps;
  onPress: () => void;
  htmlFor?: string;
}

export const ActionButton: React.FC<ActionButtonProps> = function ActionButton({
  label,
  isSecondary,
  disabled,
  style,
  labelStyle,
  onPress,
  htmlFor,
}) {
  return (
    <Button
      as={htmlFor ? FormLabel : undefined}
      htmlFor={htmlFor}
      onClick={onPress}
      disabled={disabled}
      type="button"
      padding={isSecondary ? 0 : '12px 20px 12px 20px'}
      w={isSecondary ? 'auto' : '320px'}
      h={isSecondary ? 'auto' : '56px'}
      bg={isSecondary ? Colors.Transparent : Colors.Orange}
      borderRadius="40px"
      opacity={disabled ? 0.2 : 1}
      zIndex={isSecondary ? 1 : GlobalStyleVariables.FloatingContentZIndex} // to be in front of the "Help" button
      _hover={{ opacity: 0.5 }}
      _active={{}}
      {...style}
    >
      <Text
        fontSize={isSecondary ? '18px' : '20px'}
        lineHeight="32px"
        fontWeight="700"
        color={isSecondary ? Colors.Orange : Colors.White}
        {...labelStyle}
      >
        {label}
      </Text>
    </Button>
  );
};
