import React from 'react';
import { Detail } from 'react-calendar';
import { useField } from 'formik';
import moment from 'moment-timezone';
import DatePicker from 'react-date-picker/dist/entry.nostyle';
import { Stack } from '@chakra-ui/react';

import { POLICY_OWNER_MAX_AGE_IN_YEARS, POLICY_OWNER_MIN_AGE_IN_YEARS } from '@shared/constants/validation';

import { FormikInputErrorText } from '~/components/form/formik-input-error-text/FormikInputErrorText';
import { EXISTING_POLICY_FINISH_DATE, PET_DATE_OF_BIRTH_FIELD } from '~/components/quote-form/quote-form.schema';
import { DATE_OF_OWNER_BIRTH_FIELD } from '~/components/customer-form/customer-form.schema';

import { getMomentFromLocaleDate, getTodaysDate } from '~/helpers/date/date';

import { CALENDAR_DATE_FORMAT_MAP, CalendarUnitsToPick } from '~/constants/calendar';

import '~/components/form/formik-date-input/FormikDateInput.scss';

const CALENDAR_DETAILS_MAP: Record<
  CalendarUnitsToPick,
  {
    maxDetail: Detail;
    minDetail: Detail;
    defaultView: Detail;
  }
> = {
  day: {
    maxDetail: 'month',
    minDetail: 'decade',
    defaultView: 'year',
  },
  month: {
    maxDetail: 'year',
    minDetail: 'decade',
    defaultView: 'year',
  },
  year: {
    maxDetail: 'decade',
    minDetail: 'decade',
    defaultView: 'decade',
  },
};

const EXISTING_POLICY_MAX_FINISH_DATE_FROM_TODAY_IN_YEARS = 1;

export type DateQuestionFieldName =
  | typeof PET_DATE_OF_BIRTH_FIELD
  | typeof DATE_OF_OWNER_BIRTH_FIELD
  | typeof EXISTING_POLICY_FINISH_DATE;

const PET_MAX_AGE_TO_PICK_IN_YEARS = 30;
interface FormikDateInputProps {
  name: DateQuestionFieldName; // is used to bind this question with Formik
  calendarDateFormat?: string;
  unitsToPick?: CalendarUnitsToPick;
  defaultView?: Detail;
  inputMinDate?: Date;
  inputMaxDate?: Date;
}

const DATE_INPUT_MIN_DATE_CONFIG: Record<DateQuestionFieldName, Date> = {
  dateOfBirth: getTodaysDate()
    .subtract(PET_MAX_AGE_TO_PICK_IN_YEARS, 'years')
    // to not include the last date
    .add(1, 'days')
    .toDate(),
  dateOfOwnerBirth: getTodaysDate().subtract(POLICY_OWNER_MAX_AGE_IN_YEARS, 'years').add(1, 'days').toDate(),
  existingPolicyFinishDate: getTodaysDate().toDate(),
};

const DATE_INPUT_MAX_DATE_CONFIG: Record<DateQuestionFieldName, Date> = {
  dateOfBirth: getTodaysDate().toDate(),
  dateOfOwnerBirth: getTodaysDate().subtract(POLICY_OWNER_MIN_AGE_IN_YEARS, 'years').toDate(),
  existingPolicyFinishDate: getTodaysDate().add(EXISTING_POLICY_MAX_FINISH_DATE_FROM_TODAY_IN_YEARS, 'years').toDate(),
};

export const FormikDateInput: React.FC<FormikDateInputProps> = function FormikDateInput({
  name,
  calendarDateFormat,
  unitsToPick = 'day',
  defaultView,
}) {
  const dateFormat = calendarDateFormat ?? CALENDAR_DATE_FORMAT_MAP[unitsToPick];

  const [formikField, , formikHelpers] = useField(name);
  const dateValue = formikField.value ? moment(formikField.value, dateFormat).toDate() : null;

  // React DatePicker could accept date range as an input param
  const onDateChange = (nextValue: Date | [Date] | [Date, Date] | null) => {
    // Date picker can return arrays for date ranges, which we don't need,
    // but we have to check to make TS happy
    if (Array.isArray(nextValue)) {
      return;
    }

    formikHelpers.setTouched(true);
    if (!nextValue) {
      formikHelpers.setValue('');
    } else {
      formikHelpers.setValue(getMomentFromLocaleDate(nextValue).format(dateFormat));
    }
  };

  const minDate = DATE_INPUT_MIN_DATE_CONFIG[name];

  const maxDate = DATE_INPUT_MAX_DATE_CONFIG[name];

  return (
    <Stack spacing={1}>
      <DatePicker
        className="date-picker-input"
        value={dateValue}
        defaultValue={maxDate}
        onChange={onDateChange}
        minDate={minDate}
        maxDate={maxDate}
        {...CALENDAR_DETAILS_MAP[unitsToPick]}
        defaultView={defaultView ?? CALENDAR_DETAILS_MAP[unitsToPick].defaultView}
        {...(!dateValue ? { clearIcon: null } : {})}
        dayPlaceholder="DD"
        monthPlaceholder="MM"
        yearPlaceholder="YYYY"
        showLeadingZeros
      />

      <FormikInputErrorText fieldName={name} />
    </Stack>
  );
};
