import React from 'react';
import { Detail } from 'react-calendar';
import { useField } from 'formik';
import { Stack } from '@chakra-ui/react';

import { ActionButton } from '~/components/common/ActionButton';
import { DateQuestionFieldName, FormikDateInput } from '~/components/form/formik-date-input/FormikDateInput';
import { TitleText } from '~/components/common/TitleText';
import { QuestionStack } from '~/components/common/QuestionStack';

import { useKeyEnterScreenHandler } from '~/hooks/common/useKeyEnterScreenHandler';

import type { CalendarUnitsToPick } from '~/constants/calendar';

interface DateInputQuestionProps {
  name: DateQuestionFieldName; // is used to bind this question with Formik
  questionText: string; // label for question input
  actionButtonLabel: string;
  onActionButtonClick: () => void;
  calendarDateFormat?: string;
  unitsToPick?: CalendarUnitsToPick;
  defaultView?: Detail;
  inputExtraComponent?: React.ReactElement;
  className?: string;
  isFieldOptional?: boolean;
}

export const DateInputQuestion: React.FC<React.PropsWithChildren<DateInputQuestionProps>> = function DateInputQuestion({
  name,
  questionText,
  actionButtonLabel,
  onActionButtonClick,
  calendarDateFormat,
  unitsToPick = 'day',
  defaultView,
  children,
  className,
  isFieldOptional,
}) {
  const [, meta] = useField(name);
  const isActionButtonDisabled = React.useMemo(
    () => Boolean(!isFieldOptional && (!meta.value || meta.error)),
    [isFieldOptional, meta.error, meta.value],
  );

  useKeyEnterScreenHandler({ actionToCall: onActionButtonClick, isActionDisabled: isActionButtonDisabled });

  return (
    <QuestionStack className={className}>
      <TitleText text={questionText} />

      <Stack spacing={4} align="center">
        <FormikDateInput
          name={name}
          calendarDateFormat={calendarDateFormat}
          unitsToPick={unitsToPick}
          defaultView={defaultView}
        />

        {children}
      </Stack>

      <ActionButton label={actionButtonLabel} onPress={onActionButtonClick} disabled={isActionButtonDisabled} />
    </QuestionStack>
  );
};

// Todo (later):
//  4. Update "manual input change" validation
//   - it's a problem for now, because "onChange" method is not triggered in case the input is not valid (more than maxDate / less than minDate)
//   - will be fixed later
