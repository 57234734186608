import React from 'react';
import { Stack, Text } from '@chakra-ui/react';

import { Colors } from '~/constants/colors';

interface PolicyBenefitParagraphItemProps {
  text: string;
}

export const PolicyBenefitParagraphItem: React.FC<PolicyBenefitParagraphItemProps> =
  function PolicyDeclarationParagraphItem({ text }) {
    return (
      <Stack direction={{ base: 'column', lg: 'row' }} spacing="12px" display="flex" alignItems="center" height="auto">
        <Text textAlign="center" fontSize="14px" lineHeight="18px" color={Colors.Brown}>
          {text}
        </Text>
      </Stack>
    );
  };
