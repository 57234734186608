import { PolicyCodes } from '@joinfluffy/common';

import { useSelectedPolicyPriceForAllPets } from '~/hooks/insurance-data-provider/policy/useSelectedPolicyPriceForAllPets';
import { useInsuringPetsCount } from '~/hooks/insurance-data-provider/useInsuringPetsCount';

import { roundToDecimalFixed } from '~/helpers/numbers.utils';

export function usePolicyPricingData(selectedPolicyCode: PolicyCodes) {
  const policyPriceForAllPets = useSelectedPolicyPriceForAllPets(selectedPolicyCode);

  const totalDiscount = roundToDecimalFixed(policyPriceForAllPets?.totalDiscount ?? 0);

  const originalAnnualPrice =
    policyPriceForAllPets && roundToDecimalFixed(totalDiscount + policyPriceForAllPets.annualPrice);

  const petsCount = useInsuringPetsCount();

  const petsCountPriceLabel = petsCount > 1 ? ` for ${petsCount} pets` : '';

  return {
    monthlyPrice: policyPriceForAllPets?.monthlyPrice,
    annualPrice: policyPriceForAllPets?.annualPrice,
    originalAnnualPrice: originalAnnualPrice,
    originalMontlyPrice: roundToDecimalFixed(Number(originalAnnualPrice) / 12),
    discount: totalDiscount,
    petsCountPriceLabel,
  };
}
