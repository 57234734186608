import { PolicyCodes, ProductBenefitSchema, getAvailableInsuranceBenefitsSetInViewOrder } from '@joinfluffy/common';

import { useSelectedPolicyData } from '~/hooks/insurance-data-provider/policy/useSelectedPolicyData';
import { useFilledQuoteFormValuesIsInsuringDog } from '~/hooks/insurance-data-provider/useFilledQuoteFormValuesIsInsuringDog';

export function useSelectedPolicyPerks(policyCode: PolicyCodes): ProductBenefitSchema[] | undefined {
  const selectedPolicyData = useSelectedPolicyData(policyCode);
  const isInsuringDog = useFilledQuoteFormValuesIsInsuringDog();

  if (!selectedPolicyData) {
    return;
  }

  return getAvailableInsuranceBenefitsSetInViewOrder(selectedPolicyData.productBenefits, isInsuringDog);
}
