import moment from 'moment-timezone';

import { AnyObject } from 'yup/lib/types';

import { INTERNAL_DATE_FORMAT } from '@joinfluffy/common';

import { DD_MM_YYYY_REGEXP } from '@shared/constants/regexp';

export function checkPetDateIsValid(value?: string | null, context?: AnyObject) {
  if (!context) {
    return false;
  }

  const date = moment(value, INTERNAL_DATE_FORMAT);

  return date.isValid() && !date.isAfter(moment());
}

export function checkPetDateFormatIsCorrect(value?: string | null, context?: AnyObject) {
  if (!context) {
    return false;
  }

  if (value?.match(DD_MM_YYYY_REGEXP)) {
    return true;
  } else {
    return context.createError({ message: `Date format should be: ${INTERNAL_DATE_FORMAT}` });
  }
}
