import React from 'react';
import { Box, Text, Image, Flex, BoxProps, FlexProps } from '@chakra-ui/react';

import { Colors } from '~/constants/colors';

import { ReactComponent as InfoIcon } from '~/assets/icons/info-icon.svg';

interface PolicyPerkItemProps {
  imgSrc: string;
  title: string;
  subTitle?: string;
  disableClick?: boolean;
  style?: FlexProps;
  titleContainerStyle?: BoxProps;
}

export const PerkItem: React.FC<PolicyPerkItemProps> = function PerkItem({
  imgSrc,
  title,
  subTitle,
  disableClick,
  style,
  titleContainerStyle,
}) {
  return (
    <Flex
      position="relative"
      direction="column"
      justify="center"
      padding="8px"
      align="center"
      w="145px"
      h="145px"
      borderRadius="24px"
      bg={Colors.GreyLight}
      {...(!disableClick && {
        _hover: { boxShadow: '0 8px 12px -4px rgb(0 0 0 / 5%)', transform: 'scale(1.02)' },
      })}
      {...style}
    >
      {!disableClick && (
        <Box position="absolute" top="10px" left="120px">
          <InfoIcon width="50%" height="50%" />
        </Box>
      )}
      <Image src={imgSrc} boxSize="64px" />
      <Box {...titleContainerStyle}>
        <Text fontSize="12px" lineHeight="20px" fontWeight="700" color={Colors.Brown} verticalAlign="center">
          {title}
        </Text>
        {subTitle && (
          <Text fontSize="12px" lineHeight="20px" fontWeight="700" color={Colors.Orange}>
            {subTitle}
          </Text>
        )}
      </Box>
    </Flex>
  );
};
