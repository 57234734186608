import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';

import { getHttpsCallableFunction } from '~/hooks/useInitFirebase';

import { CloudFunction } from '~/constants/functionName';

interface UserReferralLinkResponse {
  referralLink: string;
}

export async function fetchReferralCode(userEmail: string) {
  try {
    const fetchUserReferralLink = getHttpsCallableFunction(CloudFunction.fetchUserReferralLink);

    const userReferralLinkResponse = await fetchUserReferralLink({ email: userEmail });

    return (userReferralLinkResponse.data as UserReferralLinkResponse).referralLink;
  } catch (e) {
    captureAndLogException(e, 'Error');

    return;
  }
}
