import React from 'react';

const MAX_PERCENTAGE = 100;

export function useProgressBarPercentageValue(durationMs: number, totalSteps: number, maxPercentage = MAX_PERCENTAGE) {
  const [loaderPercentage, setLoaderPercentage] = React.useState(0);

  const stepDuration = React.useMemo(() => Math.ceil(durationMs / totalSteps), [durationMs, totalSteps]);
  const stepPercentageChange = React.useMemo(() => maxPercentage / totalSteps, [maxPercentage, totalSteps]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setLoaderPercentage((prevPercentage) => {
        if (prevPercentage < MAX_PERCENTAGE) {
          return prevPercentage + stepPercentageChange;
        } else {
          clearInterval(interval);
          return prevPercentage;
        }
      });
    }, stepDuration);

    return () => clearInterval(interval);
  }, [stepDuration, stepPercentageChange]);

  return { loaderPercentage };
}
