export const DEFAULT_MAX_STRING_LENGTH = 100;
export const LOOKUP_MAX_STRING_LENGTH = 150;
export const PHONE_NUMBER_MIN_STRING_LENGTH = 3;
export const PHONE_NUMBER_MAX_STRING_LENGTH = 50;
export const POSTCODE_MAX_STRING_LENGTH = 50;
export const MIN_AGE_FOR_INSURANCE_IN_WEEKS = 8;
export const DOG_MAX_AGE_FOR_INSURANCE_IN_YEARS = 8;
export const CAT_MAX_AGE_FOR_INSURANCE_IN_YEARS = 10;
export const POLICY_OWNER_MIN_AGE_IN_YEARS = 18;
export const POLICY_OWNER_MAX_AGE_IN_YEARS = 200;
export const MAX_POLICY_START_DATE_DAY_COUNT_FROM_TODAY = 30;
