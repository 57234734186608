import React from 'react';

import moment from 'moment-timezone';

import * as Sentry from '@sentry/react';

import { PolicyCodes } from '@joinfluffy/common';

import { PetQuote, ProductQuoteSchema, QuoteSchema, StorageQuoteDto } from '@shared/models/product';

import { ExternalSource } from '@shared/constants/externalSource';

import { EMAIL_FIELD, QuoteFormValues, OPTIONAL_PHONE_NUMBER_FIELD } from '~/components/quote-form/quote-form.schema';
import {
  ARE_ASSUMPTIONS_READ_FIELD,
  ARE_POLICY_AND_BUSINESS_DOCUMENTS_READ_FIELD,
  CUSTOMER_FORM_INITIAL_VALUE,
  CustomerFormValues,
  DATE_OF_OWNER_BIRTH_FIELD,
  FIRST_NAME_FIELD,
  LAST_NAME_FIELD,
  PAYMENT_TYPE_FIELD,
  PHONE_NUMBER_FIELD,
  POLICY_CODE_FIELD,
  TITLE_FIELD,
} from '~/components/customer-form/customer-form.schema';

import { filterUnsupportedProducts } from '~/helpers/productQuote/productQuote';
import { createPartialQuoteDtosFromFormValues, createQuoteDtoFromFormsValues } from '~/helpers/dto';
import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';
import { identify } from '~/helpers/analytics/identify';
import {
  buildQuotingIdentityFieldName,
  normalizeIdentityObject,
  resetAnalyticsUser,
} from '~/helpers/analytics/userAnalyticsIdentity';

import { useInvokeErrorModal } from '~/hooks/useInvokeErrorModal';
import { useInvokeConfirmationModal } from '~/hooks/useInvokeConfirmationModal';
import { useUiStateUpdateIsLoading } from '~/hooks/useUiStateUpdateIsLoading';
import { useUiStateContextPatchUiState } from '~/hooks/useUiStateContextUpdateUi';

import { ensurePolicyStartDateIsNotInPast } from '~/helpers/date/policyStartDate';
import {
  invokeFetchPurchaseUrlApi,
  convertStorageQuoteToFilledQuoteFormValues,
  getCustomerFormValuesFromQuote,
  invokeFetchQuoteWithPartialRequestDataApi,
  navigateToQuotePaymentPage,
  cleanStorageQuoteDtoMockData,
} from '~/helpers/quote/quoteApi/quoteApi';
import { cleanLocallyStoredAllFormData } from '~/helpers/quote/locallyStoredValues';
import {
  cleanLocallyStoredCustomerFormData,
  getCustomerFormValuesStoredLocally,
  saveCustomerFormValuesLocally,
} from '~/helpers/quote/locallyStoredCustomerFormValues';
import { convertStorageQuoteDtoToQuote } from '~/helpers/storageQuoteDto/storageQuoteDto';
import { trackEvent } from '~/helpers/analytics/trackEvent';
import { addSentryBreadcrumb } from '~/helpers/monitoring/common';

import {
  findAndSetQuoteFormIncompleteQuestionIdxAsCurrent,
  isQuoteFormFullfilled,
} from '~/providers/insurance-data-provider/helpers/common';

import { FetchPurchaseUrlError } from '~/error/FetchPurchaseUrlError';

import {
  addCustomerQuoteFormValuesToFilledQuoteFormValues,
  calculatePolicyPriceForAllPets,
  getPoliciesToDisplay,
} from '~/providers/insurance-data-provider/helpers/common';

import { AnalyticsEvent } from '~/constants/analyticsEvent';
import { LocalStorageKey } from '~/constants/localStorageKey';

import { FilledQuoteFormValuesSchema, INITIAL_FILLED_QUOTE_FORM_VALUES, PolicyPriceForAllPets } from './types';

export interface InsuranceDataContextSchema {
  quoteId?: string;
  isCustomerFormFulfilled: boolean;
  filledQuoteFormValues: FilledQuoteFormValuesSchema;
  filledCustomerFormValues: CustomerFormValues;
  policyList?: ProductQuoteSchema[];
  petQuotesList?: PetQuote[];
  policyPriceForAllPets?: PolicyPriceForAllPets;
  updateFilledQuoteFormValues: (values: FilledQuoteFormValuesSchema) => void;
  setIsCustomerFormFulfilled: (value: boolean) => void;
  quoteFormSubmitHandler: (petFormData: QuoteFormValues) => Promise<void>;
  customerFormSubmitHandler: (customerFormData: CustomerFormValues) => Promise<void>;
  cleanPolicyList: () => void;
  setExternalQuoteResponseData: (
    response: StorageQuoteDto,
    selectedPolicy?: PolicyCodes,
    isExternalCall?: boolean,
  ) => void;
  restartQuoteForm: () => void;
  setOriginalExternalSource: (source: ExternalSource) => void;
  submitQuoteFormWithDiscountCode: (discountCode: string) => Promise<void>;
}

export const InsuranceDataContext = React.createContext<InsuranceDataContextSchema | undefined>(undefined);

export const InsuranceDataProvider: React.FC<React.PropsWithChildren> = function InsuranceDataProvider({ children }) {
  const [policyList, setPolicyList] = React.useState<ProductQuoteSchema[] | undefined>(undefined);
  const [petQuotesList, setPetQuotesList] = React.useState<PetQuote[] | undefined>(undefined);
  const [quoteId, setQuoteId] = React.useState<string | undefined>(undefined);
  const [isCustomerFormFulfilled, setIsCustomerFormFulfilled] = React.useState<boolean>(false);
  const [originalExternalSource, setOriginalExternalSource] = React.useState<ExternalSource | undefined>(undefined);
  const [policyPriceForAllPets, setPolicyPriceForAllPets] = React.useState<PolicyPriceForAllPets | undefined>(
    undefined,
  );

  const [filledQuoteFormValues, setFilledQuoteFormValues] = React.useState<FilledQuoteFormValuesSchema>(
    INITIAL_FILLED_QUOTE_FORM_VALUES,
  );
  const [filledCustomerFormValues, setFilledCustomerFormValues] =
    React.useState<CustomerFormValues>(CUSTOMER_FORM_INITIAL_VALUE);

  const updateUiIsLoading = useUiStateUpdateIsLoading();

  const { invokeErrorModal } = useInvokeErrorModal();
  const { invokeConfirmationModal } = useInvokeConfirmationModal();
  const patchUiState = useUiStateContextPatchUiState();

  const updateFilledQuoteFormValues = React.useCallback(
    (values: FilledQuoteFormValuesSchema) => {
      setFilledQuoteFormValues({ ...filledQuoteFormValues, ...values });
    },
    [filledQuoteFormValues],
  );

  const processPetQuotes = React.useCallback((quotesList: PetQuote[]) => {
    filterUnsupportedProducts(quotesList);

    setPetQuotesList(quotesList);

    Sentry.addBreadcrumb({
      category: 'log',
      message: 'filterUnsupportedProducts successful',
      data: quotesList,
      level: 'info',
    });

    const prices = calculatePolicyPriceForAllPets(quotesList);

    setPolicyPriceForAllPets(prices);

    const displayProducts = getPoliciesToDisplay(quotesList);

    Sentry.addBreadcrumb({
      category: 'log',
      message: 'normaliseProductOrderByVetFeeAmount successful',
      data: quotesList,
      level: 'info',
    });

    setPolicyList(displayProducts);

    Sentry.addBreadcrumb({
      category: 'log',
      message: 'setPolicyList successful',
      level: 'info',
    });

    return displayProducts;
  }, []);

  /**
   * Set the quotes to state and apply side effects, like storing quoteId in Local Storage
   *
   * @param quoteData quote data from API
   */
  const processQuoteData = React.useCallback(
    (quoteData: QuoteSchema | void): ProductQuoteSchema[] => {
      if (!quoteData) {
        captureAndLogException('[fetchQuoteData]: API call returned no policies', 'Error');
      }
      const quoteDataTyped = quoteData as QuoteSchema;
      // TODO [INSR-211]: add  a policy ID once CM start supplying in Thank You page query params, use for Awin tracking
      localStorage.setItem(LocalStorageKey.SavedQuoteId, quoteDataTyped.quoteId);

      setQuoteId(quoteDataTyped.quoteId);

      return processPetQuotes(quoteDataTyped.petQuotes);
    },
    [processPetQuotes],
  );

  const fetchQuotesWithFilledQuoteFormValues = React.useCallback(
    async (filledQuoteFormData: FilledQuoteFormValuesSchema, discountCode?: string) => {
      updateUiIsLoading(true);

      localStorage.setItem(LocalStorageKey.SavedCustomerEmail, filledQuoteFormData[EMAIL_FIELD]);

      addSentryBreadcrumb({
        category: 'event_breadcrumb',
        message: discountCode
          ? `[fetchQuotesWithFilledQuoteFormValues]: fetching quotes with discout code`
          : `[fetchQuotesWithFilledQuoteFormValues]: set filled form values`,
        data: { filledQuoteFormData, ...(discountCode && { discountCode }) },
        level: 'info',
      });

      // reset 2nd stage of the customer form:
      setIsCustomerFormFulfilled(false);
      cleanLocallyStoredCustomerFormData();

      addSentryBreadcrumb({
        category: 'event_breadcrumb',
        message: `[fetchQuotesWithFilledQuoteFormValues]: cleaned Locally Stored Customer Form Data`,
        level: 'info',
      });

      if (filledQuoteFormData[OPTIONAL_PHONE_NUMBER_FIELD]) {
        addSentryBreadcrumb({
          category: 'event_breadcrumb',
          message: `[fetchQuotesWithFilledQuoteFormValues]: setting phone number to customerForm as optional phone number filled`,
          data: { optionalPhoneNumber: filledQuoteFormData[OPTIONAL_PHONE_NUMBER_FIELD] },
          level: 'info',
        });

        setFilledCustomerFormValues({
          ...filledCustomerFormValues,
          phoneNumber: filledQuoteFormData[OPTIONAL_PHONE_NUMBER_FIELD],
        });
      }

      const dtosCreationResult = createPartialQuoteDtosFromFormValues(filledQuoteFormData);

      addSentryBreadcrumb({
        category: 'event_breadcrumb',
        message: `[fetchQuotesWithFilledQuoteFormValues]: created dto`,
        data: { dtosCreationResult },
        level: 'info',
      });

      if (dtosCreationResult) {
        await invokeFetchQuoteWithPartialRequestDataApi({
          customerDto: dtosCreationResult.customerDto,
          petPolicyDtos: dtosCreationResult.petPolicyDtos,
          options: {
            originalExternalSource,
            existingPolicyFinishDate: dtosCreationResult.existingPolicyFinishDate,
            discountCode,
          },
        })
          .catch((e) => {
            invokeErrorModal({ error: e });

            captureAndLogException(
              '[fetchQuotesWithFilledQuoteFormValues]: issue with fetching a quote. ' + e.message,
              'Error',
            );

            // to stop chain processing
            return Promise.reject(e);
          })
          .then(processQuoteData)
          .then((productQuotes) => {
            localStorage.setItem(LocalStorageKey.SubmittedQuoteFormAt, moment().valueOf().toString());

            return productQuotes;
          })
          .then(() => {
            if (petQuotesList) {
              addProductPricesToIdentity(petQuotesList, dtosCreationResult.customerDto.email);
            }
          })
          .catch((e) => {
            invokeErrorModal({ error: e });

            captureAndLogException(
              '[fetchQuotesWithFilledQuoteFormValues]: issue with processing quote data. ' + e.message,
              'Error',
            );
          });
      }

      addSentryBreadcrumb({
        category: 'event_breadcrumb',
        message: `[fetchQuotesWithFilledQuoteFormValues]: finished BE invocation`,
        level: 'info',
      });

      // todo: remove ui-context's isLoading in cases, when we can use Formik state - https://linear.app/joinfluffy/issue/INSR-278
      updateUiIsLoading(false);
    },
    [
      filledCustomerFormValues,
      invokeErrorModal,
      originalExternalSource,
      petQuotesList,
      processQuoteData,
      updateUiIsLoading,
    ],
  );

  const quoteFormSubmitHandler = React.useCallback(
    async (quoteFormData: QuoteFormValues) => {
      const updatedFilledQuoteFormValues = addCustomerQuoteFormValuesToFilledQuoteFormValues(
        quoteFormData,
        filledQuoteFormValues,
      );

      setFilledQuoteFormValues(updatedFilledQuoteFormValues);

      await fetchQuotesWithFilledQuoteFormValues(updatedFilledQuoteFormValues);
    },
    [fetchQuotesWithFilledQuoteFormValues, filledQuoteFormValues],
  );

  const submitQuoteFormWithDiscountCode = React.useCallback(
    async (discountCode: string) => {
      await fetchQuotesWithFilledQuoteFormValues(filledQuoteFormValues, discountCode);
    },
    [fetchQuotesWithFilledQuoteFormValues, filledQuoteFormValues],
  );

  const customerFormSubmitHandler = React.useCallback(
    async (customerFormData: CustomerFormValues) => {
      const paymentType = customerFormData[PAYMENT_TYPE_FIELD];

      addSentryBreadcrumb({
        category: 'event_breadcrumb',
        message: `[customerFormSubmitHandler]: saved filled form value locally`,
        data: {
          [POLICY_CODE_FIELD]: customerFormData[POLICY_CODE_FIELD],
          [ARE_POLICY_AND_BUSINESS_DOCUMENTS_READ_FIELD]:
            customerFormData[ARE_POLICY_AND_BUSINESS_DOCUMENTS_READ_FIELD],
          [PAYMENT_TYPE_FIELD]: paymentType,
        },
        level: 'info',
      });

      const dtoCreationResult = createQuoteDtoFromFormsValues(filledQuoteFormValues, customerFormData);

      addSentryBreadcrumb({
        category: 'event_breadcrumb',
        message: `[customerFormSubmitHandler]: saved filled form value locally`,
        data: dtoCreationResult,
        level: 'info',
      });

      // find the plan with the highest annual price and pass the price to BE to calculate the discount
      const annualPrices = policyList?.map((policy) => policy.annualPrice) ?? [];
      const highestAnnualPrice = Math.max(...annualPrices);
      const minimalAnnualPrice = Math.min(...annualPrices);

      if (dtoCreationResult && quoteId) {
        await invokeFetchPurchaseUrlApi(
          dtoCreationResult.customerDto,
          dtoCreationResult.petPolicyDtos,
          quoteId,
          minimalAnnualPrice,
          highestAnnualPrice,
          customerFormData[POLICY_CODE_FIELD],
          dtoCreationResult.paymentType,
        )
          .catch((e) => {
            invokeErrorModal({ error: e });

            captureAndLogException(
              '[customerFormSubmitHandler]: issue with fetching a purchaseUrl. ' + e.message,
              'Error',
            );

            throw new FetchPurchaseUrlError({ message: e.message, options: { cause: e } });
          })
          .then((purchaseUrl: string | void) => {
            // in case of an error in API purchaseUrl is void

            addSentryBreadcrumb({
              category: 'event_breadcrumb',
              message: `[customerFormSubmitHandler]: not defined purchaseUrl`,
              level: 'info',
            });

            if (!purchaseUrl) {
              captureAndLogException('[customerFormSubmitHandler]: purchaseUrl not defined.', 'Error');

              throw new FetchPurchaseUrlError({ message: '[customerFormSubmitHandler]: purchaseUrl not defined.' });
            }

            // save when we are sure data is stored in db
            saveCustomerFormValuesLocally({
              [POLICY_CODE_FIELD]: customerFormData[POLICY_CODE_FIELD],
              [ARE_POLICY_AND_BUSINESS_DOCUMENTS_READ_FIELD]:
                customerFormData[ARE_POLICY_AND_BUSINESS_DOCUMENTS_READ_FIELD],
              [ARE_ASSUMPTIONS_READ_FIELD]: customerFormData[ARE_ASSUMPTIONS_READ_FIELD],
              [PAYMENT_TYPE_FIELD]: dtoCreationResult.paymentType,
              [DATE_OF_OWNER_BIRTH_FIELD]: customerFormData[DATE_OF_OWNER_BIRTH_FIELD],
              [TITLE_FIELD]: customerFormData[TITLE_FIELD],
              [FIRST_NAME_FIELD]: customerFormData[FIRST_NAME_FIELD],
              [LAST_NAME_FIELD]: customerFormData[LAST_NAME_FIELD],
              [PHONE_NUMBER_FIELD]: customerFormData[PHONE_NUMBER_FIELD],
            });

            navigateToQuotePaymentPage(purchaseUrl, dtoCreationResult.paymentType);
          })
          .catch((e) => {
            invokeErrorModal({ error: e });

            captureAndLogException(
              '[customerFormSubmitHandler]: issue with navigating to Payment page. ' + e.message,
              'Error',
            );

            throw new FetchPurchaseUrlError({ message: e.message, options: { cause: e } });
          });

        addSentryBreadcrumb({
          category: 'event_breadcrumb',
          message: `[customerFormSubmitHandler]: finished BE invocation`,
          level: 'info',
        });
      } else {
        captureAndLogException(
          '[customerFormSubmitHandler]: issue with processing quote data: dtoCreationResult or previosly fetched quoteId is not defined',
          'Error',
        );

        throw new FetchPurchaseUrlError({
          message:
            '[customerFormSubmitHandler]: issue with processing quote data: dtoCreationResult or previosly fetched quoteId is not defined',
        });
      }
    },
    [filledQuoteFormValues, invokeErrorModal, policyList, quoteId],
  );

  async function addProductPricesToIdentity(petQuotes: PetQuote[], email: string) {
    const price = calculatePolicyPriceForAllPets(petQuotes);

    await Promise.all(
      Object.keys(price).map(async (key: string) => {
        const monthlyIdentityFieldName = buildQuotingIdentityFieldName(key + '_monthly_price');
        const annualIdentityFieldName = buildQuotingIdentityFieldName(key + '_annual_price');
        await identify(email, {
          [monthlyIdentityFieldName]: price[key as PolicyCodes].monthlyPrice,
          [annualIdentityFieldName]: price[key as PolicyCodes].annualPrice,
        });
      }),
    );
  }

  const setExternalQuoteResponseData = React.useCallback(
    (storageQuoteDto: StorageQuoteDto, selectedPolicy?: PolicyCodes, isExternalCall?: boolean) => {
      ensurePolicyStartDateIsNotInPast(storageQuoteDto.petQuotes, {
        callbackAfterChanges: () => {
          invokeConfirmationModal({
            actionButtonLabel: 'Got it',
            title: "We've set your policy start date to today",
            description:
              'We have received your details, however the policy start date appears to be in the past. To fix that, we have set the policy start date to today. You can also change the start date by navigating back on the form',
            hideSecondaryOption: true,
          });
        },
      });

      const needToCleanMockData = cleanStorageQuoteDtoMockData(storageQuoteDto);

      const quoteFormValues = convertStorageQuoteToFilledQuoteFormValues(storageQuoteDto);

      setFilledQuoteFormValues(quoteFormValues);

      if (isExternalCall) {
        const identityValues = normalizeIdentityObject(quoteFormValues);

        identify(quoteFormValues[EMAIL_FIELD], identityValues);
      }

      const customerFormValues = getCustomerFormValuesFromQuote(storageQuoteDto, {
        selectedPolicy,
        needToCleanMockData,
      });
      setFilledCustomerFormValues(customerFormValues);

      if (!isQuoteFormFullfilled(quoteFormValues)) {
        findAndSetQuoteFormIncompleteQuestionIdxAsCurrent(quoteFormValues, setFilledQuoteFormValues);

        // to stay on Quote form
        return;
      }

      const quote = convertStorageQuoteDtoToQuote(storageQuoteDto);

      if (storageQuoteDto.createdAt) {
        localStorage.setItem(
          LocalStorageKey.SubmittedQuoteFormAt,
          moment(storageQuoteDto.createdAt).valueOf().toString(),
        );
      }

      resetAnalyticsUser().then(() => {
        localStorage.setItem(LocalStorageKey.SavedCustomerEmail, quoteFormValues[EMAIL_FIELD]);
        identify(quoteFormValues[EMAIL_FIELD], normalizeIdentityObject(quoteFormValues));
      });

      processQuoteData(quote);

      // setting fulfilled value
      const isCustomerFormFulfilledValue = Boolean(getCustomerFormValuesStoredLocally());
      setIsCustomerFormFulfilled(isCustomerFormFulfilledValue);
    },
    [processQuoteData, invokeConfirmationModal],
  );
  const cleanPolicyList = React.useCallback(() => setPolicyList(undefined), []);

  const restartQuoteForm = React.useCallback(() => {
    trackEvent({ eventName: AnalyticsEvent.QuoteConfirmFormReset });
    setFilledQuoteFormValues(INITIAL_FILLED_QUOTE_FORM_VALUES);
    patchUiState({ quoteForm: { isCalendarView: false } });
    cleanPolicyList();
    cleanLocallyStoredAllFormData();
  }, [cleanPolicyList, patchUiState]);

  return (
    <InsuranceDataContext.Provider
      value={{
        policyList,
        isCustomerFormFulfilled,
        filledQuoteFormValues,
        filledCustomerFormValues,
        quoteId,
        petQuotesList,
        policyPriceForAllPets,
        setIsCustomerFormFulfilled,
        updateFilledQuoteFormValues,
        quoteFormSubmitHandler,
        customerFormSubmitHandler,
        cleanPolicyList,
        setExternalQuoteResponseData,
        restartQuoteForm,
        setOriginalExternalSource,
        submitQuoteFormWithDiscountCode,
      }}
    >
      {children}
    </InsuranceDataContext.Provider>
  );
};
