import React from 'react';
import { useField } from 'formik';

import { POLICY_START_DATE_FIELD, QuoteFormValues } from '~/components/quote-form/quote-form.schema';

import { useInvokeConfirmationModal } from '~/hooks/useInvokeConfirmationModal';

import { ensurePolicyStartDateIsNotInPast } from '~/helpers/date/policyStartDate';
import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';

export function useQuoteFormPreSubmitValidationHandler() {
  const { invokeConfirmationModal } = useInvokeConfirmationModal();
  const [policyStartDateField, , policyStartDateFieldHelpers] =
    useField<QuoteFormValues[typeof POLICY_START_DATE_FIELD]>(POLICY_START_DATE_FIELD);

  const validateAndSetStartDateToTodayIfInThePast = React.useCallback(() => {
    const policyStartDate = policyStartDateField.value;

    if (!policyStartDate) {
      captureAndLogException('[quote-form pre-submit validation]: policyStartDateField is empty', 'Error');
      return false;
    }

    const policy = { policyStartDate };

    const hasChanges = ensurePolicyStartDateIsNotInPast([policy], {
      callbackAfterChanges: () => {
        invokeConfirmationModal({
          actionButtonLabel: 'Got it',
          title: "We've set your policy start date to today",
          description:
            'We have received your details, however the policy start date appears to be in the past. To fix that, we have set the policy start date to today. You can also change the start date by navigating back on the form',
          hideSecondaryOption: true,
        });
      },
    });

    if (hasChanges) {
      policyStartDateFieldHelpers.setValue(policy.policyStartDate);
    }

    return !hasChanges;
  }, [invokeConfirmationModal, policyStartDateField.value, policyStartDateFieldHelpers]);

  const verifyQuoteFormPreSubmitStateValidOrShowErrorModal = React.useCallback(() => {
    const isPolicyStartDateValid = validateAndSetStartDateToTodayIfInThePast();

    return isPolicyStartDateValid;
  }, [validateAndSetStartDateToTodayIfInThePast]);

  return { verifyQuoteFormPreSubmitStateValidOrShowErrorModal };
}
