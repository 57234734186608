import React from 'react';

import { Box, Image, Stack, Text } from '@chakra-ui/react';

import { ModalWrapper } from '~/components/common/ModalWrapper';

import { Colors } from '~/constants/colors';
import { ModalTypes } from '~/constants/modalTypes';

interface BaseModalProps {
  type: ModalTypes;
  isOpen: boolean;
  title: string;
  description: string;
  imgSrc: string;
  actionButtonLabel?: string;
  secondaryActionButtonLabel?: string;
  onClose: () => void;
  onActionButtonPress?: () => void;
  onSecondaryActionButtonPress?: () => void;
}

export const BaseModal: React.FC<BaseModalProps> = function BaseModal({
  type,
  isOpen,
  title,
  description,
  imgSrc,
  actionButtonLabel,
  secondaryActionButtonLabel,
  onClose,
  onActionButtonPress,
  onSecondaryActionButtonPress,
}) {
  return (
    <ModalWrapper
      type={type}
      isOpen={isOpen}
      onClose={onClose}
      actionButtonLabel={actionButtonLabel}
      secondaryActionButtonLabel={secondaryActionButtonLabel}
      onActionButtonPress={onActionButtonPress}
      onSecondaryActionButtonPress={onSecondaryActionButtonPress}
    >
      <Box height={{ base: '340px', lg: '450px' }} width={{ base: '340px', lg: '450px' }}>
        <Image src={imgSrc} height="100%" width="100%" />
      </Box>

      <Stack spacing="12px" align="center">
        <Box maxWidth={{ base: '296px', lg: '500px' }}>
          <Text fontSize="20px" lineHeight="32px" color={Colors.Brown} fontWeight="700" textAlign="center">
            {title}
          </Text>
        </Box>
        <Box>
          <Text
            fontSize={{ base: '12px', lg: '16px' }}
            lineHeight={{ base: '16px', lg: '24px' }}
            color={Colors.Brown}
            fontWeight="400"
            textAlign="center"
          >
            {description}
          </Text>
        </Box>
      </Stack>
    </ModalWrapper>
  );
};
