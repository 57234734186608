type DebounceCallback<ParamsType extends Array<unknown>> = (...params: ParamsType) => void;

export function debounce<ParamsType extends Array<unknown>>(
  cbFunction: DebounceCallback<ParamsType>,
  waitMs: number,
): DebounceCallback<ParamsType> {
  let timer: NodeJS.Timeout | null = null;

  return function process(...args: ParamsType) {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }

    timer = setTimeout(() => cbFunction(...args), waitMs);
  };
}

export function openUrlInNewTab(url: string) {
  window.open(url, '_blank', 'noreferrer');
}

export function openUrl(url: string) {
  window.location.href = url;
}

export function isDevEnv() {
  return process.env.REACT_APP_ENVIRONMENT === 'DEV';
}
