import React from 'react';
import { StackProps, Stack } from '@chakra-ui/react';

import { POLICY_DOCUMENT_STATEMENTS_URL, PRIVACY_POLICY_FLUFFY_URL } from '@joinfluffy/common';

import { ActionButton } from '~/components/common/ActionButton';

import { useInsuranceProductInformationLink } from '~/hooks/useInsuranceProductInformationLink';

import { openUrlInNewTab } from '~/helpers/common';

interface ProductButtonsProps {
  style?: StackProps;
}

export const ProductButtons: React.FC<ProductButtonsProps> = function ProductButtons({ style }) {
  const { productInformationLink } = useInsuranceProductInformationLink();

  const openPrivacyPolicy = () => {
    openUrlInNewTab(PRIVACY_POLICY_FLUFFY_URL);
  };

  const openProductInformation = () => {
    if (!productInformationLink) {
      return;
    }

    openUrlInNewTab(productInformationLink);
  };

  const openPolicyDocument = () => {
    openUrlInNewTab(POLICY_DOCUMENT_STATEMENTS_URL);
  };

  return (
    <Stack direction={{ base: 'column', lg: 'row' }} spacing="12px" justify="center" {...style}>
      <ActionButton
        labelStyle={{ fontSize: '14px', lineHeight: '24px' }}
        label="Privacy Policy"
        isSecondary
        onPress={openPrivacyPolicy}
      />
      <ActionButton
        labelStyle={{ fontSize: '14px', lineHeight: '24px' }}
        label="Insurance Product Information Document"
        isSecondary
        onPress={openProductInformation}
      />
      <ActionButton
        labelStyle={{ fontSize: '14px', lineHeight: '24px' }}
        label="Policy Document"
        isSecondary
        onPress={openPolicyDocument}
      />
    </Stack>
  );
};
