export interface PartnersBenefitSchema {
  imgSrc: string;
  title: string;
}

export const PARTNERS_BENEFITS: PartnersBenefitSchema[] = [
  {
    imgSrc: '/images/partners/butternut-box.png',
    title: 'Up to 50% off the first two boxes with Butternut Box',
  },
  {
    imgSrc: '/images/partners/different-dog.png',
    title: 'Up to 35% off the first two boxes with Different Dog',
  },
  {
    imgSrc: '/images/partners/forthglade.png',
    title: 'Up to 20% off the first order at Forthglade',
  },
  {
    imgSrc: '/images/partners/lilys-kitchen.png',
    title: "Up to 20% off orders at Lily's Kitchen",
  },
  {
    imgSrc: '/images/partners/yu-move.png',
    title: 'Up to 30% off on selected products at YuMove',
  },
  {
    imgSrc: '/images/partners/hello-fresh.png',
    title: 'Up to 60% off the first box at Hello Fresh',
  },
];
