import { PolicyCodes } from '@joinfluffy/common';

import {
  CURRENT_PET_IDX_FIELD,
  CURRENT_QUESTION_STEP_IDX,
  FilledPreviousluQuoteFormCustomerNecessaryFields,
  FilledPreviousluQuoteFormPetNecessaryFields,
  QuoteFormCustomerValues,
  QuoteFormField,
  QuoteFormPetValues,
  QuoteFormValues,
} from '~/components/quote-form/quote-form.schema';
import { quoteFormValidationSchema } from '~/components/quote-form/validation/quote-form.validation';

import { PetQuote } from '@shared/models/product';
import { findPolicyPetIndex } from '@shared/helpers/quote';

import { normaliseProductOrderByVetFeeAmount } from '~/helpers/productQuote/productQuote';
import { extractCustomerDataFromQuoteFormValues } from '~/helpers/quote/quoteApi/quoteApi';

import { QUOTE_FORM_FIELD_QUESTION_TYPE_CONFIG } from '~/configs/questionFormField';
import { QUOTE_FORM_QUESTION_ORDER_CONFIG } from '~/configs/questionsOrder/questionsOrder';

import { InvalidPetQuoteProductsError } from '~/error/InvalidPetQuoteProductsError';

import { FilledQuoteFormValuesSchema, PolicyPriceForAllPets } from '~/providers/insurance-data-provider/types';

const definedPolicyCodes = Object.values(PolicyCodes);

export function addCustomerQuoteFormValuesToFilledQuoteFormValues(
  quoteFormValues: QuoteFormValues,
  filledQuoteFormValues: FilledQuoteFormValuesSchema,
): FilledQuoteFormValuesSchema {
  const customerData = extractCustomerDataFromQuoteFormValues(quoteFormValues);

  return { ...filledQuoteFormValues, ...customerData };
}

export function isQuoteFormFullfilled(quoteFormData: FilledQuoteFormValuesSchema) {
  const { pets, ...customer } = quoteFormData;

  for (const pet of pets) {
    for (const petField of FilledPreviousluQuoteFormPetNecessaryFields) {
      if (!isFormFieldValid(pet, petField)) {
        return false;
      }
    }
  }

  for (const field of FilledPreviousluQuoteFormCustomerNecessaryFields) {
    if (!isFormFieldValid(customer, field)) {
      return false;
    }
  }

  return true;
}

export function findAndSetQuoteFormIncompleteQuestionIdxAsCurrent(
  quoteFormData: FilledQuoteFormValuesSchema,
  setFilledQuoteFormValues: (values: FilledQuoteFormValuesSchema) => void,
) {
  const { pets, ...customer } = quoteFormData;

  for (let petIdx = 0; petIdx < pets.length; petIdx++) {
    const currentPetAndCustomerFormData = { ...pets[petIdx], ...customer };

    const incompleteQuestionIdx = findIncompleteQuestionIdx(currentPetAndCustomerFormData);

    if (incompleteQuestionIdx !== -1) {
      setQuoteFormValuesWithIndex(quoteFormData, incompleteQuestionIdx, petIdx, setFilledQuoteFormValues);
      break;
    }
  }
}

function findIncompleteQuestionIdx(formData: QuoteFormValues): number {
  for (let questionIdx = 0; questionIdx < QUOTE_FORM_QUESTION_ORDER_CONFIG.length - 1; questionIdx++) {
    const question = QUOTE_FORM_QUESTION_ORDER_CONFIG[questionIdx];
    const questionFields = QUOTE_FORM_FIELD_QUESTION_TYPE_CONFIG[question];

    if (!Array.isArray(questionFields)) {
      if (questionFields && !isFormFieldValid(formData, questionFields)) {
        return questionIdx;
      }
    } else {
      // if question has > 1 answer field we need to validate each field
      for (let subQuestionIdx = 0; subQuestionIdx < questionFields.length; subQuestionIdx++) {
        const subQuestionField = questionFields[subQuestionIdx];
        if (!isFormFieldValid(formData, subQuestionField)) {
          return questionIdx;
        }
      }
    }
  }

  return -1;
}

function setQuoteFormValuesWithIndex(
  formData: FilledQuoteFormValuesSchema,
  questionIndex: number,
  petIndex: number,
  setFilledQuoteFormValues: (values: FilledQuoteFormValuesSchema) => void,
) {
  setFilledQuoteFormValues({
    ...formData,
    [CURRENT_QUESTION_STEP_IDX]: questionIndex,
    ...(petIndex > -1 && { [CURRENT_PET_IDX_FIELD]: petIndex }),
  });
}

export function calculatePolicyPriceForAllPets(petQuotes: PetQuote[]) {
  if (!areAllPetPoliciesValid(petQuotes)) {
    throw new InvalidPetQuoteProductsError('[calculatePolicyPriceForAllPets]: Invalid pet quote products');
  }

  const price: PolicyPriceForAllPets = {
    LT1: {
      monthlyPrice: 0,
      annualPrice: 0,
      totalDiscount: 0,
    },
    LT2: {
      monthlyPrice: 0,
      annualPrice: 0,
      totalDiscount: 0,
    },
    LT3: {
      monthlyPrice: 0,
      annualPrice: 0,
      totalDiscount: 0,
    },
  };

  for (const petQuote of petQuotes) {
    for (const productQuote of petQuote.productQuotes) {
      const { productCode, annualPrice, monthlyPrice, promotionDiscount, multipetDiscount } = productQuote;

      switch (productCode) {
        case 'LT1':
          price.LT1.annualPrice += annualPrice;
          price.LT1.monthlyPrice += monthlyPrice;
          price.LT1.totalDiscount += promotionDiscount + multipetDiscount;
          break;
        case 'LT2':
          price.LT2.annualPrice += annualPrice;
          price.LT2.monthlyPrice += monthlyPrice;
          price.LT2.totalDiscount += promotionDiscount + multipetDiscount;
          break;
        case 'LT3':
          price.LT3.annualPrice += annualPrice;
          price.LT3.monthlyPrice += monthlyPrice;
          price.LT3.totalDiscount += promotionDiscount + multipetDiscount;
          break;
      }
    }
  }

  roundPricesToDecimalFixed(price);

  return price;
}

function roundPricesToDecimalFixed(policyPriceForAllPets: PolicyPriceForAllPets) {
  for (const policyCode of Object.keys(PolicyCodes)) {
    policyPriceForAllPets[policyCode as PolicyCodes].annualPrice = parseFloat(
      policyPriceForAllPets[policyCode as PolicyCodes].annualPrice.toFixed(2),
    );
    policyPriceForAllPets[policyCode as PolicyCodes].monthlyPrice = parseFloat(
      policyPriceForAllPets[policyCode as PolicyCodes].monthlyPrice.toFixed(2),
    );
  }
}

export function getPoliciesToDisplay(petQuotes: PetQuote[]) {
  petQuotes.forEach((quote) => {
    quote.productQuotes = normaliseProductOrderByVetFeeAmount(quote.productQuotes);
  });

  // dog policy idx (if exists) to have full list of benefits
  const petPolicyWithFullBenefitListIdx = findPolicyPetIndex(petQuotes);

  return petQuotes[petPolicyWithFullBenefitListIdx].productQuotes;
}

export function areAllPetPoliciesValid(petQuotes: PetQuote[]) {
  return petQuotes.every((quote) => {
    const productCodesInQuote = quote.productQuotes.map((productQuote) => productQuote.productCode);

    return (
      productCodesInQuote.length === definedPolicyCodes.length &&
      productCodesInQuote.every((code) => definedPolicyCodes.includes(code as PolicyCodes))
    );
  });
}

function isFormFieldValid(
  formValues: QuoteFormCustomerValues | QuoteFormPetValues | QuoteFormValues,
  fieldName: QuoteFormField,
) {
  try {
    quoteFormValidationSchema.validateSyncAt(fieldName, formValues);

    return true;
  } catch (e) {
    return false;
  }
}

export function isFilledQuoteFormValuesType(
  filledQuoteFormValuesCandidate: unknown,
): filledQuoteFormValuesCandidate is FilledQuoteFormValuesSchema {
  return (filledQuoteFormValuesCandidate as FilledQuoteFormValuesSchema).pets !== undefined;
}
