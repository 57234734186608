import React from 'react';

import { useUiStateContextPatchUiState } from '~/hooks/useUiStateContextUpdateUi';

interface UseQuoteFormBackPressHandlerProps {
  shouldExecuteCustomHandler: boolean;
  customHandler: () => void;
}

export function useQuoteFormBackPressHandler({
  shouldExecuteCustomHandler,
  customHandler,
}: UseQuoteFormBackPressHandlerProps) {
  const patchUiState = useUiStateContextPatchUiState();

  const cleanUiBackButtonPressHandler = React.useCallback(() => {
    patchUiState({ backButtonPressHandler: undefined });
  }, [patchUiState]);

  React.useEffect(() => {
    if (shouldExecuteCustomHandler) {
      patchUiState({ backButtonPressHandler: customHandler });
    } else {
      cleanUiBackButtonPressHandler();
    }

    return () => {
      cleanUiBackButtonPressHandler();
    };

    // in deps we have only state, which is used to determine the flow
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldExecuteCustomHandler]);
}
