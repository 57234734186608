import React from 'react';

import { UISchema } from '~/models/ui';

interface UIStateContextSchema {
  uiSchema?: UISchema;
  patchUiState: (ui: Partial<UISchema>) => void;
}

export const UIStateContext = React.createContext<UIStateContextSchema | undefined>(undefined);

export const UIStateProvider: React.FC<React.PropsWithChildren> = function UIStateProvider({ children }) {
  const [ui, setUi] = React.useState<UISchema | undefined>(undefined);

  const patchUiState = React.useCallback((updatedUi: Partial<UISchema>) => {
    setUi((prevUiState) => {
      return { ...prevUiState, ...updatedUi };
    });
  }, []);

  return (
    <UIStateContext.Provider
      value={{
        uiSchema: ui,
        patchUiState,
      }}
    >
      {children}
    </UIStateContext.Provider>
  );
};
