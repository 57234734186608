import React from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';
import { useField } from 'formik';

import { INTERNAL_DATE_FORMAT } from '@joinfluffy/common';

import { PolicyViewStepProps } from '~/components/customer-form/customer-form-questionnaire/CustomerFormQuestionnaire';
import { ScreenWithTitleContainer } from '~/components/common/ScreenWithTitleContainer';
import { PolicyStartDateCalendarInput } from '~/components/quote-form/policy-start-date-question-view/policy-start-date-calendar-input/PolicyStartDateCalendarInput';
import { ChooseOptionItem } from '~/components/questions/ChooseOptionItem';
import { ChooseOptionFormikItem } from '~/components/questions/ChooseOptionFormikItem';
import { QuoteFormValues, POLICY_START_DATE_FIELD } from '~/components/quote-form/quote-form.schema';

import { useUiStateContextUiSchema } from '~/hooks/useUiStateContextUiSchema';
import { useUiStateContextPatchUiState } from '~/hooks/useUiStateContextUpdateUi';
import { usePolicyStartDateEnabledOptions } from '~/hooks/quote-form/usePolicyStartDateEnabledOptions';

import { getTodaysDate } from '~/helpers/date/date';

import { Colors } from '~/constants/colors';

const TODAY_DATE = getTodaysDate();

const TODAY_DATE_VALID_FORMAT = TODAY_DATE.format(INTERNAL_DATE_FORMAT);
const TOMORROW_DATE_VALID_FORMAT = TODAY_DATE.add(1, 'days').format(INTERNAL_DATE_FORMAT);

export const PolicyStartDateQuestionView: React.FC<PolicyViewStepProps> = function PolicyStartDateQuestionView({
  onValueSelect,
}) {
  const [, meta, formikHelper] = useField<QuoteFormValues[typeof POLICY_START_DATE_FIELD]>(POLICY_START_DATE_FIELD);

  const { maxValueToSelect, isCalendarOptionEnabled, isTomorrowOptionEnabled } = usePolicyStartDateEnabledOptions();

  const patchUiState = useUiStateContextPatchUiState();
  const isCalendarView = Boolean(useUiStateContextUiSchema()?.quoteForm?.isCalendarView);

  const showCalendar = React.useCallback(() => {
    formikHelper.setValue(null);

    patchUiState && patchUiState({ quoteForm: { isCalendarView: true } });
  }, [formikHelper, patchUiState]);

  const shouldShowCalendar = isCalendarView;
  const title = shouldShowCalendar
    ? 'Please pick a date when you want your policy to start'
    : 'When would you like your insurance to start?';

  return (
    <ScreenWithTitleContainer
      title={title}
      isActionButtonHidden={!shouldShowCalendar}
      isButtonDisabled={Boolean(meta.error)}
      buttonLabel="Next step"
      onActionButtonClick={onValueSelect}
    >
      {shouldShowCalendar ? (
        <PolicyStartDateCalendarInput maxValue={maxValueToSelect.toDate()} />
      ) : (
        <Stack spacing="12px">
          <ChooseOptionFormikItem
            name={POLICY_START_DATE_FIELD}
            value={TODAY_DATE_VALID_FORMAT}
            label="Today"
            onSelect={onValueSelect}
          />
          {isTomorrowOptionEnabled && (
            <ChooseOptionFormikItem
              name={POLICY_START_DATE_FIELD}
              value={TOMORROW_DATE_VALID_FORMAT}
              label="Tomorrow"
              onSelect={onValueSelect}
            />
          )}

          {isCalendarOptionEnabled && <ChooseOptionItem label="Future date" onSelect={showCalendar} />}
        </Stack>
      )}

      <Box height={{ base: '24px', lg: '36px' }} paddingTop={{ base: '4px' }}>
        <Text
          fontSize={{ base: '12px', lg: '16px' }}
          lineHeight={{ base: '16px', lg: '24px' }}
          color={Colors.Brown}
          fontWeight="400"
          textAlign="center"
        >
          Please note that the policy start date will be set in the UK timezone
        </Text>
      </Box>
    </ScreenWithTitleContainer>
  );
};
