import React from 'react';
import { Box, calc, Flex, HStack, Text } from '@chakra-ui/react';
import { useField, useFormikContext } from 'formik';

import { getProductBenefitData, PolicyCodes } from '@joinfluffy/common';

import { Currency } from '@shared/constants/currency';

import { ActionButton } from '~/components/common/ActionButton';
import { Footer } from '~/components/common/Footer';
import { PolicyItem } from '~/components/customer-form/policy-display-plans-view/policy-item/PolicyItem';
import { PlanBenefitsSection } from '~/components/customer-form/policy-display-plans-view/plan-benefits-section/PlanBenefitsSection';
import { PolicyPerksSection } from '~/components/customer-form/policy-display-plans-view/plan-perks-section/PolicyPerksSection';
import { PolicyViewStepProps } from '~/components/customer-form/customer-form-questionnaire/CustomerFormQuestionnaire';
import { CustomerFormValues, POLICY_CODE_FIELD } from '~/components/customer-form/customer-form.schema';
import { PET_NAME_FIELD } from '~/components/quote-form/quote-form.schema';

import { useHideFooter } from '~/hooks/common/useHideFooter';
import { useInsurancePolicyList } from '~/hooks/insurance-data-provider/policy/useInsurancePolicyList';
import { useSelectedPolicyPerks } from '~/hooks/insurance-data-provider/policy/useSelectedPolicyPerks';
import { useSelectedPolicyParagraphCoverLabels } from '~/hooks/insurance-data-provider/policy/useSelectedPolicyParagraphCoverLabels';
import { useSelectedPolicyData } from '~/hooks/insurance-data-provider/policy/useSelectedPolicyData';
import { usePolicyPricingData } from '~/hooks/insurance-data-provider/policy/usePolicyPricingData';
import { useFilledQuoteFormValues } from '~/hooks/insurance-data-provider/useFilledQuoteFormValues';

import { trackEvent, trackEventAndIdentify } from '~/helpers/analytics/trackEvent';
import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';
import { formatPetNameForDisplay } from '~/helpers/pet/petName';

import { AnalyticsEvent } from '~/constants/analyticsEvent';
import { Colors } from '~/constants/colors';
import { LocalStorageKey } from '~/constants/localStorageKey';

export const PolicyDisplayPlansView: React.FC<PolicyViewStepProps> = function PolicyDisplayPlansView({
  onValueSelect,
}) {
  useHideFooter();

  React.useEffect(() => {
    trackEvent({ eventName: AnalyticsEvent.QuoteViewPolicyPlansPage });
  }, []);

  const formik = useFormikContext<CustomerFormValues>();
  const [field] = useField<PolicyCodes>(POLICY_CODE_FIELD);

  const selectPlan = React.useCallback(
    (policyCode: string) => () => {
      formik.setFieldValue(POLICY_CODE_FIELD, policyCode);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.setFieldValue],
  );

  const policyList = useInsurancePolicyList();
  const quoteFormData = useFilledQuoteFormValues();
  const perksData = useSelectedPolicyPerks(field.value);
  const selectedPolicy = useSelectedPolicyData(field.value);
  const policyCoverageLabels = useSelectedPolicyParagraphCoverLabels(field.value);

  const { monthlyPrice, annualPrice, petsCountPriceLabel } = usePolicyPricingData(field.value);

  const petName = React.useMemo(() => {
    if (quoteFormData.pets.length > 1) {
      return 'your pets';
    }

    return formatPetNameForDisplay(quoteFormData.pets[0][PET_NAME_FIELD]);
  }, [quoteFormData.pets]);

  const actionButtonLabel = `Let's get ${petName} covered`;

  const confirmPolicySelection = React.useCallback(() => {
    const eventName = AnalyticsEvent.QuotePricingPlanConfirmed;
    const traits = {
      quoting_select_plan_code: selectedPolicy?.productCode,
      quoting_select_plan_annual_price: selectedPolicy?.annualPrice,
    };

    trackEventAndIdentify({ eventName: eventName, traits });

    // save annual price of the selected policy for Awin tracking on Thank You page
    localStorage.setItem(LocalStorageKey.SelectedProductAnnualPremium, String(selectedPolicy?.annualPrice));

    onValueSelect();
  }, [onValueSelect, selectedPolicy?.annualPrice, selectedPolicy?.productCode]);

  if (!policyList) {
    captureAndLogException('[PolicyDisplayPlansView]: policyList is not defined', 'Warn');

    return null;
  }

  return (
    <Flex
      minHeight={{ lg: calc('100vh').subtract('106px').toString() }}
      direction="column"
      justify="space-between"
      alignItems="center"
      marginTop={{ base: '66px', lg: '44px' }}
    >
      <Box>
        <Text
          fontSize={{ base: '24px', lg: '36px' }}
          lineHeight={{ base: '32px', lg: '36px' }}
          color={Colors.Brown}
          fontWeight="700"
        >
          Pick a plan for {formatPetNameForDisplay(petName)}
        </Text>
        <HStack spacing="8px" marginTop={{ base: '32px', lg: '28px' }} justifyContent="center">
          {policyList.map((policy) => (
            <PolicyItem
              key={policy.productCode}
              policyBenefitData={getProductBenefitData(policy.productBenefits, 'VET_FEES')}
              onPress={selectPlan(policy.productCode)}
              isActive={policy.productCode === field.value}
            />
          ))}
        </HStack>

        <Flex direction="column" align="center">
          <Flex direction={{ base: 'column', lg: 'row-reverse' }}>
            {policyCoverageLabels && (
              <Box marginTop="36px" marginLeft={{ lg: '40px' }}>
                <PlanBenefitsSection benefits={policyCoverageLabels} />
              </Box>
            )}
            {perksData && (
              <Box marginTop="36px">
                <PolicyPerksSection perks={perksData} />
              </Box>
            )}
          </Flex>
        </Flex>
      </Box>

      <Box marginY="32px">
        <Box paddingBottom="8px">
          <Text fontSize="18px" lineHeight="28px" fontWeight="700" color={Colors.Brown}>
            Monthly price{petsCountPriceLabel}: {Currency.GBP}
            {monthlyPrice}
          </Text>
          <Box marginTop={{ base: '2px', lg: '4px' }}>
            <Text fontSize="18px" lineHeight="28px" fontWeight="700" color={Colors.Brown}>
              or {Currency.GBP}
              {annualPrice} annually
            </Text>
          </Box>
        </Box>
        <ActionButton label={actionButtonLabel} onPress={confirmPolicySelection} disabled={!field.value} />
      </Box>

      <Footer>
        <Box>
          <Text fontSize={{ base: '10px', lg: '12px' }} lineHeight="16px" fontWeight="400" color={Colors.PaleBrow}>
            This pet insurance is underwritten by Covea Insurance plc. This quote is valid for 30 days provided all the
            information you have given to us is correct and does not change.
          </Text>
        </Box>
      </Footer>
    </Flex>
  );
};
