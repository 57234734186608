import React from 'react';

import { LocalStorageKey } from '~/constants/localStorageKey';

import { isDevEnv } from '~/helpers/common';
import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';
import { cleanLocallyStoredCustomerFormData } from '~/helpers/quote/locallyStoredCustomerFormValues';

import { useSearchParamsPolicyNo } from '~/hooks/useSearchParamsPolicyNo';

const AWIN_MERCHANT_ID = process.env.REACT_APP_AWIN_MERCHANT_ID;
const AWIN_TRANSACTION_TYPE = 'ns';
const AWIN_TRANSACTION_TYPE_VALUE = '2';
const AWIN_COMMISSION_GROUP = 'DEFAULT';
const AWIN_CURRENCY = 'GBP';
const AWIN_CHANNEL = 'aw';
const AWIN_CUSTOMER_ACQUISITION = 'NEW';

const AWIN_URL = new URL(`https://www.dwin1.com/${AWIN_MERCHANT_ID}.js`);
const AWIN_FALLBACK_URL = new URL('https://www.awin1.com/sread.img');

export function useSendPurchaseConfirmationToAwin({ onFinish }: { onFinish: () => void }) {
  const policyNo = useSearchParamsPolicyNo();
  const selectedProductAnnualPremium = localStorage.getItem(LocalStorageKey.SelectedProductAnnualPremium);

  const isAwingInfoTrackedRef = React.useRef<boolean>(false);

  if ((!policyNo || !selectedProductAnnualPremium) && !isAwingInfoTrackedRef.current) {
    captureAndLogException('Missing policyNo or selected product annual premium for Awin tracking', 'Error');
  }

  const addAwinScriptElement = React.useCallback(() => {
    const awinScriptElement: HTMLScriptElement = document.createElement('script');
    awinScriptElement.src = AWIN_URL.toString();
    awinScriptElement.type = 'text/javascript';
    awinScriptElement.defer = true;

    document.body.appendChild(awinScriptElement);
  }, []);

  const sendPurchaseConfirmationEventToAwin = React.useCallback(() => {
    // @ts-ignore
    window.AWIN = {
      Tracking: {
        Sale: {
          amount: selectedProductAnnualPremium,
          orderRef: policyNo,
          parts: `${AWIN_COMMISSION_GROUP}:${selectedProductAnnualPremium}`,
          voucher: '',
          currency: AWIN_CURRENCY,
          channel: AWIN_CHANNEL,
          customerAcquisition: AWIN_CUSTOMER_ACQUISITION,
        },
      },
    };
  }, [policyNo, selectedProductAnnualPremium]);

  const addAwinFallbackImageElement = React.useCallback(
    (merchantId: string, savedQuoteId: string, selectedProductAnnualPremium: string) => {
      // add necessary query params to the fallback url
      AWIN_FALLBACK_URL.search = new URLSearchParams({
        tt: AWIN_TRANSACTION_TYPE,
        tv: AWIN_TRANSACTION_TYPE_VALUE,
        merchant: merchantId,
        amount: selectedProductAnnualPremium,
        cr: AWIN_CURRENCY,
        ref: savedQuoteId,
        parts: `${AWIN_COMMISSION_GROUP}:${selectedProductAnnualPremium}`,
        ch: AWIN_CHANNEL,
        customeracquisition: AWIN_CUSTOMER_ACQUISITION,
      }).toString();

      const awinImgElement: HTMLImageElement = document.createElement('img');
      awinImgElement.src = AWIN_FALLBACK_URL.toString();
      awinImgElement.width = 0;
      awinImgElement.height = 0;

      document.body.appendChild(awinImgElement);
    },
    [],
  );

  React.useEffect(() => {
    if (isAwingInfoTrackedRef.current) {
      return;
    }

    addAwinScriptElement();

    if (policyNo && selectedProductAnnualPremium && AWIN_MERCHANT_ID && !isDevEnv()) {
      addAwinFallbackImageElement(AWIN_MERCHANT_ID, policyNo, selectedProductAnnualPremium);
      sendPurchaseConfirmationEventToAwin();
    }

    isAwingInfoTrackedRef.current = true;

    cleanLocallyStoredCustomerFormData();

    onFinish && onFinish();
  }, [
    addAwinFallbackImageElement,
    addAwinScriptElement,
    onFinish,
    policyNo,
    selectedProductAnnualPremium,
    sendPurchaseConfirmationEventToAwin,
  ]);
}
