import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';
import { addSentryBreadcrumb } from '~/helpers/monitoring/common';

export interface LookupSuggestion {
  address: string;
  url: string;
  id: string;
}

type LookupSuggestionResponse = { suggestions: LookupSuggestion[] };

export interface AddressLookupResponse {
  postcode: string;
  line_1: string;
  line_2: string;
  line_3: string;
  line_4: string;
  town_or_city: string;
  country: string;
}

export function fetchAddressByLookupId(lookupId: string): Promise<AddressLookupResponse> {
  const getAddressIoApiKey = process.env.REACT_APP_GET_ADDRESS_IO_API_KEY;
  if (!getAddressIoApiKey) {
    captureAndLogException('[fetchAddressByLookupId]: getaddress.io API key is not defined', 'Error');

    throw Error('[fetchAddressByLookupId]: getaddress.io API key is not defined');
  }

  return fetch(`https://api.getAddress.io/get/${lookupId}?api-key=${getAddressIoApiKey}`)
    .then(addResponseBreadcrumb('fetchAddressByLookupId'))
    .then((response) => {
      if (!response.ok) {
        return Promise.reject(response);
      }

      return response.json();
    })
    .catch((err) => {
      captureAndLogException(
        `[fetchAddressByLookupId]: issue with fetching the address by id: "${lookupId}". ${err.message}`,
        'Error',
      );

      throw err;
    });
}

export function fetchAddressAutocompleteSuggestions(inputTerm: string): Promise<LookupSuggestion[]> {
  const getAddressIoApiKey = process.env.REACT_APP_GET_ADDRESS_IO_API_KEY;
  if (!getAddressIoApiKey) {
    captureAndLogException('[fetchAddressAutocompleteSuggestions]: getaddress.io API key is not defined', 'Error');

    throw Error('[fetchAddressAutocompleteSuggestions]: getaddress.io API key is not defined');
  }

  return fetch(`https://api.getAddress.io/autocomplete/${inputTerm}?api-key=${getAddressIoApiKey}`)
    .then(addResponseBreadcrumb('fetchAddressAutocompleteSuggestions'))
    .then((response) => {
      if (!response.ok) {
        return Promise.reject(response);
      }

      return response.json().then((responseData: LookupSuggestionResponse) => responseData.suggestions);
    })
    .catch((err) => {
      // Here we will have an error in case:
      // (1) there was something wrong with sending the API request (CORS, for example)
      // (2) or when we throw an error from the section above
      // (3) or if JSON parsing ( `response.json()` ) went wrong
      // Few more info: https://github.com/JakeChampion/fetch/issues/201#issuecomment-308213104

      captureAndLogException(
        `[fetchAddressAutocompleteSuggestions]: issue with fetching the address autocomplete suggestions by term: "${inputTerm}". ${err.message}`,
        'Error',
      );

      throw err;
    });
}

function addResponseBreadcrumb(label: string) {
  return function (response: Response) {
    // If we can see this breadcrumb in the Sentry logs, that means the API call went fine,
    // but status code could be not "200 OK"
    addSentryBreadcrumb({
      category: 'event_breadcrumb',
      message: `[${label}] API call response`,
      data: {
        ok: response.ok,
        status: response.status,
        statusText: response.statusText,
        url: response.url,
      },
      level: 'info',
    });

    return response;
  };
}
