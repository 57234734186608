import React from 'react';

import { Box, Image, Stack } from '@chakra-ui/react';

import { Header } from '~/components/common/Header';
import { Footer } from '~/components/common/Footer';
import { ActionButton } from '~/components/common/ActionButton';
import { TitleText } from '~/components/common/TitleText';
import { ScreenContainer } from '~/components/common/ScreenContainer';

import { Colors } from '~/constants/colors';

const FOOTER_REMARK =
  '*The Promotion is for a £25 Amazon.co.uk Gift Card which will only be sent if a person you referred purchases a new pet insurance policy via your unique referral link and if their Policy remains active for 90 days after the policy start date.';

const HEART_DOG_IMG_SRC = '/images/dogImages/08-heart-dog/heart-dog-1080.png';

interface ReferralCodeViewProps {
  isRestartFormButtonDisabled?: boolean;
  restartQuote: () => void;
  openReferralLinkModal: () => void;
}

export const ReferralCodeView: React.FC<ReferralCodeViewProps> = function ReferralCodeView({
  isRestartFormButtonDisabled,
  restartQuote,
  openReferralLinkModal,
}) {
  return (
    <ScreenContainer>
      <Stack spacing={{ base: '58px', lg: '116px' }}>
        <Header />

        <Stack
          w="100%"
          direction={{ base: 'column', lg: 'row' }}
          spacing={{ lg: '32px' }}
          justify={{ lg: 'space-between' }}
          alignItems="center"
        >
          <Stack alignItems="flex-start" maxWidth={{ base: '320px', lg: '600px' }} spacing="20px">
            <TitleText
              style={{ align: { base: 'center', lg: 'left' } }}
              type="TITLE"
              text="Now you’re part of the family! Your policy documents will be with you shortly."
            />
            <Box width="320px" height="320px" display={{ base: 'block', lg: 'none' }}>
              <Image src={HEART_DOG_IMG_SRC} w="100%" h="100%" />
            </Box>
            <TitleText
              style={{ align: { base: 'center', lg: 'left' } }}
              type="SUBTITLE"
              text="Help other pet parents protect their pets. Introduce your friends to Fluffy and you'll both get £25 Amazon voucher when they purchase* their policy"
            />

            <Stack marginTop="20px" spacing="12px">
              <ActionButton label="Get my referral code" onPress={openReferralLinkModal} />
              <ActionButton
                style={{ bg: Colors.Brown }}
                label="Insure another pet"
                onPress={restartQuote}
                disabled={isRestartFormButtonDisabled}
              />
            </Stack>
          </Stack>

          <Box width="320px" height="320px" display={{ base: 'none', lg: 'block' }}>
            <Image src={HEART_DOG_IMG_SRC} w="100%" h="100%" />
          </Box>
        </Stack>
      </Stack>

      <Box padding="20px 0 20px 0">
        <Footer textContent={FOOTER_REMARK} />
      </Box>
    </ScreenContainer>
  );
};
