import React from 'react';
import { Stack } from '@chakra-ui/react';

import { Title } from '@shared/models/customer';

import { FormikInput } from '~/components/form/formik-input/FormikInput';
import { FormikSelect } from '~/components/form/FormikSelect';
import { ScreenWithTitleContainer } from '~/components/common/ScreenWithTitleContainer';
import {
  CUSTOMER_TITLE_SELECTION_OPTION_LIST,
  FIRST_NAME_FIELD,
  LAST_NAME_FIELD,
  PHONE_NUMBER_FIELD,
  TITLE_FIELD,
} from '~/components/customer-form/customer-form.schema';

import { useIsPolicyOwnerInfoFormActionButtonEnabled } from '~/hooks/useIsPolicyOwnerInfoFormActionButtonEnabled';
import { useKeyEnterScreenHandler } from '~/hooks/common/useKeyEnterScreenHandler';
import { useInsuringPetsCount } from '~/hooks/insurance-data-provider/useInsuringPetsCount';

export interface PolicySummaryViewProps {
  openNextStep: () => void;
}

export const InsuranceOwnerForm: React.FC<PolicySummaryViewProps> = function InsuranceOwnerForm({ openNextStep }) {
  const isActionButtonEnabled = useIsPolicyOwnerInfoFormActionButtonEnabled();
  useKeyEnterScreenHandler({ actionToCall: openNextStep, isActionDisabled: !isActionButtonEnabled });

  const insuringPetsCount = useInsuringPetsCount();

  const title = React.useMemo(
    () => `Let us know who is the owner and keeper of the ${insuringPetsCount > 1 ? 'pets' : 'pet'}?`,
    [insuringPetsCount],
  );

  return (
    <ScreenWithTitleContainer
      title={title}
      buttonLabel="Next step"
      isButtonDisabled={!isActionButtonEnabled}
      onActionButtonClick={openNextStep}
    >
      <Stack spacing="12px">
        <FormikSelect<Title>
          name={TITLE_FIELD}
          options={CUSTOMER_TITLE_SELECTION_OPTION_LIST}
          placeholder="Title"
          variant="outline"
        />
        <FormikInput name={FIRST_NAME_FIELD} placeholder="First Name" type="text" variant="outline" />
        <FormikInput name={LAST_NAME_FIELD} placeholder="Last Name" type="text" variant="outline" />
        <FormikInput name={PHONE_NUMBER_FIELD} placeholder="Phone number" type="tel" variant="outline" />
      </Stack>
    </ScreenWithTitleContainer>
  );
};
