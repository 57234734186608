import React from 'react';

import { ModalTypes } from '~/constants/modalTypes';

import { useInvokeModal } from '~/hooks/useInvokeModal';

export function useInvokeConfirmationModal() {
  const { invokeModal } = useInvokeModal();

  const invokeConfirmationModal = React.useCallback(
    (modalParams: {
      actionButtonLabel?: string;
      title: string;
      description: string;
      confirmationHandler?: () => void;
      hideSecondaryOption?: boolean;
    }) => {
      invokeModal(ModalTypes.GeneralConfirmation, modalParams);
    },
    [invokeModal],
  );

  return { invokeConfirmationModal };
}
