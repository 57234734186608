import React from 'react';
import { Box, Flex, Text, Wrap, WrapItem } from '@chakra-ui/react';

import {
  FluffyAppBenefits,
  ProductBenefitCode,
  ProductBenefitSchema,
  formatProductBenefitAmountLabel,
  formatProductBenefitNameLabel,
} from '@joinfluffy/common';

import { PerkItem } from '~/components/common/PerkItem';
import { PartnersBenefitsSection } from '~/components/customer-form/policy-display-plans-view/partners-benefits-section/PartnersBenefitsSection';

import { useInvokeModal } from '~/hooks/useInvokeModal';

import { Colors } from '~/constants/colors';
import { ModalTypes } from '~/constants/modalTypes';

import { ProductPerkInfoConfig } from '~/configs/perkInfoConfig';

interface PolicyPerksSectionProps {
  perks: ProductBenefitSchema[];
}

export const PolicyPerksSection: React.FC<PolicyPerksSectionProps> = function PolicyPerksSection({ perks }) {
  const { invokeModal } = useInvokeModal();

  const openPerkInfoModal = React.useCallback(
    (code: ProductBenefitCode | FluffyAppBenefits) => () => {
      invokeModal(ModalTypes.PerkInfo, { code });
    },
    [invokeModal],
  );

  return (
    <Flex direction="column" alignItems="center">
      <Box>
        <Text fontSize="18px" lineHeight="32px" color={Colors.Brown} fontWeight="900">
          Every policy has these benefits
        </Text>
      </Box>
      <Wrap marginTop="18px" spacing="16px" maxW={{ lg: '471px' }} justify="center">
        {perks.map(({ code, amount }) => (
          <WrapItem key={code}>
            <Box as="button" onClick={openPerkInfoModal(code)}>
              <PerkItem
                imgSrc={ProductPerkInfoConfig[code].smallImgSrc}
                title={formatProductBenefitNameLabel(ProductPerkInfoConfig[code].title)}
                subTitle={formatProductBenefitAmountLabel(amount)}
              />
            </Box>
          </WrapItem>
        ))}
      </Wrap>
      <Box marginTop="20px">
        <Text fontSize="18px" lineHeight="32px" color={Colors.Brown} fontWeight="900">
          When purchasing insurance you get extra perks with the Fluffy app
        </Text>
      </Box>
      <Wrap marginTop="18px" spacing="16px" maxW={{ lg: '471px' }} justify="center">
        {Object.values(FluffyAppBenefits).map((perkName, idx) => (
          <WrapItem key={idx}>
            <Box as="button" onClick={openPerkInfoModal(perkName)}>
              <PerkItem
                imgSrc={ProductPerkInfoConfig[perkName].smallImgSrc}
                title={formatProductBenefitNameLabel(perkName)}
              />
            </Box>
          </WrapItem>
        ))}
      </Wrap>

      <PartnersBenefitsSection style={{ display: { base: 'none', lg: 'none' }, marginTop: '20px' }} />
    </Flex>
  );
};
