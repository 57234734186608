import { IsValidDiscountCodeResponse } from '@shared/models/discountCode';

import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';

import { getHttpsCallableFunction } from '~/hooks/useInitFirebase';

import { CloudFunction } from '~/constants/functionName';

export async function isDiscountCodeValid(discountCode: string): Promise<boolean> {
  try {
    const isValidDiscountCode = getHttpsCallableFunction(CloudFunction.isValidDiscountCode);

    const response = await isValidDiscountCode({ discountCode });

    const isValidDiscountCodeResponse = response.data as IsValidDiscountCodeResponse;

    return isValidDiscountCodeResponse.isValidDiscountCode;
  } catch (e) {
    captureAndLogException(e, 'Error');

    return false;
  }
}
