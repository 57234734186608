import { BreedSchema, PetPedigree } from '@joinfluffy/common';

export function getPetPedigreeFromPetBreed(petBreed: BreedSchema | undefined): PetPedigree | undefined {
  if (!petBreed) {
    return;
  }

  let petPedigree: PetPedigree = 'pedigree';

  if (petBreed?.isCrossBreed) {
    petPedigree = 'crossed';
  }
  if (petBreed?.isMixed) {
    petPedigree = 'mixed';
  }

  return petPedigree;
}
