import React from 'react';

import { useSearchParamsExternalSource } from '~/hooks/quote/useSearchParamsExternalSource';
import { useInsuranceDataContextSetOriginalExternalSource } from '~/hooks/insurance-data-provider/useInsuranceDataContextSetOriginalExternalSource';

export function useSetOriginalExternalSourceToQuote() {
  const originalExternalSource = useSearchParamsExternalSource();

  const setOriginalExternalSource = useInsuranceDataContextSetOriginalExternalSource();

  React.useEffect(() => {
    if (originalExternalSource && setOriginalExternalSource) {
      setOriginalExternalSource(originalExternalSource);
    }
  }, [originalExternalSource, setOriginalExternalSource]);
}
