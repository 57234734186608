import type { ToastConfig, ToastSchema } from '~/providers/toaster-provider/toasterProvider.schema';

const RADIX_16_PREFIX_LENGTH = 2; // for radix 16 we need to skip first 2 symbols: "0."
const RADIX_16_LAST_SYMBOL_INDEX = 15;

function getRandomId() {
  return Math.random().toString(16).substring(RADIX_16_PREFIX_LENGTH, RADIX_16_LAST_SYMBOL_INDEX);
}

export function prepareToast(message: string, toastConfig?: ToastConfig): ToastSchema {
  return {
    id: getRandomId(),
    title: toastConfig?.title,
    message,
  };
}
