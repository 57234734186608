import React from 'react';

import * as Sentry from '@sentry/react';

import { Box } from '@chakra-ui/react';

import { QuoteForm } from '~/components/quote-form/QuoteForm';
import { CustomerForm } from '~/components/customer-form/CustomerForm';
import { IntercomLauncher } from '~/components/intercom/intercom-launcher/IntercomLauncher';
import { Footer } from '~/components/common/Footer';
import { ScreenContainer } from '~/components/common/ScreenContainer';

import { useCheckForExistingQuote } from '~/hooks/quote/useCheckForExistingQuote';
import { useInsurancePolicyList } from '~/hooks/insurance-data-provider/policy/useInsurancePolicyList';
import { useExternalQuoteRedirect } from '~/hooks/quote/useExternalQuoteRedirect';
import { useInsuranceDataContext } from '~/hooks/insurance-data-provider/useInsuranceDataContext';
import { useUiStateContextUiSchema } from '~/hooks/useUiStateContextUiSchema';
import { useInvokeErrorModal } from '~/hooks/useInvokeErrorModal';
import { useSetOriginalExternalSourceToQuote } from '~/hooks/insurance-data-provider/useSetOriginalExternalSourceToQuote';

export const AppWrapper: React.FC = function AppWrapper() {
  useCheckForExistingQuote();
  useSetOriginalExternalSourceToQuote();

  const { setExternalQuoteResponseData } = useInsuranceDataContext();
  useExternalQuoteRedirect({
    setQuoteResponseData: setExternalQuoteResponseData,
  });

  const isCustomerFormVisible = Boolean(useInsurancePolicyList());
  const uiSchema = useUiStateContextUiSchema();

  const { invokeErrorModal } = useInvokeErrorModal();

  const onSentryError = React.useCallback(
    (error: Error) => {
      invokeErrorModal({ error });
    },
    [invokeErrorModal],
  );

  if (uiSchema?.isQuoteDataForExternalRouteLoading) {
    return null;
  }

  return (
    <>
      <IntercomLauncher />

      <Sentry.ErrorBoundary onError={onSentryError}>
        <ScreenContainer>
          <Box>{isCustomerFormVisible ? <CustomerForm /> : <QuoteForm />}</Box>

          {!uiSchema?.isFooterHidden && (
            <Box marginTop="16px">
              <Footer />
            </Box>
          )}
        </ScreenContainer>
      </Sentry.ErrorBoundary>
    </>
  );
};
