import React from 'react';
import moment from 'moment-timezone';

import * as Sentry from '@sentry/react';

import { IntercomProvider } from 'react-use-intercom';

import { GlobalLoaderContentWrapper } from '~/components/global-loader-content-wrapper/GlobalLoaderContentWrapper';

import { AnalyticsProviders } from '~/providers/AnalyticsProviders';
import { VwoTrackingProvider } from '~/providers/VwoTrackingProvider';
import { UIStateProvider } from '~/providers/UIStateProvider';
import { InsuranceDataProvider } from '~/providers/insurance-data-provider/InsuranceDataProvider';
import { ToasterProvider } from '~/providers/toaster-provider/ToasterProvider';

import { trackEvent } from '~/helpers/analytics/trackEvent';

import { AnalyticsEvent } from '~/constants/analyticsEvent';

import './App.scss';
import './styles/global-variables.scss';

const INTERCOM_APP_ID = 'm07vkb2u';

moment.tz.setDefault('Europe/London');

function App() {
  return (
    <AnalyticsProviders>
      <IntercomProvider
        appId={INTERCOM_APP_ID}
        autoBoot
        onShow={() => trackEvent({ eventName: AnalyticsEvent.QuoteOpenChat })}
        onHide={() => trackEvent({ eventName: AnalyticsEvent.QuoteCloseChat })}
      >
        <div className="App">
          <main>
            <VwoTrackingProvider>
              <ToasterProvider>
                <UIStateProvider>
                  <InsuranceDataProvider>
                    <GlobalLoaderContentWrapper />
                  </InsuranceDataProvider>
                </UIStateProvider>
              </ToasterProvider>
            </VwoTrackingProvider>
          </main>
        </div>
      </IntercomProvider>
    </AnalyticsProviders>
  );
}

export default Sentry.withProfiler(App);
