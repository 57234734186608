import React from 'react';

import { Colors } from '~/constants/colors';
import { Box, HStack, StackProps, Text } from '@chakra-ui/react';

import { ReactComponent as PawIcon } from '~/assets/icons/paw-curve-circle.svg';

export interface PolicyDeclarationItemProps {
  category: string;
  description: string;
  style?: StackProps;
}

export const PolicyDeclarationItem: React.FC<PolicyDeclarationItemProps> = function PolicyDeclarationItem({
  category,
  description,
  style,
}) {
  return (
    <HStack
      justify="flex-start"
      align="center"
      padding="8px 8px"
      spacing="12px"
      backgroundColor={Colors.Beige}
      borderRadius="20px"
      {...style}
    >
      <Box minW="25px" w="25px" minHeight="25px">
        <PawIcon width="100%" height="100%" />
      </Box>

      <Text textAlign="left" fontSize="12px" lineHeight="14px" fontWeight="400" color={Colors.Black}>
        <Text as="span" style={{ fontWeight: 'bold' }}>
          {category}
        </Text>{' '}
        {description}
      </Text>
    </HStack>
  );
};
