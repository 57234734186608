import type { AnalyticsBrowser, ID } from '@segment/analytics-next';

import { addSentryBreadcrumb } from '~/helpers/monitoring/common';

type ExperimentDetails = {
  id: string;
  name: string;
  variationId: string;
  variationName: string;
};

const EXPERIMENT_IDENTITY_PREFIX = 'VWO Experiment:';

export async function trackVwoExperimentVariation(
  analyticsBrowser: AnalyticsBrowser,
  userId: ID,
  expDetails: ExperimentDetails,
) {
  addSentryBreadcrumb({
    category: 'event_breadcrumb',
    message: '"trackVwoExperimentVariation" called with params',
    data: {
      userId,
      expDetails,
    },
    level: 'info',
  });

  try {
    await analyticsBrowser.identify(userId, {
      [`${EXPERIMENT_IDENTITY_PREFIX}: "${expDetails.name}"`]: expDetails.variationName,
    });
  } catch (error) {
    addSentryBreadcrumb({
      category: 'event_breadcrumb',
      message: '"trackVwoExperimentVariation" method error caught',
      data: {
        userId,
        expDetails,
        caughtErrorFromTrySection: error,
      },
      level: 'debug',
    });
  }
}
