import React from 'react';
import { useField } from 'formik';

import { getBreedList } from '@joinfluffy/common';

import type { QuoteFormValues } from '~/components/quote-form/quote-form.schema';
import type { AutoCompleteOption } from '~/components/form/formik.schema';
import { CAT_OR_DOG_FIELD } from '~/components/quote-form/quote-form.schema';

type BreedClassToPick = Parameters<typeof getBreedList>[0]['breedClassToPick'];

// be sure you call this hook inside the Insurance Formik provider
// otherwise you will have an error
export function useFormikInsuranceAvailableBreedOptions(options?: {
  isPickingMixedBreed?: boolean;
  isPickingBaseCrossBreed?: boolean;
}): AutoCompleteOption[] {
  let breedClassToPick: BreedClassToPick = 'main';
  if (options?.isPickingMixedBreed) {
    breedClassToPick = 'mixed';
  } else if (options?.isPickingBaseCrossBreed) {
    breedClassToPick = 'base-cross';
  }

  const [formikField] = useField<QuoteFormValues[typeof CAT_OR_DOG_FIELD]>(CAT_OR_DOG_FIELD);

  return React.useMemo(
    () =>
      getBreedList({
        catOrDog: formikField.value,
        breedClassToPick,
      }),
    [formikField.value, breedClassToPick],
  );
}
