import { useSearchParams } from 'react-router-dom';

import { ExternalSource, isExternalSource } from '@shared/constants/externalSource';
import { ExternalPurchaseUrlSearchParams } from '@shared/constants/urlSearchParams';

export function useSearchParamsExternalSource(): Optional<ExternalSource> {
  const [searchParams] = useSearchParams();
  const sourceSearchParam = searchParams.get(ExternalPurchaseUrlSearchParams.Source);

  if (!isExternalSource(sourceSearchParam)) {
    return;
  }

  return sourceSearchParam;
}
