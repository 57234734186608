import React from 'react';

import { useIntercom } from 'react-use-intercom';

import { ReactComponent as IntercomLauncherIcon } from '~/assets/icons/intercom-launcher-icon.svg';

import '~/components/intercom/intercom-launcher/IntercomLauncher.scss';

export const IntercomLauncher: React.FC<{
  withFloatingContainer?: boolean;
}> = function IntercomLauncher({ withFloatingContainer = false }) {
  const Intercom = useIntercom();

  React.useEffect(() => {
    Intercom.update({
      hideDefaultLauncher: true,
    });
  });

  React.useEffect(() => {
    Intercom.update({
      hideDefaultLauncher: true,
    });
  });

  const className = React.useMemo(
    () =>
      ['intercom-launcher-container', withFloatingContainer ? 'intercom-launcher-container__floating' : undefined]
        .filter(Boolean)
        .join(' '),
    [withFloatingContainer],
  );

  const openIntercom = () => {
    Intercom.show();
  };

  return (
    <div id="intercom-launcher-custom" className={className} onClick={openIntercom}>
      <div className="intercom-launcher-label">Help</div>
      <IntercomLauncherIcon className="intercom-launcher-icon" />
    </div>
  );
};
