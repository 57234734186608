import React from 'react';
import { useFormikContext } from 'formik';
import { Box } from '@chakra-ui/react';

import { Header } from '~/components/common/Header';
import { Stepper } from '~/components/stepper/Stepper';
import { Step } from '~/components/stepper/Step';
import { PolicyDisplayPlansView } from '~/components/customer-form/policy-display-plans-view/PolicyDisplayPlansView';
import { PaymentPageLoaderView } from '~/components/customer-form/payment-page-loader-view/PaymentPageLoaderView';
import { InsuranceOwnerForm } from '~/components/customer-form/insurance-owner-form/InsuranceOwnerForm';
import { DateInputQuestion } from '~/components/questions/DateInputQuestion';
import { PaymentTypeSeletion } from '~/components/customer-form/payment-type-selection/PaymentTypeSeletion';
import { PolicyDeclarationView } from '~/components/customer-form/policy-declration-view/PolicyDeclarationView';

import {
  DATE_OF_OWNER_BIRTH_FIELD,
  CustomerFormValues,
  CustomerFormQuestionType,
} from '~/components/customer-form/customer-form.schema';

import { useInsuranceDataContext } from '~/hooks/insurance-data-provider/useInsuranceDataContext';
import { useFilledQuoteFormValuesField } from '~/hooks/insurance-data-provider/useFilledQuoteFormValuesField';
import { useFormikInstrumentation } from '~/hooks/formik-common/useFormikInstrumentation';
import { useCurrentQuestionInstrumentation } from '~/hooks/formik-common/useCurrentQuestionInstrumentation';
import { useFormikOnSubmitValidationErrorHandling } from '~/hooks/formik-common/useFormikOnSubmitValidationErrorHandling';

import { getCurrentCustomerFormQuestionTraits } from '~/helpers/analytics/userAnalyticsIdentity';
import { trackEventAndIdentify } from '~/helpers/analytics/trackEvent';
import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';

import { EMAIL_FIELD } from '~/components/quote-form/quote-form.schema';

import { FetchPurchaseUrlError } from '~/error/FetchPurchaseUrlError';

import { CUSTOMER_FORM_IDENTITY_FIELDS_CONFIG, CUSTOMER_FORM_TRACKING_EVENTS_CONFIG } from '~/configs/tracking';
import { CUSTOMER_FORM_QUESTION_ORDER_CONFIG } from '~/configs/questionsOrder/questionsOrder';
import { CUSTOMER_FORM_QUESTION_POPOVER_CONTENT_CONFIG } from '~/configs/questionsPopoverContent';
export interface PolicyViewStepProps {
  onValueSelect: () => void;
}

const FIRST_STEP_IDX = 0;

// Question before payment page loader step idx
const LAST_STEP_IDX_BEFORE_LOADING = CUSTOMER_FORM_QUESTION_ORDER_CONFIG.length - 2;

const FORM_LABEL = 'insurance_policy_questionnaire';

export const CustomerFormQuestionnaire: React.FC = function CustomerFormQuestionnaire() {
  useFormikInstrumentation(FORM_LABEL);
  useFormikOnSubmitValidationErrorHandling(FORM_LABEL);

  const formik = useFormikContext<CustomerFormValues>();

  const { isCustomerFormFulfilled, cleanPolicyList } = useInsuranceDataContext();
  const { isSubmitting } = formik;

  const [currentStep, setCurrentStep] = React.useState<number>(
    isCustomerFormFulfilled ? LAST_STEP_IDX_BEFORE_LOADING : FIRST_STEP_IDX,
  );

  useCurrentQuestionInstrumentation(CUSTOMER_FORM_QUESTION_ORDER_CONFIG[currentStep]);

  const [isBackButtonVisible, setIsBackButtonVisible] = React.useState<boolean>(true);

  const email = String(useFilledQuoteFormValuesField(EMAIL_FIELD));

  React.useEffect(() => {
    // start the next question from the top of the page
    window.scrollTo(0, 0);
  }, [currentStep]);

  const trackAnalytics = (currentQuestionType: CustomerFormQuestionType, currentQuestionTraits?: object) => {
    const eventName = CUSTOMER_FORM_TRACKING_EVENTS_CONFIG[currentQuestionType];
    trackEventAndIdentify({ eventName, traits: currentQuestionTraits, email });
  };

  const openNextStep = React.useCallback(() => {
    setCurrentStep((prevStep) => prevStep + 1);
  }, []);

  const openNextQuestion =
    ({ currentQuestionType }: { currentQuestionType: CustomerFormQuestionType }) =>
    () => {
      const fields = CUSTOMER_FORM_IDENTITY_FIELDS_CONFIG[currentQuestionType];

      let traits;

      if (fields) {
        traits = getCurrentCustomerFormQuestionTraits(fields, formik);
      }

      trackAnalytics(currentQuestionType, traits);

      openNextStep();
    };

  const handleFormSubmitHandlerError = React.useCallback((e: FetchPurchaseUrlError) => {
    captureAndLogException(e.message, 'Error');

    setCurrentStep(LAST_STEP_IDX_BEFORE_LOADING);

    setIsBackButtonVisible(true);
  }, []);

  const openPaymentPage = React.useCallback(() => {
    if (!isSubmitting) {
      formik.submitForm().catch(handleFormSubmitHandlerError);
      setIsBackButtonVisible(false);
      // next step is the Payment page loader
      openNextStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openNextStep, formik.submitForm, isSubmitting]);

  const openPreviousStep = React.useCallback(() => setCurrentStep((prevStep) => prevStep - 1), []);

  const onBackClick = React.useCallback(() => {
    if (currentStep === FIRST_STEP_IDX) {
      // clean policy list to show the Insurance Form again
      cleanPolicyList();
    } else {
      openPreviousStep();
    }
  }, [currentStep, cleanPolicyList, openPreviousStep]);

  return (
    <Box>
      <Header
        popoverTextContent={
          CUSTOMER_FORM_QUESTION_POPOVER_CONTENT_CONFIG[CUSTOMER_FORM_QUESTION_ORDER_CONFIG[currentStep]]
        }
        onBackButtonPress={onBackClick}
        isBackButtonVisible={isBackButtonVisible}
      />
      <Box marginX="auto" alignSelf="center" marginTop={{ base: '40px', lg: '65px' }}>
        <Stepper step={currentStep} questionOrderConfig={CUSTOMER_FORM_QUESTION_ORDER_CONFIG}>
          <Step step="POLICY_DISPLAY_VIEW">
            <PolicyDisplayPlansView onValueSelect={openNextStep} />
          </Step>

          <Step step="OWNER_INFO">
            <InsuranceOwnerForm openNextStep={openNextQuestion({ currentQuestionType: 'OWNER_INFO' })} />
          </Step>

          <Step step="DATE_OF_OWNER_BIRTH">
            <DateInputQuestion
              className="default-date-input"
              name={DATE_OF_OWNER_BIRTH_FIELD}
              questionText="Please confirm your date of birth"
              defaultView="decade"
              actionButtonLabel="Next step"
              onActionButtonClick={openNextQuestion({ currentQuestionType: 'DATE_OF_OWNER_BIRTH' })}
            />
          </Step>

          <Step step="POLICY_DECLARATION">
            <PolicyDeclarationView onSubmit={openNextQuestion({ currentQuestionType: 'POLICY_DECLARATION' })} />
          </Step>

          <Step step="PAYMENT_TYPE_SELECTION">
            <PaymentTypeSeletion onValueSelect={openPaymentPage} />
          </Step>
          <Step step="PAYMENT_PAGE_LOADER">
            <PaymentPageLoaderView />
          </Step>
        </Stepper>
      </Box>
    </Box>
  );
};
