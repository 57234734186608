import React from 'react';
import { useField } from 'formik';
import { Box, HStack, Text } from '@chakra-ui/react';

import { PolicyCodes } from '@joinfluffy/common';

import { PaymentType } from '@shared/constants/paymentType';
import { Currency } from '@shared/constants/currency';

import { PolicyViewStepProps } from '~/components/customer-form/customer-form-questionnaire/CustomerFormQuestionnaire';
import { ScreenWithTitleContainer } from '~/components/common/ScreenWithTitleContainer';
import { PaymentTypeSelectionItem } from '~/components/customer-form/payment-type-selection/payment-type-selection-item/PaymentTypeSelectionItem';
import {
  CustomerFormValues,
  PAYMENT_TYPE_FIELD,
  POLICY_CODE_FIELD,
} from '~/components/customer-form/customer-form.schema';
import { ActionButton } from '~/components/common/ActionButton';

import { usePolicyPricingData } from '~/hooks/insurance-data-provider/policy/usePolicyPricingData';

import { trackEvent, trackEventAndIdentify } from '~/helpers/analytics/trackEvent';

import { Colors } from '~/constants/colors';
import { AnalyticsEvent } from '~/constants/analyticsEvent';

export const PaymentTypeSeletion: React.FC<PolicyViewStepProps> = function PaymentTypeSeletion({ onValueSelect }) {
  const [paymentTypeField, , paymentTypeFieldHelpers] =
    useField<CustomerFormValues[typeof PAYMENT_TYPE_FIELD]>(PAYMENT_TYPE_FIELD);
  const [selectedPolicyCodeField] = useField<PolicyCodes>(POLICY_CODE_FIELD);

  const { monthlyPrice, annualPrice, discount, originalAnnualPrice, originalMontlyPrice } = usePolicyPricingData(
    selectedPolicyCodeField.value,
  );

  const getPaymentTypeSelectionHandler = (paymentType: PaymentType) => () => {
    paymentTypeFieldHelpers.setValue(paymentType);
  };

  const openPaymentPage = () => {
    const eventName = AnalyticsEvent.QuotePaymentTypeConfirmed;

    const traits = {
      quoting_payment_type_selected: paymentTypeField.value,
    };

    trackEventAndIdentify({ eventName: eventName, traits });
    trackEvent({ eventName: AnalyticsEvent.QuotePurchaseClick });

    onValueSelect();
  };

  return (
    <ScreenWithTitleContainer title="Select how you would like to pay">
      <HStack spacing="32px" marginBottom="32px">
        <PaymentTypeSelectionItem
          oldPrice={`${Currency.GBP}${originalMontlyPrice}`}
          price={`${Currency.GBP}${monthlyPrice}`}
          label="Monthly"
          isSelected={paymentTypeField.value === PaymentType.Monthly}
          onSelect={getPaymentTypeSelectionHandler(PaymentType.Monthly)}
        />

        <PaymentTypeSelectionItem
          oldPrice={`${Currency.GBP}${originalAnnualPrice}`}
          price={`${Currency.GBP}${annualPrice}`}
          label="Annually"
          isSelected={paymentTypeField.value === PaymentType.Annually}
          onSelect={getPaymentTypeSelectionHandler(PaymentType.Annually)}
        />
      </HStack>

      <Box>
        <Box w="300px" marginBottom="16px">
          <Text fontSize="16px" lineHeight="20px" color={Colors.Brown} fontWeight="400">
            Includes 12% IPT and £8 non-refundable admin fee
          </Text>
        </Box>

        <Box marginBottom="32px">
          {discount > 0 && (
            <Text fontSize="16px" lineHeight="20px" color={Colors.Brown} fontWeight="400">
              Discount: {Currency.GBP}
              {discount}
            </Text>
          )}
        </Box>

        <Box>
          <ActionButton label="Continue" onPress={openPaymentPage} disabled={!paymentTypeField.value} />
        </Box>
      </Box>
    </ScreenWithTitleContainer>
  );
};
