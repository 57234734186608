import React from 'react';

const notDefinedMethod = () => {
  throw new Error('The method is not defined yet. Please use a Provider for the PetAgeQuestionContext');
};

interface PetAgeQuestionContextSchema {
  isDdMissed: boolean;
  setIsDdMissed: (nextValue: boolean) => void;
  isDdMmMissed: boolean;
  setIsDdMmMissed: (nextValue: boolean) => void;
}

export const PetAgeQuestionContext = React.createContext<PetAgeQuestionContextSchema>({
  isDdMissed: false,
  setIsDdMissed: notDefinedMethod,
  isDdMmMissed: false,
  setIsDdMmMissed: notDefinedMethod,
});

export const PetAgeQuestionProvider: React.FC<React.PropsWithChildren> = function PetAgeQuestionProvider({ children }) {
  const [isDdMissed, setIsDdMissed] = React.useState<boolean>(false);
  const [isDdMmMissed, setIsDdMmMissed] = React.useState<boolean>(false);

  return (
    <PetAgeQuestionContext.Provider
      value={{
        isDdMissed,
        setIsDdMissed,
        isDdMmMissed,
        setIsDdMmMissed,
      }}
    >
      {children}
    </PetAgeQuestionContext.Provider>
  );
};
