import React from 'react';
import { createBrowserRouter, Navigate, Outlet, RouterProvider, useLocation, useNavigate } from 'react-router-dom';

import { AppWrapper } from '~/components/app-wrapper/AppWrapper';
import { ErrorPage } from '~/components/pages/ErrorPage';
import { ThankYouPage } from '~/components/pages/thank-you-page/ThankYouPage';
import { AuthForm } from '~/components/auth-form/AuthForm';

import { useVisitAttribution } from '~/hooks/analytics/useVisitAttribution';
import { useInvokeErrorModal } from '~/hooks/useInvokeErrorModal';

import { isUserAuthorized } from '~/helpers/auth';

const ProtectedRoute: React.FC<React.PropsWithChildren> = function ProtectedRoute({ children }) {
  if (!isUserAuthorized()) {
    return <Navigate to="/auth" replace />;
  }

  return <>{children}</>;
};

const QuotingForm = (
  <ProtectedRoute>
    <AppWrapper />
  </ProtectedRoute>
);

const ThankYou = <ThankYouPage />;

function Root() {
  useVisitAttribution();

  const location = useLocation();
  const navigate = useNavigate();
  const { invokeErrorModal } = useInvokeErrorModal();

  React.useEffect(() => {
    if (location.pathname === '/') {
      navigate('/quote', { replace: true });
    }

    console.log('Root component mounted');

    invokeErrorModal({
      error: new Error('TOO_MANY_REQUESTS_ERROR_MESSAGE'),
    });
  }, [invokeErrorModal, location.pathname, navigate]);

  return <Outlet />;
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      { path: '/quote', element: QuotingForm },
      { path: '/quote/:quoteId', element: QuotingForm },
    ],
  },
  { path: '/thank-you', element: ThankYou },
  { path: '/auth', element: <AuthForm /> },
]);

export function AppRouter() {
  return <RouterProvider router={router} />;
}
