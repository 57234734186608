import React from 'react';
import { useFormikContext } from 'formik';

import { Box, Flex, Image } from '@chakra-ui/react';

import { ModalWrapper } from '~/components/common/ModalWrapper';
import { FormikInput } from '~/components/form/formik-input/FormikInput';
import { DISCOUNT_CODE_FIELD } from '~/components/modal/discount-code-modal/discount-code-modal.schema';
import { TitleText } from '~/components/common/TitleText';

import { ModalTypes } from '~/constants/modalTypes';

interface DiscountCodeModalProps {
  type: ModalTypes;
  isOpen: boolean;
  onClose: () => void;
}

const HEART_DOG_IMG_SRC = '/images/dogImages/08-heart-dog/heart-dog-1080.png';

export const DiscountCodeModalContent: React.FC<DiscountCodeModalProps> = function DiscountCodeModal({
  type,
  isOpen,
  onClose,
}) {
  const formik = useFormikContext();

  return (
    <ModalWrapper
      type={type}
      isOpen={isOpen}
      onClose={onClose}
      actionButtonLabel="Confirm"
      onActionButtonPress={formik.submitForm}
      modalContentStyle={{
        paddingTop: { base: '40px', lg: '48px' },
      }}
    >
      <Flex direction="column" align="center">
        <Box>
          <TitleText style={{ align: 'center' }} text="Enter your promo code" />
        </Box>

        <Box height={{ base: '220px', lg: '320px' }} width={{ base: '220px', lg: '320px' }} marginBottom="32px">
          <Image src={HEART_DOG_IMG_SRC} height="100%" width="100%" />
        </Box>

        <Box>
          <FormikInput
            w={{ base: '280px', lg: '400px' }}
            variant="flushed"
            name={DISCOUNT_CODE_FIELD}
            type="text"
            placeholder="Promo code"
          />
        </Box>
      </Flex>
    </ModalWrapper>
  );
};
