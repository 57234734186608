import {
  ADDRESS_LINE_1_FIELD,
  CAT_OR_DOG_FIELD,
  EMAIL_FIELD,
  GENDER_FIELD,
  IS_MICROCHIPPED_FIELD,
  IS_NEUTERED_FIELD,
  PET_BREED_FIELD,
  PET_DATE_OF_BIRTH_FIELD,
  PET_NAME_FIELD,
  PET_VALUE_FIELD,
  POLICY_START_DATE_FIELD,
  POSTCODE_FIELD,
  QuoteFormField,
  QuoteFormQuestionType,
} from '~/components/quote-form/quote-form.schema';

// Mandatory form fields
export const QUOTE_FORM_FIELD_QUESTION_TYPE_CONFIG: Partial<
  Record<QuoteFormQuestionType, QuoteFormField | QuoteFormField[]>
> = {
  PET_POLICY_START_DATE: POLICY_START_DATE_FIELD,
  EMAIL: EMAIL_FIELD,
  PET_BIRTH_DATE: PET_DATE_OF_BIRTH_FIELD,
  PET_NAME: PET_NAME_FIELD,
  PET_TYPE: CAT_OR_DOG_FIELD,
  PET_GENDER: GENDER_FIELD,
  PET_BREED: PET_BREED_FIELD,
  IS_PET_NEUTERED: IS_NEUTERED_FIELD,
  PET_VALUE: PET_VALUE_FIELD,
  LOOKUP_ADDRESS: [POSTCODE_FIELD, ADDRESS_LINE_1_FIELD],
  IS_PET_MICROCHIPPED: IS_MICROCHIPPED_FIELD,
};
