import { UserTraits } from '@segment/analytics-core/src/events/interfaces';

import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';

import { LocalStorageKey } from '~/constants/localStorageKey';

// To fetch all the traits, that are already saved for the current customer from the Local Storage
export function getSegmentTraitsStoredLocally(): UserTraits | null {
  let result: UserTraits | null = null;
  try {
    const storageItem = localStorage.getItem(LocalStorageKey.SegmentUserTraits);

    if (storageItem) {
      result = JSON.parse(storageItem);
    }
  } catch (err) {
    captureAndLogException(
      `Error while parsing stored locally Segment traits: "${JSON.stringify(
        err instanceof Error ? err.message : err,
      )}"`,
      'Error',
    );
  }

  return result;
}
