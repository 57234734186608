import React from 'react';
import { createPortal } from 'react-dom';
import { Box, Stack } from '@chakra-ui/react';

import { useToaster } from '~/providers/toaster-provider/ToasterProvider';

import { Toast } from '~/components/toaster/Toast';

import '~/components/toaster/Toaster.scss';

export const Toaster: React.FC = () => {
  return createPortal(<ToasterInner />, document.body);
};

// Todo: think about animation in the future
const ToasterInner: React.FC = () => {
  const { toasts } = useToaster();

  return (
    <Box className="toaster-container">
      <Stack spacing="12px">
        {toasts.map((toast) => (
          <Toast key={toast.id} toast={toast} />
        ))}
      </Stack>
    </Box>
  );
};
