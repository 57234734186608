import { PolicyCodes } from '@joinfluffy/common';

import type { ProductQuoteSchema } from '@shared/models/product';

import { useInsurancePolicyList } from '~/hooks/insurance-data-provider/policy/useInsurancePolicyList';

export function useSelectedPolicyData(policyCode: PolicyCodes) {
  const policyList = useInsurancePolicyList();

  return policyList?.find((policy: ProductQuoteSchema) => policy.productCode === policyCode);
}
