import { PolicyCodes } from '@joinfluffy/common';
import {
  QUOTE_FORM_CUSTOMER_INITIAL_VALUES,
  QUOTE_FORM_PET_INITIAL_VALUES,
  QuoteFormCustomerValues,
  QuoteFormPetValues,
} from '~/components/quote-form/quote-form.schema';

export interface FilledQuoteFormValuesSchema extends QuoteFormCustomerValues {
  pets: QuoteFormPetValues[];
}

export const INITIAL_FILLED_QUOTE_FORM_VALUES: FilledQuoteFormValuesSchema = {
  ...QUOTE_FORM_CUSTOMER_INITIAL_VALUES,
  pets: [QUOTE_FORM_PET_INITIAL_VALUES],
};

export type PolicyPriceForAllPets = Record<
  PolicyCodes,
  {
    monthlyPrice: number;
    annualPrice: number;
    totalDiscount: number;
  }
>;
