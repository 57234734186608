import React from 'react';

import { Text, TextProps } from '@chakra-ui/react';

import { Colors } from '~/constants/colors';

export interface TitleTextProps {
  text: string;
  type?: TitleTextType;
  style?: TextProps;
}

export type TitleTextType = 'TITLE' | 'SUBTITLE';

const STYLES_CONFIG: Record<TitleTextType, TextProps> = {
  TITLE: {
    fontSize: { base: '24px', lg: '35px' },
    lineHeight: { base: '32px', lg: '40px' },
    fontWeight: '700',
    w: { lg: '800px' },
    color: Colors.Brown,
  },
  SUBTITLE: {
    fontSize: { base: '16px', lg: '24px' },
    lineHeight: { base: '24px', lg: '32px' },
    fontWeight: '400',
    color: Colors.Brown,
  },
};

export const TitleText: React.FC<TitleTextProps> = function TitleText({ text, type = 'TITLE', style }) {
  return (
    <Text {...STYLES_CONFIG[type]} {...style}>
      {text}
    </Text>
  );
};
