import React from 'react';
import { Formik } from 'formik';

import {
  DISCOUNT_CODE_FIELD,
  DISCOUNT_CODE_FORM_INITIAL_VALUE,
  DiscountCodeFormValues,
} from '~/components/modal/discount-code-modal/discount-code-modal.schema';
import { DiscountCodeModalContent } from '~/components/modal/discount-code-modal/DiscountCodeModalContent';
import { discountCodeValidationSchema } from '~/components/modal/discount-code-modal/discount-code-modal.validation';

import { useToaster } from '~/providers/toaster-provider/ToasterProvider';

import { isDiscountCodeValid } from '~/helpers/discountCode/isDiscountCodeValid';
import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';
import { trackEvent } from '~/helpers/analytics/trackEvent';

import { useUiStateContextPatchUiState } from '~/hooks/useUiStateContextUpdateUi';
import { useInsuranceDataProviderSubmitQuoteFormWithDiscountCode } from '~/hooks/insurance-data-provider/useInsuranceDataProviderSubmitQuoteFormWithDiscountCode';

import { AnalyticsEvent } from '~/constants/analyticsEvent';
import { ModalTypes } from '~/constants/modalTypes';

interface DiscountCodeModalProps {
  type: ModalTypes;
  isOpen: boolean;
  onClose: () => void;
}

export const DiscountCodeModal: React.FC<DiscountCodeModalProps> = function DiscountCodeModal({
  type,
  isOpen,
  onClose,
}) {
  const patchUiState = useUiStateContextPatchUiState();

  const isApplyingPromocodeRef = React.useRef<boolean>(false);

  const submitFormWithDiscountCodeHandler = useInsuranceDataProviderSubmitQuoteFormWithDiscountCode();

  const { showToast } = useToaster();

  const applyPromoCode = React.useCallback(
    async (values: DiscountCodeFormValues) => {
      if (isApplyingPromocodeRef.current) {
        return;
      }

      isApplyingPromocodeRef.current = true;

      try {
        patchUiState({ isLoading: true });
        const discountCode = values[DISCOUNT_CODE_FIELD];

        const isProvidedCodeValid = await isDiscountCodeValid(discountCode);

        trackEvent({ eventName: AnalyticsEvent.QuoteDiscountCodeConfirmed, usedValidCode: isProvidedCodeValid });

        if (!isProvidedCodeValid) {
          showToast('Invalid promo code ❌');

          return;
        }

        await submitFormWithDiscountCodeHandler(discountCode);

        showToast('Promo code applied ✅');

        onClose();
      } catch (e) {
        captureAndLogException(e, 'Error');
      } finally {
        isApplyingPromocodeRef.current = false;
        patchUiState({ isLoading: false });
      }
    },
    [patchUiState, submitFormWithDiscountCodeHandler, showToast, onClose],
  );

  return (
    <Formik
      initialValues={DISCOUNT_CODE_FORM_INITIAL_VALUE}
      validationSchema={discountCodeValidationSchema}
      onSubmit={applyPromoCode}
    >
      <DiscountCodeModalContent type={type} isOpen={isOpen} onClose={onClose} />
    </Formik>
  );
};
