import moment from 'moment-timezone';

import { INTERNAL_DATE_FORMAT } from '@joinfluffy/common';

export function getTodaysDate() {
  return moment().startOf('day');
}

export function getDateStartOfDay(value: string) {
  return moment(value, INTERNAL_DATE_FORMAT).startOf('day');
}

export function isStringValidDate(value: string) {
  return moment(value, INTERNAL_DATE_FORMAT).isValid();
}

export function getDatesDifferenceInMonth(startDate: moment.Moment, endDate: moment.Moment) {
  return startDate.diff(endDate, 'months');
}

export function getDatesDifferenceInYearsMonths(startDate: moment.Moment, endDate: moment.Moment) {
  const diff = moment.duration(startDate.diff(endDate));

  return {
    years: diff.years(),
    months: diff.months(),
  };
}

export function getMoment(value?: string | number | null | Date, format?: string, strict?: boolean) {
  return moment(value, format, strict);
}

// MixPanel friendly date format
export function getUTCDateISOFormat(value: string, dateFormat = INTERNAL_DATE_FORMAT) {
  return moment.utc(value, dateFormat).toISOString();
}

export function getMomentFromLocaleDate(date: Date) {
  // Moment's timezone set to London globally, dates timezone depends on local timezone
  return moment(date).subtract(moment(date).utcOffset() + date.getTimezoneOffset(), 'minutes');
}
