import React from 'react';

import { PetAgeQuestionContext } from '~/components/questions/pet-age-question/PetAgeQuestionContext';

// Be sure you call this hook inside the PetAgeQuestionProvider,
// otherwise you will have an error
export function usePetBirthDateMissedFields() {
  const petAgeQuestionContext = React.useContext(PetAgeQuestionContext);
  if (!petAgeQuestionContext) {
    // Should be handled during development
    throw new Error('Trying to call "React.useContext(PetAgeQuestionContext)" without "PetAgeQuestionProvider"');
  }

  const { isDdMissed, setIsDdMissed, isDdMmMissed, setIsDdMmMissed } = petAgeQuestionContext;

  return {
    isDdMissed,
    setIsDdMissed,
    isDdMmMissed,
    setIsDdMmMissed,
  };
}
