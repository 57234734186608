import React from 'react';
import moment from 'moment-timezone';
import { useField } from 'formik';

import { CatOrDog, INTERNAL_DATE_FORMAT } from '@joinfluffy/common';

import { Box, Flex, Image, Stack, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import { TitleText } from '~/components/common/TitleText';
import { ActionButton } from '~/components/common/ActionButton';
import { HAS_MULTIPLE_PETS_FIELD, QuoteFormValues } from '~/components/quote-form/quote-form.schema';
import { MultipetOptions } from '~/components/quote-form/constants/MultipetOptions';

import { useFillingMultipetDataHelpers } from '~/hooks/insurance-data-provider/useFillingMultipetDataHelpers';

import { getDatesDifferenceInMonth } from '~/helpers/date/date';
import { formatPetNameForDisplay } from '~/helpers/pet/petName';
import { trackEvent } from '~/helpers/analytics/trackEvent';
import { getPetIconPath } from '~/helpers/petIcon/petIcon';

import { Colors } from '~/constants/colors';
import { AnalyticsEvent } from '~/constants/analyticsEvent';
import { AnalyticsEventFields } from '~/constants/analyticsEventField';

const SINGLE_PET_DISCOUNT_INFO_LABEL =
  'Just to let you know each pet you insure with Fluffy will receive up to 10% discount';
const MULTI_PET_DISCOUNT_INFO_LABEL = 'Up to 10% discount will be applied! ✅';

interface MultiPetQuestionProps {
  openNextStep: (value: string) => void;
}

interface PetCardProps {
  petName: string;
  dateOfBirth: string;
  petBreed: string;
  catOrDog: CatOrDog | null;
  imgPath?: string;
  onEditPress: () => void;
  onRemovePress: () => void;
}

export const MultiPetQuestion: React.FC<MultiPetQuestionProps> = function MultiPetQuestion({ openNextStep }) {
  const [, , multipetFieldHelpers] = useField<QuoteFormValues[typeof HAS_MULTIPLE_PETS_FIELD]>(HAS_MULTIPLE_PETS_FIELD);

  const { pets, startFillingNewPetData, getEditPetDetailsCb, removePet, saveCurrentFormPet } =
    useFillingMultipetDataHelpers();

  const discountInfoLabel = pets.length > 1 ? MULTI_PET_DISCOUNT_INFO_LABEL : SINGLE_PET_DISCOUNT_INFO_LABEL;

  const goToNextQuestion = React.useCallback(() => {
    saveCurrentFormPet();

    const isMultipet = pets.length > 1;

    const multipetFieldValue = isMultipet ? MultipetOptions.Multiple : MultipetOptions.Single;

    multipetFieldHelpers.setValue(multipetFieldValue);

    trackEvent({
      eventName: AnalyticsEvent.QuoteNumOfPetsConfirmed,
      [AnalyticsEventFields.QuotingNumOfPets]: pets.length,
    });

    openNextStep(multipetFieldValue);
  }, [multipetFieldHelpers, openNextStep, pets.length, saveCurrentFormPet]);

  return (
    <Stack spacing={{ base: '32px', lg: '48px' }} align="center">
      <TitleText text="Your pets" />

      <Wrap
        marginTop="18px"
        maxW={{ base: '320px', lg: '800px' }}
        spacing={{ base: '24px', lg: '40px' }}
        justify="center"
      >
        {pets.map((pet, idx) => (
          <WrapItem key={idx}>
            <PetCard
              {...pet}
              imgPath={getPetIconPath(pets, idx)}
              onEditPress={getEditPetDetailsCb(idx)}
              onRemovePress={removePet(idx)}
            />
          </WrapItem>
        ))}
      </Wrap>

      <Text
        fontSize={{ base: '14px', lg: '16px' }}
        lineHeight={{ base: '20px', lg: '24px' }}
        color={Colors.Black}
        fontWeight="900"
        textAlign="center"
      >
        {discountInfoLabel}
      </Text>

      <VStack spacing="16px">
        <ActionButton label="Next step" onPress={goToNextQuestion} />
        <ActionButton
          style={{ backgroundColor: Colors.Brown }}
          label="Add another pet"
          onPress={startFillingNewPetData}
        />
      </VStack>
    </Stack>
  );
};

const PetCard: React.FC<PetCardProps> = function PetCard({
  petName,
  dateOfBirth,
  petBreed,
  catOrDog,
  imgPath,
  onEditPress,
  onRemovePress,
}) {
  const monthsCount = getDatesDifferenceInMonth(moment(), moment(dateOfBirth, INTERNAL_DATE_FORMAT));

  const months = monthsCount % 12;
  const years = (monthsCount - months) / 12;

  const ageLabel = React.useMemo(() => {
    const yearsLabel = years > 1 ? 'years' : 'year';
    const monthLabel = months > 1 ? 'months' : 'month';

    const showYears = years > 0;
    const showMonths = months > 0;

    const yearsAge = showYears ? `${years} ${yearsLabel}` : '';
    const monthsAge = showMonths ? `${months} ${monthLabel}` : '';

    return [yearsAge, monthsAge].filter(Boolean).join(' ');
  }, [months, years]);

  const showPetType = React.useMemo(() => {
    const breed = petBreed.toLocaleLowerCase();

    if (catOrDog === 'cat') {
      return !breed.endsWith('cat');
    }

    return !breed.endsWith('dog');
  }, [catOrDog, petBreed]);

  return (
    <VStack
      spacing="20px"
      w="320px"
      minH={{ base: '300px', lg: '320px' }}
      padding="20px"
      bg={Colors.Beige}
      borderRadius="16px"
      align="center"
    >
      <Flex w="80px" h="80px" borderRadius="80px" bg={Colors.Brown} align="center" justify="center">
        <Image src={imgPath} w="80%" h="80%" />
      </Flex>

      <Box>
        <Text
          fontSize={{ base: '24px', lg: '32px' }}
          lineHeight={{ base: '24px', lg: '32px' }}
          fontWeight="900"
          color={Colors.Black}
        >
          {formatPetNameForDisplay(petName)}
        </Text>
      </Box>

      <Box>
        <Text fontSize="16px" lineHeight="20px" fontWeight="900" color={Colors.Black}>
          {petBreed} {showPetType && catOrDog}
        </Text>
        <Text fontSize="16px" lineHeight="20px" fontWeight="900" color={Colors.Black}>
          {ageLabel} old
        </Text>
      </Box>

      <Flex flexDirection="column">
        <ActionButton
          labelStyle={{ lineHeight: { base: '20px', lg: '24px' }, fontSize: { base: '16px', lg: '18px' } }}
          label="Edit details"
          onPress={onEditPress}
          isSecondary
        />
        <ActionButton
          style={{ marginTop: '8px' }}
          labelStyle={{ lineHeight: { base: '20px', lg: '24px' }, fontSize: { base: '16px', lg: '18px' } }}
          label="Remove"
          onPress={onRemovePress}
          isSecondary
        />
      </Flex>
    </VStack>
  );
};
