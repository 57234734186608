import { ToastSchema } from '~/providers/toaster-provider/toasterProvider.schema';

import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';

type Action = { type: 'add'; payload: ToastSchema } | { type: 'remove'; payload: string };

export function toasterReducer(toasts: ToastSchema[], action: Action): ToastSchema[] {
  switch (action.type) {
    case 'add':
      return [...toasts, action.payload];
    case 'remove':
      return toasts.filter(({ id }) => id !== action.payload);
    default:
      captureAndLogException(
        new Error(`Action is not supported by toaster reducer. Action: "${JSON.stringify(action)}"`),
        'Error',
      );

      return toasts;
  }
}
