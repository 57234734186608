export enum LocalStorageKey {
  Authorization = 'authorization',
  SubmittedQuoteFormAt = 'submitted_quote_form_at', // user completed the 1st step of the form
  SubmittedCustomerFormAt = 'submitted_customer_form_at', // user completed the 2nd step of the form
  SavedQuoteId = 'saved_quote_id',
  SelectedProductAnnualPremium = 'selected_product_annual_premium',
  SavedCustomerFormValues = 'saved_customer_form_values',
  SavedCustomerEmail = 'saved_customer_email',

  // Segment keys:
  SegmentAnonymousId = 'ajs_anonymous_id',
  SegmentUserId = 'ajs_user_id',
  SegmentUserTraits = 'ajs_user_traits',
}
