import React from 'react';

import { Box, Center, Image, Text } from '@chakra-ui/react';

import { Colors } from '~/constants/colors';

import { ReactComponent as ComingSoonBadge } from '~/assets/icons/coming-soon-badge.svg';

interface PreferredBenefitCardProps {
  title: string;
  iconSrc: string;
  isNotAvailableYet?: boolean;
  isSelected?: boolean;
  onPress: () => void;
}

export const PreferredBenefitCard: React.FC<PreferredBenefitCardProps> = function PreferredBenefitCard({
  title,
  iconSrc,
  isSelected,
  isNotAvailableYet,
  onPress,
}) {
  return (
    <Center
      position="relative"
      flexDirection="column"
      width={{ base: '136px', lg: '156px' }}
      height={{ base: '152px', lg: '172px' }}
      borderRadius="20px"
      padding="0px 16px"
      bg={isSelected ? Colors.OrangePale : Colors.White}
      cursor="pointer"
      _hover={{ boxShadow: '0 8px 12px -4px rgb(0 0 0 / 5%)', transform: 'scale(1.02)' }}
      onClick={onPress}
    >
      {Boolean(isNotAvailableYet) && (
        <Box
          position="absolute"
          top={{ base: '-12px', lg: '-16px' }}
          left={{ base: '-14px', lg: '-20px' }}
          width={{ base: '48px', lg: '60px' }}
          height={{ base: '48px', lg: '60px' }}
        >
          <ComingSoonBadge width="100%" height="100%" />
        </Box>
      )}

      <Box width={{ base: '60px', lg: '80px' }} height={{ base: '60px', lg: '80px' }}>
        <Image src={iconSrc} w="100%" h="100%" />
      </Box>

      <Box marginTop="8px">
        <Text
          fontSize={{ base: '16px', lg: '20px' }}
          lineHeight={{ base: '20px', lg: '24px' }}
          color={Colors.Orange}
          fontWeight="700"
        >
          {title}
        </Text>
      </Box>
    </Center>
  );
};
