import React from 'react';
import { useDisclosure } from '@chakra-ui/react';

import { MODAL_CONFIG } from '~/components/modal/config/ModalConfig';

import { useUiStateContextModal } from '~/hooks/useUiStateContextModal';
import { useUiStateContextPatchUiState } from '~/hooks/useUiStateContextUpdateUi';

export const Modal: React.FC = function Modal() {
  const modal = useUiStateContextModal();
  const patchUiState = useUiStateContextPatchUiState();
  const Component = MODAL_CONFIG[modal?.type || ''];

  const { isOpen, onClose } = useDisclosure({ isOpen: Boolean(modal) });

  const closeModal = React.useCallback(() => {
    patchUiState && patchUiState({ modal: undefined });

    onClose();
  }, [patchUiState, onClose]);

  if (!modal || !Component) {
    return null;
  }

  const modalParams = { ...modal.params, type: modal.type, onClose: closeModal, isOpen };

  return <Component {...modalParams} />;
};
