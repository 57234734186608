import React from 'react';

import {
  InsuranceDataContext,
  InsuranceDataContextSchema,
} from '~/providers/insurance-data-provider/InsuranceDataProvider';

export function useInsuranceDataContext(): InsuranceDataContextSchema {
  const insuranceDataContext = React.useContext(InsuranceDataContext);

  if (!insuranceDataContext) {
    // Should be handled during development
    throw new Error('Trying to call "useInsuranceDataContext" without "InsuranceDataProvider"');
  }

  return insuranceDataContext;
}
