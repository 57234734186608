import { CURRENT_PET_IDX_FIELD, QuoteFormValues } from '~/components/quote-form/quote-form.schema';

import { extractCustomerDataFromQuoteFormValues } from '~/helpers/quote/quoteApi/quoteApi';

import { useFilledQuoteFormValues } from '~/hooks/insurance-data-provider/useFilledQuoteFormValues';

export function useFilledQuoteFormValuesCurrentPetFormValues(): QuoteFormValues {
  const filledQuoteFormValues = useFilledQuoteFormValues();
  const currentPetIdxField = filledQuoteFormValues[CURRENT_PET_IDX_FIELD];

  const currentPetData = filledQuoteFormValues.pets[currentPetIdxField];
  const customerData = extractCustomerDataFromQuoteFormValues(filledQuoteFormValues);

  return { ...customerData, ...currentPetData };
}
