import * as yup from 'yup';

import { petBreedValueList, petTypeValueList, petGenderValueList, INTERNAL_DATE_FORMAT } from '@joinfluffy/common';

import { DD_MM_YYYY_REGEXP, EMAIL_REGEXP, UK_POSTCODE_REGEXP } from '@shared/constants/regexp';
import {
  DEFAULT_MAX_STRING_LENGTH,
  LOOKUP_MAX_STRING_LENGTH,
  PHONE_NUMBER_MAX_STRING_LENGTH,
  PHONE_NUMBER_MIN_STRING_LENGTH,
  POSTCODE_MAX_STRING_LENGTH,
} from '@shared/constants/validation';

import {
  ADDRESS_LINE_1_FIELD,
  ADDRESS_LINE_2_FIELD,
  ADDRESS_LINE_3_FIELD,
  CAT_OR_DOG_FIELD,
  EMAIL_FIELD,
  GENDER_FIELD,
  IS_MICROCHIPPED_FIELD,
  IS_NEUTERED_FIELD,
  IS_OVER_EIGHTEEN_FIELD,
  OPTIONAL_PHONE_NUMBER_FIELD,
  PET_BREED_FIELD,
  PET_DATE_OF_BIRTH_FIELD,
  PET_NAME_FIELD,
  PET_VALUE_FIELD,
  POLICY_START_DATE_FIELD,
  POSTCODE_FIELD,
} from '~/components/quote-form/quote-form.schema';

import { checkPetDateFormatIsCorrect, checkPetDateIsValid } from '~/helpers/validation/petDateValidation';
import {
  checkIsPolicyStartDateValid,
  checkPetPolicyStartDateIsNotLaterThan30Days,
  checkPolicyStartDateIsNotEarlierThanToday,
} from '~/helpers/validation/policyStartDataValidation';

const CURRENCY_REGEXP = /(^£\d+)/;

export const quoteFormValidationSchema = yup.object({
  [PET_NAME_FIELD]: yup
    .string()
    .trim()
    .required('Please provide the name of your pet')
    .max(DEFAULT_MAX_STRING_LENGTH, `Pet name should not be longer than ${DEFAULT_MAX_STRING_LENGTH} symbols`),
  [CAT_OR_DOG_FIELD]: yup
    .string()
    .trim()
    .oneOf(petTypeValueList, 'Pet type must be cat or dog')
    .nullable()
    .required('Please indicate if you have a dog or a cat'),
  [GENDER_FIELD]: yup.string().trim().oneOf(petGenderValueList).nullable().required('Pet gender is required'),
  [PET_BREED_FIELD]: yup.string().trim().oneOf(petBreedValueList).required('Please provide the Breed of your pet'),
  [PET_DATE_OF_BIRTH_FIELD]: yup
    .string()
    .trim()
    .required('Please provide pet date of birth')
    .test('Check that string is a valid date', 'Please enter valid date of birth', checkPetDateIsValid)
    .test('Check that date has a valid format', checkPetDateFormatIsCorrect),
  [IS_MICROCHIPPED_FIELD]: yup.bool().nullable().required('Please indicate if your pet is microchipped'),
  [IS_NEUTERED_FIELD]: yup.bool().nullable().required('Please indicate if your pet is neutered/spayed'),
  [PET_VALUE_FIELD]: yup
    .string()
    .trim()
    .matches(CURRENCY_REGEXP, 'Please enter a valid value')
    .nullable()
    .required('Please provide the value of your pet'),
  [POSTCODE_FIELD]: yup
    .string()
    .trim()
    .max(POSTCODE_MAX_STRING_LENGTH, `Postcode should not be longer than ${POSTCODE_MAX_STRING_LENGTH} symbols`)
    .required(`Please provide the postcode`)
    .matches(UK_POSTCODE_REGEXP, 'Please enter valid UK post code e.g. E3 4EF'),
  [ADDRESS_LINE_1_FIELD]: yup
    .string()
    .trim()
    .required(`Please provide the Address`)
    .max(LOOKUP_MAX_STRING_LENGTH, `Address Line 1 should not be longer than ${LOOKUP_MAX_STRING_LENGTH} symbols`),
  [ADDRESS_LINE_2_FIELD]: yup
    .string()
    .trim()
    .max(DEFAULT_MAX_STRING_LENGTH, 'Address line 2 is longer than 100 symbols'),
  [ADDRESS_LINE_3_FIELD]: yup
    .string()
    .trim()
    .max(DEFAULT_MAX_STRING_LENGTH, 'Address line 3 is longer than 100 symbols'),
  [POLICY_START_DATE_FIELD]: yup
    .string()
    .nullable()
    .required('Please provide the date you want your policy to start')
    .test('Check that string is a valid date', 'Please enter valid policy start date', checkIsPolicyStartDateValid)
    .matches(DD_MM_YYYY_REGEXP, `Date format should be: ${INTERNAL_DATE_FORMAT}`)
    .test(
      'Check start date is not earlier than today',
      "Policy start date can't be in the past",
      checkPolicyStartDateIsNotEarlierThanToday,
    )
    .test(
      'Check start date is later than 30 days',
      "Policy start date can't be later than 30 days",
      checkPetPolicyStartDateIsNotLaterThan30Days,
    ),
  [EMAIL_FIELD]: yup
    .string()
    .trim()
    .max(DEFAULT_MAX_STRING_LENGTH, `Email should not be longer than ${DEFAULT_MAX_STRING_LENGTH} symbols`)
    .matches(EMAIL_REGEXP, 'Invalid email format')
    .required('Please provide your email'),
  [IS_OVER_EIGHTEEN_FIELD]: yup.bool().nullable().required('Please indicate if you are over 18'),
  [OPTIONAL_PHONE_NUMBER_FIELD]: yup
    .string()
    .trim()
    .min(PHONE_NUMBER_MIN_STRING_LENGTH, `Phone number should have at least ${PHONE_NUMBER_MIN_STRING_LENGTH} symbols`)
    .max(
      PHONE_NUMBER_MAX_STRING_LENGTH,
      `Phone number should not be longer than ${PHONE_NUMBER_MAX_STRING_LENGTH} symbols`,
    )
    .notRequired(),
});
