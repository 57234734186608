import React from 'react';
import { Box, BoxProps, HStack, Text, Wrap, WrapItem } from '@chakra-ui/react';

import { formatProductBenefitNameLabel } from '@joinfluffy/common';

import { PerkItem } from '~/components/common/PerkItem';

import { Colors } from '~/constants/colors';

import { PARTNERS_BENEFITS } from '~/configs/partnersBenefits';

import { ReactComponent as PlusIcon } from '~/assets/icons/plus-in-circle.svg';

interface PartnersBenefitsSectionProps {
  style?: BoxProps;
}

export const PartnersBenefitsSection: React.FC<PartnersBenefitsSectionProps> = function PartnersBenefitsSection({
  style,
}) {
  return (
    <Box position="relative" {...style}>
      <Box>
        <Text fontSize="18px" lineHeight="32px" color={Colors.Brown} fontWeight="900">
          You also get the following rewards by joining Fluffy
        </Text>
      </Box>
      <Wrap marginTop="18px" spacing="16px" maxW={{ lg: '471px' }} justify="center">
        {PARTNERS_BENEFITS.map(({ title, imgSrc }, idx) => (
          <WrapItem key={idx}>
            <PerkItem
              style={{ justify: 'flex-start' }}
              titleContainerStyle={{ marginTop: '12px' }}
              imgSrc={imgSrc}
              title={formatProductBenefitNameLabel(title)}
              disableClick
            />
          </WrapItem>
        ))}
      </Wrap>

      <HStack w="100%" position="absolute" bottom="-48px" spacing="5px" alignItems="center" justify="center">
        <Box width="25px" height="25px">
          <PlusIcon width="25px" height="25px" />
        </Box>

        <Text fontSize="12px" lineHeight="16px" color={Colors.Black} fontWeight="500">
          over 20 more rewards
        </Text>
      </HStack>
    </Box>
  );
};
