import React from 'react';
import moment from 'moment-timezone';
import { useField } from 'formik';

import { CatOrDog, INTERNAL_DATE_FORMAT } from '@joinfluffy/common';

import {
  CAT_MAX_AGE_FOR_INSURANCE_IN_YEARS,
  DOG_MAX_AGE_FOR_INSURANCE_IN_YEARS,
  MIN_AGE_FOR_INSURANCE_IN_WEEKS,
} from '@shared/constants/validation';

import { CAT_OR_DOG_FIELD, QuoteFormValues, PET_DATE_OF_BIRTH_FIELD } from '~/components/quote-form/quote-form.schema';

import { useInvokeModal } from '~/hooks/useInvokeModal';

import { getTodaysDate, getUTCDateISOFormat } from '~/helpers/date/date';
import { trackEvent } from '~/helpers/analytics/trackEvent';

import { AnalyticsEvent } from '~/constants/analyticsEvent';
import { ModalTypes } from '~/constants/modalTypes';

export function usePetDateOfBirthSubmitHandler({ onActionButtonClick }: { onActionButtonClick: () => void }) {
  const { invokeModal } = useInvokeModal();

  const [petDateOfBirthFormikField] =
    useField<QuoteFormValues[typeof PET_DATE_OF_BIRTH_FIELD]>(PET_DATE_OF_BIRTH_FIELD);
  const [catOrDogFormikField] = useField<QuoteFormValues[typeof CAT_OR_DOG_FIELD]>(CAT_OR_DOG_FIELD);

  const onDateInputSubmit = React.useCallback(() => {
    const trackingDateValue = getUTCDateISOFormat(petDateOfBirthFormikField.value);

    const { petAgeIsOutOfRange, isPetTooYoung, isPetTooOld } = calculatePetAgeIsOutOfRange(
      petDateOfBirthFormikField.value,
      {
        catOrDog: catOrDogFormikField.value,
      },
    );

    trackEvent({
      eventName: AnalyticsEvent.QuotePetAgeFullConfirmed,
      dateFormat: INTERNAL_DATE_FORMAT,
      dateValue: trackingDateValue,
      petAgeIsOutOfRange,
      isPetTooYoung,
      isPetTooOld,
    });

    if (petAgeIsOutOfRange) {
      invokeModal(ModalTypes.NotSupportedPetAge, {
        petTypeValue: catOrDogFormikField.value,
        isPetTooYoung,
        isPetTooOld,
      });

      return;
    }

    // pet has perfect age to be insured - we can continue 🚀
    onActionButtonClick();
  }, [petDateOfBirthFormikField.value, catOrDogFormikField.value, onActionButtonClick, invokeModal]);

  return { onDateInputSubmit };
}

export function calculatePetAgeIsOutOfRange(
  petDateOfBirthValue: string,
  options: {
    catOrDog: CatOrDog | null;
  },
) {
  const petDateOfBirthMomentDate = moment(petDateOfBirthValue, INTERNAL_DATE_FORMAT);

  const isPetTooYoung = petDateOfBirthMomentDate.isAfter(
    getTodaysDate().subtract(MIN_AGE_FOR_INSURANCE_IN_WEEKS, 'weeks'),
  );

  const isPetTooOld = petDateOfBirthMomentDate.isSameOrBefore(
    getTodaysDate().subtract(
      options.catOrDog === 'dog' ? DOG_MAX_AGE_FOR_INSURANCE_IN_YEARS : CAT_MAX_AGE_FOR_INSURANCE_IN_YEARS,
      'years',
    ),
  );

  const petAgeIsOutOfRange = isPetTooYoung || isPetTooOld;

  return {
    petAgeIsOutOfRange,
    isPetTooYoung,
    isPetTooOld,
  };
}
