import React from 'react';

import { toasterReducer } from '~/providers/toaster-provider/toasterReducer';
import { prepareToast } from '~/providers/toaster-provider/toaster.helpers';
import type { ToastConfig, ToasterAPI } from '~/providers/toaster-provider/toasterProvider.schema';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const ToasterContext = React.createContext<ToasterAPI>({
  showToast: () => '',
  hideToast: noop,
  toasts: [],
});

export function useToaster() {
  return React.useContext(ToasterContext);
}

const DEFAULT_TOAST_DURATION_MS = 5000;

export const ToasterProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [toasts, dispatch] = React.useReducer(toasterReducer, []);

  const hideToast = React.useCallback((id: string) => dispatch({ type: 'remove', payload: id }), []);
  const showToast = React.useCallback(
    (message: string, toastConfig?: ToastConfig) => {
      const toast = prepareToast(message, toastConfig);
      dispatch({ type: 'add', payload: toast });

      setTimeout(() => hideToast(toast.id), toastConfig?.durationMs ?? DEFAULT_TOAST_DURATION_MS);

      return toast.id;
    },
    [hideToast],
  );

  const contextValue = React.useMemo(() => ({ showToast, hideToast, toasts }), [hideToast, showToast, toasts]);

  return <ToasterContext.Provider value={contextValue}>{children}</ToasterContext.Provider>;
};
