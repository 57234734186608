export const PreferredBenefitsCodes = [
  'DOG_TRAINING',
  'VET_HELPINE',
  'TRAINER_HELPINE',
  'DISCOUNTS_ON_PET_FOOD',
  'SMART_COLLAR',
  'WORM_AND_FLEA_TREATMENT',
  'FREE_ANNUAL_VET_CHECKUP',
  'DISCOUNTS_ON_DOG_WALKING_AND_SITTING',
] as const;

export type PreferredBenefitCode = (typeof PreferredBenefitsCodes)[number];

export interface PreferredBenefitContentConfig {
  title: string;
  iconSrc: string;
  isNotAvailableYet?: boolean;
}

export const PREFERED_BENEFITS_CODE_CONTENT_CONFIG: Record<PreferredBenefitCode, PreferredBenefitContentConfig> = {
  DOG_TRAINING: {
    title: 'Dog Training',
    iconSrc: '/images/preferred-benefits/sitting-dog-with-heart.png',
  },
  VET_HELPINE: {
    title: '24/7 vet helpline',
    iconSrc: '/images/preferred-benefits/doctor-with-paw.png',
  },
  TRAINER_HELPINE: {
    title: 'Personalised growth plans',
    iconSrc: '/images/preferred-benefits/dog-with-trainer.png',
  },
  DISCOUNTS_ON_PET_FOOD: {
    title: 'Discounts on pet food',
    iconSrc: '/images/preferred-benefits/pack-with-paw.png',
  },
  SMART_COLLAR: {
    title: 'Smart collar',
    iconSrc: '/images/preferred-benefits/collar.png',
    isNotAvailableYet: true,
  },
  WORM_AND_FLEA_TREATMENT: {
    title: 'Worm & Flea treatment',
    iconSrc: '/images/preferred-benefits/flea-treatment.png',
    isNotAvailableYet: true,
  },
  FREE_ANNUAL_VET_CHECKUP: {
    title: 'Free annual vet check up',
    iconSrc: '/images/preferred-benefits/calendar.png',
    isNotAvailableYet: true,
  },
  DISCOUNTS_ON_DOG_WALKING_AND_SITTING: {
    title: 'Discounts on dog walking & sitting',
    iconSrc: '/images/preferred-benefits/dog-walk.png',
    isNotAvailableYet: true,
  },
};
