import React from 'react';
import { Flex } from '@chakra-ui/react';

export const ScreenContainer: React.FC<React.PropsWithChildren> = function ScreenContainer({ children }) {
  return (
    <Flex
      direction="column"
      justify="space-between"
      width={{ base: '375px', lg: '1100px' }}
      minHeight="100vh"
      padding={{ base: '16px' }}
      marginX="auto"
      alignSelf="center"
    >
      {children}
    </Flex>
  );
};
