import moment from 'moment-timezone';
import { FormikContextType } from 'formik';

import { INTERNAL_DATE_FORMAT } from '@joinfluffy/common';

import { CustomerFormValues, CustomerFormField } from '~/components/customer-form/customer-form.schema';
import {
  QuoteFormValues,
  QuoteFormField,
  EMAIL_FIELD,
  QUOTE_FORM_PET_INITIAL_VALUES,
} from '~/components/quote-form/quote-form.schema';

import { FilledQuoteFormValuesSchema } from '~/providers/insurance-data-provider/types';
import { isFilledQuoteFormValuesType } from '~/providers/insurance-data-provider/helpers/common';

import {
  getDatesDifferenceInMonth,
  getDatesDifferenceInYearsMonths,
  getTodaysDate,
  getUTCDateISOFormat,
} from '~/helpers/date/date';
import { identify } from '~/helpers/analytics/identify';
import { getAnalyticsBrowser } from '~/helpers/analytics/analytics';

import {
  DATE_FORM_FIELDS_DATE_FORMAT,
  FILLED_QUOTE_FORM_VALUES_TRAITS_SKIPPED_FIELD,
  isDateFormFields,
} from '~/configs/tracking';

const QUOTING_PREFIX = 'quoting_';

export function normalizeIdentityObject(
  formValues: QuoteFormValues | CustomerFormValues | FilledQuoteFormValuesSchema,
) {
  const keysToSkip = isFilledQuoteFormValuesType(formValues)
    ? [FILLED_QUOTE_FORM_VALUES_TRAITS_SKIPPED_FIELD]
    : Object.keys(QUOTE_FORM_PET_INITIAL_VALUES);

  const normalizedKeyValues = Object.entries(formValues)
    .filter(([key]) => !keysToSkip.includes(key))
    .map(([key, value]) => {
      if (isDateFormFields(key)) {
        return [QUOTING_PREFIX + key, getUTCDateISOFormat(value)];
      }
      return [QUOTING_PREFIX + key, value];
    });

  return Object.fromEntries(normalizedKeyValues);
}

export function getCurrentQuoteFormQuestionTraits(
  formFields: QuoteFormField[],
  formik: FormikContextType<QuoteFormValues>,
  currentQuestionAnswerValue?: string | boolean,
) {
  return formFields.reduce((acc, formFieldName: QuoteFormField) => {
    let value =
      isCurrentQuestionAnswerValid(currentQuestionAnswerValue) && isSingleFieldQuestion(formFields)
        ? currentQuestionAnswerValue
        : formik.values[formFieldName];

    if (typeof value === 'string') {
      value = checkAndParseFormDateFieldsMixPanelFriendly(formFieldName, value);
      value = checkAndLowerEmailField(formFieldName, value);
    }

    acc[buildQuotingIdentityFieldName(formFieldName)] = value;

    return acc;
  }, {} as Record<string, unknown>);
}

export function getSingleFormikFieldValue(formField: QuoteFormField, formik: FormikContextType<QuoteFormValues>) {
  return formik.values[formField];
}

export function buildQuotingIdentityFieldName(fieldName: string) {
  return QUOTING_PREFIX + fieldName;
}

export function getUserQuoteFormEmail(formik: FormikContextType<QuoteFormValues>) {
  const email = String(getSingleFormikFieldValue(EMAIL_FIELD, formik));

  return email.length > 0 ? email : undefined;
}

export function getCurrentCustomerFormQuestionTraits(
  formFields: CustomerFormField[],
  formik: FormikContextType<CustomerFormValues>,
) {
  const data = Array.isArray(formFields) ? formFields : [formFields];

  return data.reduce((acc, formFieldName: CustomerFormField) => {
    let value = formik.values[formFieldName];
    if (typeof value === 'string') {
      if (formFieldName === 'dateOfOwnerBirth') {
        addUserAgeInYearsTrait(acc, value);
      }

      value = checkAndParseFormDateFieldsMixPanelFriendly(formFieldName, value);
    }

    acc[buildQuotingIdentityFieldName(formFieldName)] = value;

    return acc;
  }, {} as Record<string, unknown>);
}

export function addPetAgeInMonthsToIdentity(dateOfBirth: string, email?: string) {
  const differenceInMonth = getDatesDifferenceInMonth(moment(), moment(dateOfBirth, INTERNAL_DATE_FORMAT));

  identify(email, { quoting_pet_age_in_month: differenceInMonth });
}

export function checkAndParseFormDateFieldsMixPanelFriendly(
  fieldName: QuoteFormField | CustomerFormField,
  fieldValue: string,
) {
  if (isDateFormFields(fieldName)) {
    return getUTCDateISOFormat(fieldValue, DATE_FORM_FIELDS_DATE_FORMAT[fieldName]);
  }

  return fieldValue;
}

export function checkAndLowerEmailField(fieldName: QuoteFormField, fieldValue: string) {
  if (fieldName === EMAIL_FIELD) {
    return fieldValue.toLowerCase();
  }

  return fieldValue;
}

function addUserAgeInYearsTrait(traits: Record<string, unknown>, userDateOfBirth: string) {
  const dateOfBirthMoment = moment(userDateOfBirth, INTERNAL_DATE_FORMAT);
  const todaysMoment = getTodaysDate();

  const { years } = getDatesDifferenceInYearsMonths(todaysMoment, dateOfBirthMoment);

  traits['quoting_ownerAgeInYears'] = `${years}`;
}

export function isSingleFieldQuestion(fields: QuoteFormField[]) {
  return fields.length === 1;
}

export function isCurrentQuestionAnswerValid(value?: string | boolean) {
  return typeof value === 'string' || typeof value === 'boolean';
}

export async function resetAnalyticsUser() {
  const analyticsBrowser = getAnalyticsBrowser();

  await analyticsBrowser?.reset();
}
