import React from 'react';

import { AppRouter } from '~/components/pages/AppRouter';
import { Modal } from '~/components/modal/Modal';
import { OverlayLoader } from '~/components/common/OverlayLoader';
import { Toaster } from '~/components/toaster/Toaster';

import { useUiStateIsLoading } from '~/hooks/useUiStateIsLoading';

export const GlobalLoaderContentWrapper: React.FC = function GlobalLoaderContentWrapper() {
  const isLoading = useUiStateIsLoading();
  return (
    <>
      {isLoading && <OverlayLoader />}

      <Toaster />
      <Modal />
      <AppRouter />
    </>
  );
};
