import React from 'react';
import { Box, Flex, CheckboxGroup, Text } from '@chakra-ui/react';

import { useField } from 'formik';

import { POLICY_DOCUMENT_STATEMENTS_URL, PRIVACY_POLICY_CM_URL, TERMS_OF_BUSINESS_URL } from '@joinfluffy/common';

import { ScreenWithTitleContainer } from '~/components/common/ScreenWithTitleContainer';
import { ActionButton } from '~/components/common/ActionButton';
import { ExternalLink } from '~/components/common/ExternalLink';
import { CheckBoxWithStatement } from '~/components/common/CheckBoxWithStatement';
import { PolicyDeclarationItem } from '~/components/customer-form/policy-declration-view/policy-declaration-item/PolicyDeclarationItem';
import { FloatingContainer } from '~/components/common/FloatingContainer';
import {
  ARE_ASSUMPTIONS_READ_FIELD,
  ARE_POLICY_AND_BUSINESS_DOCUMENTS_READ_FIELD,
} from '~/components/customer-form/customer-form.schema';
import { Footer } from '~/components/common/Footer';

import { useHideFooter } from '~/hooks/common/useHideFooter';
import { useInsuranceProductInformationLink } from '~/hooks/useInsuranceProductInformationLink';

import { trackEvent } from '~/helpers/analytics/trackEvent';

import { PolicyDeclarationConfig } from '~/configs/policyDeclaration';

import { Colors } from '~/constants/colors';
import { AnalyticsEvent } from '~/constants/analyticsEvent';

export interface PolicyDeclarationViewProps {
  onSubmit: () => void;
}

export const PolicyDeclarationView: React.FC<PolicyDeclarationViewProps> = function PolicyStatementsConfirmationView({
  onSubmit,
}) {
  useHideFooter();

  const { productInformationLink } = useInsuranceProductInformationLink();

  const [arePolicyAndBusinessDocumentsReadField] = useField(ARE_POLICY_AND_BUSINESS_DOCUMENTS_READ_FIELD);
  const [areAssumtionsReadField] = useField(ARE_ASSUMPTIONS_READ_FIELD);

  const [showWarning, setShowWarning] = React.useState({
    arePolicyAndBusinessDocumentsReadFieldMissed: false,
    areAssumtionsReadFieldWarning: false,
  });

  const confirmPolicyDeclarations = React.useCallback(() => {
    setShowWarning({
      arePolicyAndBusinessDocumentsReadFieldMissed: !arePolicyAndBusinessDocumentsReadField.value,
      areAssumtionsReadFieldWarning: !areAssumtionsReadField.value,
    });

    if (!arePolicyAndBusinessDocumentsReadField.value) {
      const checkBox = document.getElementById(ARE_POLICY_AND_BUSINESS_DOCUMENTS_READ_FIELD);

      checkBox && checkBox.scrollIntoView();

      return;
    }

    if (!areAssumtionsReadField.value) {
      const checkBox = document.getElementById(ARE_ASSUMPTIONS_READ_FIELD);

      checkBox && checkBox.scrollIntoView();

      return;
    }

    trackEvent({ eventName: AnalyticsEvent.QuotePolicyDeclarationConfirmed });

    onSubmit();
  }, [areAssumtionsReadField.value, arePolicyAndBusinessDocumentsReadField.value, onSubmit]);

  return (
    <ScreenWithTitleContainer
      style={{ spacing: '24px', paddingBottom: '106px' }}
      title="Please read the important information about your policy"
    >
      <Box maxWidth="800px">
        <Box
          borderRadius="20px"
          backgroundColor={Colors.Beige}
          padding={{ base: '12px 24px', lg: '12px 32px' }}
          marginBottom="12px"
        >
          <Box marginBottom="8px">
            <Text fontSize="16px" lineHeight="20px" color={Colors.Black} fontWeight="700">
              Auto Renewal
            </Text>
          </Box>

          <Box>
            <Text fontSize="12px" lineHeight="14px" color={Colors.Black} fontWeight="400">
              To make sure you have continuous cover under your policy, the provider will aim to automatically renew
              your policy unless you tell us not to. At each renewal, the Insurer will recalculate the cost of cover and
              we’ll let you know via email the quote for the upcoming year five weeks before the renewal date. We’ll
              also notify you about any price changes and changes in the policy. You can cancel your policy by emailing
              us at support@insureyourpaws.co.uk.
            </Text>
          </Box>
        </Box>

        <PolicyDeclarationItem
          style={{ marginBottom: '16px' }}
          category="Demands and Needs."
          description="This lifetime product meets the demands and needs of pet owners seeking insurance which covers their pet against medical illnesses and injuries, up to a set amount each year for as long as the policy remains in force - as well as third-party liability and a package of additional covers including theft/straying and overseas travel."
        />

        <Flex justify="flex-start" maxWidth="800px" marginBottom="16px">
          <CheckboxGroup size="lg" colorScheme={Colors.Orange}>
            <CheckBoxWithStatement
              formikFieldName={ARE_POLICY_AND_BUSINESS_DOCUMENTS_READ_FIELD}
              showEmptyTickWarning={showWarning.arePolicyAndBusinessDocumentsReadFieldMissed}
            >
              I have read and understood the above information. I have also read the{' '}
              <ExternalLink style={{ color: Colors.Orange }} url={POLICY_DOCUMENT_STATEMENTS_URL}>
                Policy Documents
              </ExternalLink>
              ,{' '}
              <ExternalLink style={{ color: Colors.Orange }} url={PRIVACY_POLICY_CM_URL}>
                Privacy Policy
              </ExternalLink>
              ,{' '}
              <ExternalLink style={{ color: Colors.Orange }} url={TERMS_OF_BUSINESS_URL}>
                Terms of Business
              </ExternalLink>{' '}
              and{' '}
              <ExternalLink style={{ color: Colors.Orange }} url={productInformationLink || ''}>
                Insurance Product Information Document
              </ExternalLink>
            </CheckBoxWithStatement>
          </CheckboxGroup>
        </Flex>

        <Box marginBottom="20px" display={{ base: 'block', lg: 'none' }}>
          <Text fontSize="20px" lineHeight="32px" color={Colors.Black} fontWeight="700">
            Assumptions
          </Text>
        </Box>

        {PolicyDeclarationConfig.map(({ category, description }) => (
          <PolicyDeclarationItem
            key={category}
            style={{ marginBottom: { base: '8px', lg: '12px' } }}
            category={category}
            description={description}
          />
        ))}

        <Flex justify="flex-start" maxWidth="800px" paddingTop="8px">
          <CheckboxGroup size="lg" colorScheme={Colors.Orange}>
            <CheckBoxWithStatement
              formikFieldName={ARE_ASSUMPTIONS_READ_FIELD}
              showEmptyTickWarning={showWarning.areAssumtionsReadFieldWarning}
            >
              I have read, understood and accept the above assumptions. I understand that the underwriter reserves the
              right to decline in the event of a claim if my dog doesn&apos;t have a registered microchip number unless
              I have an exemption certificate from my vet
            </CheckBoxWithStatement>
          </CheckboxGroup>
        </Flex>
      </Box>

      <Footer />

      <FloatingContainer>
        <ActionButton label="Next step" onPress={confirmPolicyDeclarations} />
      </FloatingContainer>
    </ScreenWithTitleContainer>
  );
};
