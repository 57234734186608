export interface PolicyDeclarationItem {
  category: string;
  description: string;
}

export const PolicyDeclarationConfig: PolicyDeclarationItem[] = [
  {
    category: 'Location.',
    description:
      'You’re a resident of the United Kingdom, the owner and keeper of the pet, and the pet is kept in the UK at the address provided.',
  },
  {
    category: 'Breed Information.',
    description:
      'Your dog is not registered under The Dangerous Dogs Act 1991 and The Dangerous Dogs (Northern Ireland) order 1991 or Dangerous Dogs (amendment) Act 1997 or any subsequent amendments or a dog crossed with any of these. In addition, any dog that is an American Pit Bull Terrier, Perro de Presa Canario, wolf or wolf hybrid, or a dog crossed with any of these.',
  },
  {
    category: 'Pre-existing conditions.',
    description:
      'There is no cover for pre-existing conditions including illnesses developed before/within the first 14 days of the policy start date and or injuries that occurred before/within the first 48 hours of the policy start date. There is also no cover for cruciate ligament problems which occurred before/within the first 30 days of the policy start date.',
  },
  {
    category: 'Good care.',
    description:
      'You must look after your pet and maintain your pet’s health to avoid illness or injury and death. In addition, your pet must have a yearly health check, which is not covered by the policy, including a dental examination and vaccinations. If the pet is not vaccinated then any illness the pet would normally be protected against by such vaccinations won’t be covered.',
  },
  {
    category: 'Assumptions.',
    description:
      'Your dog is not used for security or as a: commercial guard dog, hunting/gundog, working, guide or assistance dog; and is not involved in any: racing, competitive agility, coursing or breeding. Your dog has never shown aggressive tendencies or been trained to attack. Your dog has never been involved in any third party related claims.',
  },
];
