import React from 'react';
import { useFormikContext } from 'formik';

import {
  CURRENT_PET_IDX_FIELD,
  CURRENT_QUESTION_STEP_IDX,
  QUOTE_FORM_PET_INITIAL_VALUES,
  QuoteFormValues,
} from '~/components/quote-form/quote-form.schema';

import { useFilledQuoteFormValues } from '~/hooks/insurance-data-provider/useFilledQuoteFormValues';
import { useUpdateFilledQuoteFormValues } from '~/hooks/insurance-data-provider/useUpdateFilledQuoteFormValues';

import { extractPetDataFromQuoteFormValues } from '~/helpers/quote/quoteApi/quoteApi';
import { trackEvent } from '~/helpers/analytics/trackEvent';

import { AnalyticsEvent } from '~/constants/analyticsEvent';
import { AnalyticsEventFields } from '~/constants/analyticsEventField';

const EMPTY_PET_FIELDS_ERRORS_VALUES = Object.fromEntries(
  Object.keys(QUOTE_FORM_PET_INITIAL_VALUES).map((key) => [key, undefined]),
);
const EMPTY_PET_FIELDS_TOUCHED_VALUES = Object.fromEntries(
  Object.keys(QUOTE_FORM_PET_INITIAL_VALUES).map((key) => [key, false]),
);

export function useFillingMultipetDataHelpers() {
  const filledQuoteFormValues = useFilledQuoteFormValues();
  const updateFilledQuoteFormValues = useUpdateFilledQuoteFormValues();
  const formik = useFormikContext<QuoteFormValues>();

  const cleanFormikErrors = () => {
    formik.setErrors({ ...formik.errors, ...EMPTY_PET_FIELDS_ERRORS_VALUES });
    formik.setTouched({ ...formik.touched, ...EMPTY_PET_FIELDS_TOUCHED_VALUES });
  };

  const getFilledPetsList = React.useCallback(() => {
    const currentPetIdx = filledQuoteFormValues[CURRENT_PET_IDX_FIELD];

    const pets = filledQuoteFormValues.pets;

    const currentPet = extractPetDataFromQuoteFormValues(formik.values);

    pets[currentPetIdx] = currentPet;

    return pets;
  }, [filledQuoteFormValues, formik.values]);

  const saveCurrentFormPet = React.useCallback(() => {
    const pets = getFilledPetsList();

    updateFilledQuoteFormValues({
      ...filledQuoteFormValues,
      pets,
    });
  }, [filledQuoteFormValues, getFilledPetsList, updateFilledQuoteFormValues]);

  const startFillingNewPetData = () => {
    trackEvent({ eventName: AnalyticsEvent.QuoteAddPetDetailsClick });

    saveCurrentFormPet();

    const updatedPets = getFilledPetsList();

    const currentPetIdx = updatedPets.length;

    updatedPets.push(QUOTE_FORM_PET_INITIAL_VALUES);

    updateFilledQuoteFormValues({
      ...filledQuoteFormValues,
      pets: [...updatedPets],
      [CURRENT_PET_IDX_FIELD]: currentPetIdx,
      [CURRENT_QUESTION_STEP_IDX]: 0,
    });

    formik.setValues({
      ...formik.values,
      ...QUOTE_FORM_PET_INITIAL_VALUES,
      [CURRENT_PET_IDX_FIELD]: currentPetIdx,
      [CURRENT_QUESTION_STEP_IDX]: 0,
    });

    cleanFormikErrors();
  };

  const getEditPetDetailsCb = (petIdx: number) => () => {
    trackEvent({ eventName: AnalyticsEvent.QuoteEditPetDetailsClick, [AnalyticsEventFields.QuotingPetIdx]: petIdx });

    saveCurrentFormPet();

    const pets = getFilledPetsList();

    const filledPetDetails = pets[petIdx];

    updateFilledQuoteFormValues({
      ...filledQuoteFormValues,
      pets: [...pets],
      [CURRENT_PET_IDX_FIELD]: petIdx,
      [CURRENT_QUESTION_STEP_IDX]: 0,
    });

    formik.setValues({
      ...formik.values,
      ...filledPetDetails,
      [CURRENT_PET_IDX_FIELD]: petIdx,
      [CURRENT_QUESTION_STEP_IDX]: 0,
    });
  };

  const removePet = (petIdx: number) => () => {
    trackEvent({ eventName: AnalyticsEvent.QuoteRemovePetDetailsClick, [AnalyticsEventFields.QuotingPetIdx]: petIdx });

    saveCurrentFormPet();

    const pets = getFilledPetsList();

    const currentPetIdx = filledQuoteFormValues[CURRENT_PET_IDX_FIELD];
    const currentQuestionIdx = formik.values[CURRENT_QUESTION_STEP_IDX];

    if (!pets[petIdx]) {
      return;
    }

    pets.splice(petIdx, 1);

    let updatedPets = pets;
    let updatedPetIdx = Math.max(currentPetIdx - 1, 0);
    let updatedQuestionIdx = currentQuestionIdx;
    let updatedQuoteFormPetValues = extractPetDataFromQuoteFormValues(formik.values);

    if (updatedPets.length === 0) {
      updatedPets = [QUOTE_FORM_PET_INITIAL_VALUES];
      updatedQuestionIdx = 0;
      updatedQuoteFormPetValues = updatedPets[updatedPetIdx];

      cleanFormikErrors();
    } else if (currentPetIdx === petIdx) {
      updatedPetIdx = updatedPets.length - 1;
      updatedQuoteFormPetValues = updatedPets[updatedPetIdx];
    }

    updateFilledQuoteFormValues({
      ...filledQuoteFormValues,
      pets: updatedPets,
      [CURRENT_PET_IDX_FIELD]: updatedPetIdx,
      [CURRENT_QUESTION_STEP_IDX]: updatedQuestionIdx,
    });

    formik.setValues({
      ...formik.values,
      ...updatedQuoteFormPetValues,
      [CURRENT_PET_IDX_FIELD]: updatedPetIdx,
      [CURRENT_QUESTION_STEP_IDX]: updatedQuestionIdx,
    });
  };

  return { pets: getFilledPetsList(), startFillingNewPetData, getEditPetDetailsCb, removePet, saveCurrentFormPet };
}
