import React from 'react';

import { Box, Flex, HStack, Image, Stack } from '@chakra-ui/react';

import { Header } from '~/components/common/Header';
import { TitleText } from '~/components/common/TitleText';
import { Footer } from '~/components/common/Footer';
import { ActionButton } from '~/components/common/ActionButton';

import { openUrlInNewTab } from '~/helpers/common';

import { APP_STORE_APP_PAGE_LINK, PLAY_MARKET_APP_PAGE_LINK } from '~/constants/mobileApp';
import { Colors } from '~/constants/colors';

const GET_ON_APP_STORE_IMG_SRC = '/images/action-tabs/get-on-app-store.png';
const GET_ON_GOOGLE_PLAY_IMG_SRC = '/images/action-tabs/get-on-google-play.png';
const GET_ON_APP_STORE_QR_IMG_SRC = '/images/qr-codes/get-app-on-app-store.png';
const GET_ON_GOOGLE_PLAY_QR_IMG_SRC = '/images/qr-codes/get-app-on-google-play.png';
const APP_SCREENSHOTS_IMG_SRC = '/images/mobile-app/app-screenshots.png';

const FOOTER_REMARK =
  'Your policy documents will be arriving in your email shortly. If you do not receive your Welcome Email within a few minutes, please check your Spam or Junk folder just in case the email got filtered there instead of your Inbox. Please contact 0344 273 2777.';

function openAppStoreAppPage() {
  openUrlInNewTab(APP_STORE_APP_PAGE_LINK);
}

function openGooglePlayAppPage() {
  openUrlInNewTab(PLAY_MARKET_APP_PAGE_LINK);
}

interface MobileAppInfoViewProps {
  isRestartFormButtonDisabled?: boolean;
  restartQuote: () => void;
}

export const MobileAppInfoView: React.FC<MobileAppInfoViewProps> = function MobileAppInfoView({
  isRestartFormButtonDisabled,
  restartQuote,
}) {
  return (
    <Flex flexDirection="column" minHeight="100vh">
      <Header />
      <HStack
        spacing="40px"
        justify={{ base: 'center', lg: 'space-between' }}
        padding={{ base: '72px 16px 0px 16px ', lg: '32px 0px 0px 80px' }}
      >
        <Box>
          <Stack spacing="32px" align="center" maxWidth={{ base: '320px', lg: '480px' }}>
            <Stack w="100%" spacing={{ base: '32px', lg: '48px' }} align="center">
              <TitleText text="Get access to the Fluffy app" />

              <TitleText
                type="SUBTITLE"
                text="As a Fluffy customer, you get access to free training, 24/7 vet and discounts on pet food, treats and more. Download the app on iOS and Android."
              />
            </Stack>

            <ActionButton
              style={{ background: Colors.Brown }}
              onPress={restartQuote}
              label="Insure another pet"
              disabled={isRestartFormButtonDisabled}
            />

            <HStack spacing={{ base: '24px', lg: '56px' }}>
              <GetOnStoreActionButton
                imgSrc={GET_ON_APP_STORE_IMG_SRC}
                qrImgSrc={GET_ON_APP_STORE_QR_IMG_SRC}
                onPress={openAppStoreAppPage}
              />
              <GetOnStoreActionButton
                imgSrc={GET_ON_GOOGLE_PLAY_IMG_SRC}
                qrImgSrc={GET_ON_GOOGLE_PLAY_QR_IMG_SRC}
                onPress={openGooglePlayAppPage}
              />
            </HStack>
          </Stack>
        </Box>

        <Box width="50%" height="50%" display={{ base: 'none', lg: 'block' }}>
          <Image src={APP_SCREENSHOTS_IMG_SRC} width="100%" height="90%" />
        </Box>
      </HStack>

      <Flex flexDirection="column" flex="1" alignItems="center" justifyContent="flex-end" padding="32px 16px">
        <Footer textContent={FOOTER_REMARK} />
      </Flex>
    </Flex>
  );
};

const GetOnStoreActionButton: React.FC<{ imgSrc: string; qrImgSrc: string; onPress: () => void }> =
  function GetOnStoreActionButton({ imgSrc, qrImgSrc, onPress }) {
    return (
      <Stack spacing="16px" align="center">
        <Box as="button" _hover={{ opacity: 0.8 }} onClick={onPress}>
          <Image w="148px" height="46px" src={imgSrc} />
        </Box>

        <Box width="100px" height="100px">
          <Image src={qrImgSrc} width="100%" height="100%" />
        </Box>
      </Stack>
    );
  };
