import { LocalStorageKey } from '~/constants/localStorageKey';

export function getSubmittedQuoteFormAt(): number | null {
  const submittedQuoteFormAtStr = localStorage.getItem(LocalStorageKey.SubmittedQuoteFormAt);
  if (!submittedQuoteFormAtStr) {
    return null;
  }

  const submittedQuoteFormAt = Number(submittedQuoteFormAtStr);
  return isNaN(submittedQuoteFormAt) ? null : submittedQuoteFormAt;
}

export function cleanLocallyStoredQuoteFormProvidedInfo() {
  localStorage.removeItem(LocalStorageKey.SavedQuoteId);
  localStorage.removeItem(LocalStorageKey.SubmittedQuoteFormAt);
}
