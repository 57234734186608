import React from 'react';

import { Box, Stack, Text } from '@chakra-ui/react';

import { ActionButton } from '~/components/common/ActionButton';

import { useQuoteRestart } from '~/hooks/useQuoteRestart';

import { Colors } from '~/constants/colors';

export interface FooterProps {
  isRestartButtonDisabled?: boolean;
  showRestartFormButton?: boolean;
  restartFormButtonLabel?: string;
  textContent?: string;
  restartButtonHandler?: () => void;
}

export interface FooterRemarkProps {
  text?: string;
}

const DEFAULT_FOOTER_REMARK = '';

export const Footer: React.FC<React.PropsWithChildren<FooterProps>> = function Footer({
  showRestartFormButton = false,
  isRestartButtonDisabled = false,
  restartFormButtonLabel,
  textContent,
  restartButtonHandler,
  children,
}) {
  const restartQuote = useQuoteRestart(restartButtonHandler);

  return (
    <Stack spacing={4}>
      {showRestartFormButton && (
        <ActionButton
          onPress={restartQuote}
          label={restartFormButtonLabel ?? 'Start again'}
          isSecondary
          disabled={isRestartButtonDisabled}
          style={{ margin: '0 auto', padding: '4px 8px' }}
        />
      )}

      {children}

      <FooterRemark text={textContent} />
    </Stack>
  );
};

const FooterRemark: React.FC<FooterRemarkProps> = function FooterRemark({ text }) {
  const textToDisplay = text ? text : DEFAULT_FOOTER_REMARK;

  return (
    <Box>
      <Text fontSize={{ base: '10px', lg: '12px' }} lineHeight="16px" fontWeight="400" color={Colors.PaleBrow}>
        {textToDisplay}
      </Text>
    </Box>
  );
};
