export enum ModalTypes {
  ErrorInfo = 'ErrorInfo',
  PerkInfo = 'PerkInfo',
  NotSupportedBreed = 'NotSupportedBreed',
  NotSupportedPetAge = 'NotSupportedPetAge',
  ConfirmPetIsOldEnough = 'ConfirmPetIsOldEnough',
  GeneralConfirmation = 'GeneralConfirmation',
  DiscountCode = 'DiscountCode',
  ReferralLink = 'ReferralLink',
}
