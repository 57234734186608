import React from 'react';
import { Formik } from 'formik';

import { CustomerFormQuestionnaire } from '~/components/customer-form/customer-form-questionnaire/CustomerFormQuestionnaire';

import { CustomerFormValues } from '~/components/customer-form/customer-form.schema';
import { customerFormValidationSchema } from '~/components/customer-form/validation/customer-form.validation';

import { useFilledQuoteFormValues } from '~/hooks/insurance-data-provider/useFilledQuoteFormValues';
import { useCustomerFormSubmitHandler } from '~/hooks/insurance-data-provider/useCustomerFormSubmitHandler';
import { useFilledCustomerFormValues } from '~/hooks/insurance-data-provider/useFilledCustomerFormValues';

import { identify } from '~/helpers/analytics/identify';
import { normalizeIdentityObject } from '~/helpers/analytics/userAnalyticsIdentity';
import { addSentryBreadcrumb, startSentryTransaction } from '~/helpers/monitoring/common';

export const CustomerForm: React.FC = function CustomerForm() {
  const quoteFormData = useFilledQuoteFormValues();
  const customerFormSubmitHandler = useCustomerFormSubmitHandler();
  const customerFormInitValues = useFilledCustomerFormValues();

  const handleFormSubmit = React.useCallback(
    async (values: CustomerFormValues) => {
      const formSubmitTransaction = startSentryTransaction('customer_form_submit_transaction');

      addSentryBreadcrumb({
        category: 'event_breadcrumb',
        message: `[Form "CustomerForm"]: run onSubmit handler with values:`,
        data: values,
        level: 'info',
      });

      const normalizedFormData = normalizeIdentityObject(values);
      await identify(quoteFormData?.email, normalizedFormData);

      await customerFormSubmitHandler(values);

      formSubmitTransaction?.finish();
    },
    [customerFormSubmitHandler, quoteFormData?.email],
  );

  return (
    <Formik
      initialValues={customerFormInitValues}
      onSubmit={handleFormSubmit}
      validationSchema={customerFormValidationSchema}
    >
      <CustomerFormQuestionnaire />
    </Formik>
  );
};
