import { useField } from 'formik';

import { PET_NAME_FIELD } from '~/components/quote-form/quote-form.schema';
import type { QuoteFormValues } from '~/components/quote-form/quote-form.schema';

// be sure you call this hook inside the Formik provider
// otherwise you will have an error
export function useFormikInsuranceFormPetName(): string {
  const [formikField] = useField<QuoteFormValues[typeof PET_NAME_FIELD]>(PET_NAME_FIELD);

  return formikField.value ?? 'pet';
}
