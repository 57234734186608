import { QuoteSchema, StorageQuoteDto } from '@shared/models/product';

export function convertStorageQuoteDtoToQuote(quoteStorageDto: StorageQuoteDto): QuoteSchema {
  const petQuotes = quoteStorageDto.petQuotes.map((petQuote) => {
    return {
      petQuoteId: petQuote.petQuoteId,
      productQuotes: petQuote.productQuotes,
    };
  });

  return {
    quoteId: quoteStorageDto.quoteId,
    petQuotes,
  };
}
