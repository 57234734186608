import { PetPedigree, PetGender, CatOrDog } from '@joinfluffy/common';

import { Title } from '@shared/models/customer';

export type PartialCustomerDto = Omit<CustomerDto, 'firstName' | 'lastName' | 'title' | 'dateOfBirth'>;

export type CustomerDto = {
  firstName: string;
  lastName: string;
  title: Title;
  // 1982-01-22T00:00:00 format
  dateOfBirth: string;
  mobileNumber: string;
  email: string;
  address1: string;
  address2?: string;
  address3?: string;
  postcode: string;
};

export type PetDto = {
  name: string;
  type: CatOrDog;
  pedigree: PetPedigree;
  breedId: string;
  purchaseValue: number;
  isChipped: boolean;
  isNeutered: boolean;
  dateOfBirth: string;
  gender: PetGender;
};

export type PetPolicyDto = {
  pet: PetDto;
  policyStartDate: string;
};

export const MOCK_PLACEHOLDER = 'MOCK_VALUE_PLACEHOLDER';
export const MOCK_MOBILE_NUMBER_PLACEHOLDER = '445555555555';

// This is a special config object to check whether we need to clean the mocked values on the client side.
// In order to do that we check the mockDataControl:
//   1) firstName field to check if we need to clean the main customer info (when the value in the form is equal to the controlValue);
//   2) mobileNumber field to check if we need to clean the mobile number only (in case the user didn't provide the phone number in the 1st form)
// in case we need to clean the data, we set an empty string ('') in the fields, listed in the customerData (and mobileNumber)
// also we handle separately petData.isChipped, because we need to set null
export const MOCKED_DTO_FIELDS: {
  customerData: Array<keyof Omit<CustomerDto, 'title'>>;
  // is used to check, if we need to clean data
  mockDataControl: Record<
    keyof Pick<CustomerDto, 'firstName' | 'mobileNumber' | 'address1'>,
    {
      key: keyof CustomerDto;
      controlValue: string;
    }
  >;
  petData: Array<keyof Pick<PetDto, 'isChipped'>>;
} = {
  customerData: ['firstName', 'lastName', 'dateOfBirth'],
  mockDataControl: {
    firstName: { key: 'firstName', controlValue: MOCK_PLACEHOLDER },
    mobileNumber: { key: 'mobileNumber', controlValue: MOCK_MOBILE_NUMBER_PLACEHOLDER },
    address1: { key: 'address1', controlValue: MOCK_PLACEHOLDER },
  },
  petData: ['isChipped'],
};

export interface ExternalCallPetPolicyDto extends Omit<PetPolicyDto, 'pet'> {
  pet: ExternalCallPetDto;
}

export interface ExternalCallPetDto extends Omit<PetDto, 'breedId'> {
  breedId: number;
  isProfessional: boolean;
  hasShownAggression: boolean;
  hasPastAccident: boolean;
  isOnPremisesWhereAlcoholServed: boolean;
  // we can insure pets even if they are not vaccinated
  isVaccinated: boolean;
}
