export type DisplayPlace = 'QUESTION' | 'BUTTON';

const DISPLAY_PET_NAME_LENGTH_CONFIG: Record<DisplayPlace, number> = {
  QUESTION: 20,
  BUTTON: 10,
};

export function formatPetNameForDisplay(petName: string, displayPlace: DisplayPlace = 'QUESTION') {
  const maxPetNameLength = DISPLAY_PET_NAME_LENGTH_CONFIG[displayPlace];

  if (petName.length < maxPetNameLength) {
    return petName;
  }

  return `${petName.substring(0, maxPetNameLength - 1)}...`;
}
