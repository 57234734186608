import React from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';

import type { ToastSchema } from '~/providers/toaster-provider/toasterProvider.schema';
import { useToaster } from '~/providers/toaster-provider/ToasterProvider';

import { Colors } from '~/constants/colors';

interface ToastProps {
  toast: ToastSchema;
}

export const Toast: React.FC<ToastProps> = ({ toast }) => {
  const { hideToast } = useToaster();

  const [animationClass, setAnimationClass] = React.useState<string>(''); // Initial state without 'appear' or 'disappear'

  React.useEffect(() => {
    setAnimationClass('appear');

    const timeout = setTimeout(() => {
      setAnimationClass('disappear');
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  const hideCurrentToast = React.useCallback(() => hideToast(toast.id), [hideToast, toast.id]);

  const hasTitle = React.useMemo(() => Boolean(toast.title?.trim()), [toast.title]);

  return (
    <Box className={`toast-item ${animationClass}`} onClick={hideCurrentToast}>
      <Stack spacing="2px">
        {hasTitle && (
          <Text textAlign="left" fontSize="16px" lineHeight="24px" color={Colors.White} fontWeight="700">
            {toast.title}
          </Text>
        )}
        <Text textAlign="left" fontSize="14px" lineHeight="20px" color={Colors.White} fontWeight="400">
          {toast.message}
        </Text>
      </Stack>
    </Box>
  );
};
