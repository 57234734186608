import type { CustomerFormQuestionType } from '~/components/customer-form/customer-form.schema';
import type { QuoteFormQuestionType } from '~/components/quote-form/quote-form.schema';

export const QUOTE_FORM_QUESTION_POPOVER_CONTENT_CONFIG: Partial<Record<QuoteFormQuestionType, string>> = {
  PET_BREED:
    'A pedigree is a pet whose parents are of the same breed.\n\nA crossbreed is a pet whose parents are of two different breeds.\n\nA mixed breed is a pet whose parents are of three or more breeds or where the breeds are not known.',
  PET_BIRTH_DATE:
    'To start a new policy, your pet must be older than eight weeks and younger than eight years of age for dogs and younger than ten years of age for cats.\n\n' +
    "When you don't know your pet's exact date of birth, you can enter either the month and year or just the year. Here's how we handle it:\n\n" +
    "- If you provide the month and year, we'll use the first day of that month.\n" +
    "- If you provide just the year, and it's the current year, we'll set it to today minus the minimum pet age in weeks for insurance;\n" +
    "- If you provide just the year, and it's not the current year, we'll use the last day of that year minus the minimum pet age in weeks for insurance;",
  IS_PET_MICROCHIPPED:
    "Since 6 April 2016, all dogs in England, Scotland and Wales must be microchipped. As of that date, owners of dogs and puppies over the age of eight weeks must also have registered their pet's microchip details on one of the authorised databases.",
};

export const CUSTOMER_FORM_QUESTION_POPOVER_CONTENT_CONFIG: Partial<Record<CustomerFormQuestionType, string>> = {
  POLICY_DISPLAY_VIEW:
    'Lifetime Vet Fee cover is for illnesses and injuries. Subject to the underwriter offering renewal and premiums being paid on time with no break in cover, illnesses and injuries will continue to be covered. Pre-existing conditions are excluded from cover. Terms and conditions apply.',
};
