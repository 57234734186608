export enum ExternalPurchaseUrlSearchParams {
  Source = 'source',
  PolicyCode = 'policyCode',
  PolicyNo = 'PolicyNo',
}

export enum InternalUrlSearchParams {
  UtmSource = 'utm_source',
  FluffyReferralId = 'fluffy_referral_id',
}
