import React from 'react';

import { UIStateContext } from '~/providers/UIStateProvider';

export function useUiStateContext() {
  const context = React.useContext(UIStateContext);

  if (!context) {
    // Should be handled during development
    throw new Error('Trying to call "useUiStateContext" without "UIStateProvider"');
  }

  return context;
}
