import { CAT_OR_DOG_FIELD } from '~/components/quote-form/quote-form.schema';

import { useFilledQuoteFormValues } from '~/hooks/insurance-data-provider/useFilledQuoteFormValues';

export function useFilledQuoteFormValuesIsInsuringDog() {
  const filledValues = useFilledQuoteFormValues();

  const containsDogDetails = filledValues.pets.find((pet) => pet[CAT_OR_DOG_FIELD] === 'dog');

  return Boolean(containsDogDetails);
}
