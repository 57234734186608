import React from 'react';
import { Button, Text } from '@chakra-ui/react';

import { ProductBenefitSchema } from '@joinfluffy/common';

import { Currency } from '@shared/constants/currency';

import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';

import { Colors } from '~/constants/colors';

interface PolicyItemProps {
  policyBenefitData?: ProductBenefitSchema;
  isActive?: boolean;
  onPress: () => void;
}

export const PolicyItem: React.FC<PolicyItemProps> = function PolicyItem({ policyBenefitData, isActive, onPress }) {
  if (!policyBenefitData) {
    captureAndLogException('[PolicyItem]: policyBenefitData is not defined', 'Warn');

    return null;
  }

  return (
    <Button
      display="flex"
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      width="102px"
      height="80px"
      _hover={{ opacity: 0.7 }}
      bg={isActive ? Colors.Orange : Colors.White}
      borderRadius="20px"
      onClick={onPress}
    >
      <Text fontSize="14px" lineHeight="15px" fontWeight="400" color={isActive ? Colors.White : Colors.Brown}>
        {policyBenefitData.name}
      </Text>
      <Text fontSize="18px" lineHeight="28px" fontWeight="700" color={isActive ? Colors.White : Colors.Brown}>
        {Currency.GBP}
        {policyBenefitData.amount}
      </Text>
    </Button>
  );
};
