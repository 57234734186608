import React from 'react';

import { Box, Modal, ModalContent, ModalContentProps, ModalFooter, ModalOverlay, Stack } from '@chakra-ui/react';

import { ActionButton } from '~/components/common/ActionButton';

import { trackEvent } from '~/helpers/analytics/trackEvent';

import { Colors } from '~/constants/colors';
import { ModalTypes } from '~/constants/modalTypes';
import { AnalyticsEvent } from '~/constants/analyticsEvent';

import { ReactComponent as CrossIcon } from '~/assets/icons/cross.svg';

interface ModalWrapperProps extends React.PropsWithChildren {
  type: ModalTypes;
  isOpen: boolean;
  actionButtonLabel?: string;
  secondaryActionButtonLabel?: string;
  modalContentStyle?: ModalContentProps;
  onClose: () => void;
  onActionButtonPress?: () => void;
  onSecondaryActionButtonPress?: () => void;
}

export const ModalWrapper: React.FC<ModalWrapperProps> = function ModalWrapper({
  children,
  type,
  isOpen,
  actionButtonLabel,
  secondaryActionButtonLabel,
  modalContentStyle,
  onClose,
  onActionButtonPress,
  onSecondaryActionButtonPress,
}) {
  function onCloseWithEvent() {
    trackEvent({
      eventName: AnalyticsEvent.QuoteModalClose,
      modalType: type,
    });

    onClose();
  }

  function onActionButtonPressWithEvent() {
    trackEvent({
      eventName: AnalyticsEvent.QuoteModalExecutePrimaryAction,
      modalType: type,
    });

    onActionButtonPress && onActionButtonPress();
  }

  function onSecondaryActionButtonPressWithEvent() {
    trackEvent({
      eventName: AnalyticsEvent.QuoteModalExecuteSecondaryAction,
      modalType: type,
    });

    onSecondaryActionButtonPress && onSecondaryActionButtonPress();
  }

  return (
    <Modal isOpen={isOpen} onClose={onCloseWithEvent}>
      <ModalOverlay bg={Colors.DarkOverlay} />
      <ModalContent
        alignItems="center"
        padding="0 24px 12px 24px"
        maxWidth={{ base: '340px', lg: '768px' }}
        minHeight={{ base: '500px', lg: '640px' }}
        bg={Colors.PrimaryBackground}
        borderRadius="32px"
        justifyContent="space-between"
        {...modalContentStyle}
      >
        <Box as="button" position="absolute" top="16px" right="16px" onClick={onCloseWithEvent}>
          <CrossIcon width="24px" height="24px" color={Colors.Brown} opacity={0.48} />
        </Box>

        {children}

        <ModalFooter>
          <Stack marginTop={{ base: '24px', bg: '12px' }} spacing="12px" align="center">
            {actionButtonLabel && onActionButtonPress && (
              <ActionButton
                label={actionButtonLabel}
                onPress={onActionButtonPressWithEvent}
                style={{ width: '295px' }}
              />
            )}
            {secondaryActionButtonLabel && onSecondaryActionButtonPress && (
              <ActionButton
                label={secondaryActionButtonLabel}
                onPress={onSecondaryActionButtonPressWithEvent}
                isSecondary
              />
            )}
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
