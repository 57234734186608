import React from 'react';
import { useField } from 'formik';
import { Wrap, WrapItem } from '@chakra-ui/react';

import { ActionButton } from '~/components/common/ActionButton';
import { TitleText } from '~/components/common/TitleText';
import { QuestionStack } from '~/components/common/QuestionStack';
import { PreferredBenefitCard } from '~/components/questions/PreferredBenefitsQuestion/PreferredBenefitCard/PreferredBenefitCard';
import { PREFERRED_BENEFITS_LIST, QuoteFormCustomerValues } from '~/components/quote-form/quote-form.schema';

import {
  PREFERED_BENEFITS_CODE_CONTENT_CONFIG,
  PreferredBenefitCode,
  PreferredBenefitsCodes,
} from '~/configs/preferredBenefits';

interface PreferredBenefitsQuestionProps {
  onActionButtonClick: () => void;
}

export const PreferredBenefitsQuestion: React.FC<PreferredBenefitsQuestionProps> = function PreferredBenefitsQuestion({
  onActionButtonClick,
}) {
  const [preferredBenefitsListField, , preferredBenefitsListFieldHelpers] =
    useField<QuoteFormCustomerValues[typeof PREFERRED_BENEFITS_LIST]>(PREFERRED_BENEFITS_LIST);

  const getPreferredBenefitCardPressHandler = (code: PreferredBenefitCode) => () => {
    const currentSelectedBenefits = preferredBenefitsListField.value;
    const updatedBenefits = currentSelectedBenefits.includes(code)
      ? currentSelectedBenefits.filter((benefitCode) => benefitCode !== code)
      : [...currentSelectedBenefits, code];

    preferredBenefitsListFieldHelpers.setValue(updatedBenefits);
  };

  return (
    <QuestionStack spacing={{ base: '32px', lg: '48px' }}>
      <TitleText text="Anything else that really interests you about us (pick as many as you like)?" />

      <Wrap
        justify="center"
        spacingX={{ base: '20px', lg: '56px' }}
        spacingY={{ base: '20px', lg: '40px' }}
        overflow="visible"
      >
        {PreferredBenefitsCodes.map((code) => {
          const config = PREFERED_BENEFITS_CODE_CONTENT_CONFIG[code];

          return (
            <WrapItem key={code}>
              <PreferredBenefitCard
                title={config.title}
                iconSrc={config.iconSrc}
                isNotAvailableYet={config.isNotAvailableYet}
                onPress={getPreferredBenefitCardPressHandler(code)}
                isSelected={preferredBenefitsListField.value.includes(code)}
              />
            </WrapItem>
          );
        })}
      </Wrap>
      <ActionButton label="Continue" onPress={onActionButtonClick} />
    </QuestionStack>
  );
};
