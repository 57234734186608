import React from 'react';
import moment from 'moment-timezone';
import { useField } from 'formik';

import { INTERNAL_DATE_FORMAT } from '@joinfluffy/common';

import {
  CAT_MAX_AGE_FOR_INSURANCE_IN_YEARS,
  DOG_MAX_AGE_FOR_INSURANCE_IN_YEARS,
  MAX_POLICY_START_DATE_DAY_COUNT_FROM_TODAY,
} from '@shared/constants/validation';

import { CAT_OR_DOG_FIELD, QuoteFormValues, PET_DATE_OF_BIRTH_FIELD } from '~/components/quote-form/quote-form.schema';

import { getTodaysDate } from '~/helpers/date/date';

export function usePolicyStartDateEnabledOptions() {
  const [petDateOfBirth] = useField<QuoteFormValues[typeof PET_DATE_OF_BIRTH_FIELD]>(PET_DATE_OF_BIRTH_FIELD);
  const [catOrDog] = useField<QuoteFormValues[typeof CAT_OR_DOG_FIELD]>(CAT_OR_DOG_FIELD);

  const insuranceMaxPetAge = React.useMemo(() => {
    const petType = catOrDog.value;
    if (petType === 'dog') {
      return DOG_MAX_AGE_FOR_INSURANCE_IN_YEARS;
    }

    if (petType === 'cat') {
      return CAT_MAX_AGE_FOR_INSURANCE_IN_YEARS;
    }
  }, [catOrDog]);

  const maxInsuranceStartDateFromToday = getTodaysDate().add(MAX_POLICY_START_DATE_DAY_COUNT_FROM_TODAY, 'days');

  const currentPetLastInsuranceStartSupportedDay = moment(petDateOfBirth.value, INTERNAL_DATE_FORMAT)
    .add(insuranceMaxPetAge, 'years')
    .subtract(1, 'day');

  const isTomorrowOptionEnabled = currentPetLastInsuranceStartSupportedDay.isAfter(getTodaysDate());

  const isCalendarOptionEnabled = currentPetLastInsuranceStartSupportedDay.isAfter(getTodaysDate().add(1, 'day'));

  const maxValueToSelect = currentPetLastInsuranceStartSupportedDay.isAfter(maxInsuranceStartDateFromToday)
    ? maxInsuranceStartDateFromToday
    : currentPetLastInsuranceStartSupportedDay;

  return {
    maxValueToSelect,
    isCalendarOptionEnabled,
    isTomorrowOptionEnabled,
  };
}
