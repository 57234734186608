import React, { PropsWithChildren } from 'react';
import { useField, useFormikContext } from 'formik';
import { Checkbox, FormLabel, HStack, Stack, Text } from '@chakra-ui/react';

import { Colors } from '~/constants/colors';

interface CheckBoxWithStatementProps extends PropsWithChildren {
  formikFieldName: string;
  showEmptyTickWarning?: boolean;
}

export const CheckBoxWithStatement: React.FC<CheckBoxWithStatementProps> = function CheckBoxWithStatement({
  children,
  formikFieldName,
  showEmptyTickWarning,
}) {
  const formik = useFormikContext();
  const [field] = useField(formikFieldName);

  const changeFormikFieldValue = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      formik.setFieldValue(formikFieldName, e.target.checked);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.setFieldValue, formikFieldName],
  );

  return (
    <Stack spacing="4px">
      <HStack spacing="16px">
        <Checkbox
          id={`${formikFieldName}`}
          isChecked={field.value}
          onChange={changeFormikFieldValue}
          iconColor={Colors.Orange}
          color={Colors.Orange}
          borderColor={Colors.Orange}
          alignItems="flex-start"
        />

        <Text
          as={FormLabel}
          htmlFor={`${formikFieldName}`}
          textAlign="left"
          fontSize="12px"
          lineHeight="14px"
          color={Colors.Black}
          fontWeight="400"
        >
          {children}
        </Text>
      </HStack>

      {showEmptyTickWarning && (
        <Text textAlign="left" fontSize="12px" lineHeight="16px" color={Colors.Red}>
          You need to confirm the statement
        </Text>
      )}
    </Stack>
  );
};
