// Landing to Quoting attribution search params
export enum LtqSearchParams {
  utmSource = 'ltq-utmSource',
  utmMedium = 'ltq-utmMedium',
  utmCampaign = 'ltq-utmCampaign',
  utmContent = 'ltq-utmContent',
  source = 'ltq-source',
  referrer = 'ltq-referrer',
  fluffyReferralId = 'ltq-fluffyReferralId',
}
