import moment from 'moment-timezone';

import { YYYY_DATE_FORMAT } from '@shared/constants/date';
import { MIN_AGE_FOR_INSURANCE_IN_WEEKS } from '@shared/constants/validation';

import { getTodaysDate } from '~/helpers/date/date';

export const CURRENT_YEAR = moment().year();

// Expected valid for insurance year (today - min age = same year for current year`
export function getFullPetDateOfBirthByYear(year: string) {
  if (isCurrentYear(year)) {
    return getTodaysDate().subtract(MIN_AGE_FOR_INSURANCE_IN_WEEKS, 'week');
  }

  return moment(year, YYYY_DATE_FORMAT).endOf('year').subtract(MIN_AGE_FOR_INSURANCE_IN_WEEKS, 'weeks');
}

export function isCurrentYear(year: string) {
  return moment(year, YYYY_DATE_FORMAT).year() === CURRENT_YEAR;
}

export function isPreviousYear(year: string) {
  return moment(year, YYYY_DATE_FORMAT).add(1, 'year').year() === CURRENT_YEAR;
}
