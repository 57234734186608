import moment from 'moment-timezone';

import { PolicyCodes, findPetBreedById } from '@joinfluffy/common';

import { ProductQuoteSchema, QuoteSchema, StorageQuoteDto } from '@shared/models/product';
import { paymentTypeParam } from '@shared/constants/url';
import { Currency } from '@shared/constants/currency';
import { CustomerDto, MOCKED_DTO_FIELDS, PetPolicyDto } from '@shared/models/dto';
import { PaymentType } from '@shared/constants/paymentType';

import { getCustomerFormValuesStoredLocally } from '~/helpers/quote/locallyStoredCustomerFormValues';
import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';
import { trackAdEvent, trackEvent } from '~/helpers/analytics/trackEvent';
import { InvokeFetchQuoteWithPartialRequestDataApiParams } from '~/helpers/quote/quoteApi/quoteApi.schema';

import { getHttpsCallableFunction } from '~/hooks/useInitFirebase';

import {
  ADDRESS_LINE_1_FIELD,
  CAT_OR_DOG_FIELD,
  CURRENT_QUESTION_STEP_IDX,
  EMAIL_FIELD,
  GENDER_FIELD,
  HAS_ALREADY_INSURANCE_POLICY,
  QuoteFormValues,
  HAS_MULTIPLE_PETS_FIELD,
  IS_MICROCHIPPED_FIELD,
  IS_NEUTERED_FIELD,
  IS_OVER_EIGHTEEN_FIELD,
  LAST_QUESTION_STEP_IDX,
  OPTIONAL_PHONE_NUMBER_FIELD,
  PET_BREED_FIELD,
  PET_DATE_OF_BIRTH_FIELD,
  PET_NAME_FIELD,
  PET_VALUE_FIELD,
  POLICY_START_DATE_FIELD,
  POSTCODE_FIELD,
  CURRENT_PET_IDX_FIELD,
  QuoteFormCustomerValues,
  EXISTING_POLICY_FINISH_DATE,
  ADDRESS_LINE_2_FIELD,
  ADDRESS_LINE_3_FIELD,
  PREFERRED_BENEFITS_LIST,
} from '~/components/quote-form/quote-form.schema';
import {
  ARE_POLICY_AND_BUSINESS_DOCUMENTS_READ_FIELD,
  DATE_OF_OWNER_BIRTH_FIELD,
  FIRST_NAME_FIELD,
  CustomerFormValues,
  LAST_NAME_FIELD,
  PAYMENT_TYPE_FIELD,
  PHONE_NUMBER_FIELD,
  POLICY_CODE_FIELD,
  TITLE_FIELD,
  ARE_ASSUMPTIONS_READ_FIELD,
} from '~/components/customer-form/customer-form.schema';

import { FilledQuoteFormValuesSchema } from '~/providers/insurance-data-provider/types';

import { CloudFunction } from '~/constants/functionName';
import { LocalStorageKey } from '~/constants/localStorageKey';
import { AdEventType, AnalyticsEvent } from '~/constants/analyticsEvent';

export async function fetchQuoteById(quoteId: string): Promise<StorageQuoteDto> {
  const fetchQuoteById = getHttpsCallableFunction(CloudFunction.fetchQuoteById);

  return fetchQuoteById({ quoteId })
    .then((result) => result.data as StorageQuoteDto)
    .catch((e) => {
      captureAndLogException(e, 'Error');

      throw e;
    });
}

export function convertStorageQuoteToFilledQuoteFormValues(quote: StorageQuoteDto): FilledQuoteFormValuesSchema {
  const { customerData, petQuotes } = quote;

  const pets = petQuotes.map(({ petData }) => ({
    [PET_NAME_FIELD]: petData.name,
    [CAT_OR_DOG_FIELD]: petData.type,
    [GENDER_FIELD]: petData.gender,
    [PET_BREED_FIELD]: findPetBreedById(petData.breedId)?.breedName ?? '',
    [IS_NEUTERED_FIELD]: petData.isNeutered,
    [PET_VALUE_FIELD]: `${Currency.GBP}${petData.purchaseValue}`,
    [POSTCODE_FIELD]: customerData.postcode,
    [PET_DATE_OF_BIRTH_FIELD]: petData.dateOfBirth,
    [IS_MICROCHIPPED_FIELD]: petData.isChipped,
  }));

  // TODO [INSR-440] Policy start date is the same for all pets now
  const policyStartDate = petQuotes[0].policyStartDate;

  const customer: QuoteFormCustomerValues = {
    [ADDRESS_LINE_1_FIELD]: customerData.address1,
    [ADDRESS_LINE_2_FIELD]: customerData.address2 ?? '',
    [ADDRESS_LINE_3_FIELD]: customerData.address3 ?? '',
    [POSTCODE_FIELD]: customerData.postcode,
    [EMAIL_FIELD]: customerData.email,
    [HAS_ALREADY_INSURANCE_POLICY]: undefined,
    [IS_OVER_EIGHTEEN_FIELD]: true,
    [POLICY_START_DATE_FIELD]: policyStartDate,
    [OPTIONAL_PHONE_NUMBER_FIELD]: customerData.mobileNumber,
    [CURRENT_QUESTION_STEP_IDX]: LAST_QUESTION_STEP_IDX,
    [HAS_MULTIPLE_PETS_FIELD]: pets.length > 1 ? 'multiple_pets' : 'singe_pet',
    [CURRENT_PET_IDX_FIELD]: 0,
    [EXISTING_POLICY_FINISH_DATE]: null,
    [PREFERRED_BENEFITS_LIST]: [],
  };

  return {
    ...customer,
    pets,
  };
}

export function getCustomerFormValuesFromQuote(
  quote: StorageQuoteDto,
  {
    selectedPolicy,
    needToCleanMockData,
  }: {
    selectedPolicy?: PolicyCodes;
    needToCleanMockData?: boolean;
  },
): CustomerFormValues {
  const { customerData } = quote;

  const locallyStoredCustomerFormValues = getCustomerFormValuesStoredLocally();
  const customerFormValues: CustomerFormValues = {
    [POLICY_CODE_FIELD]: selectedPolicy ?? PolicyCodes.LT2,
    [ARE_POLICY_AND_BUSINESS_DOCUMENTS_READ_FIELD]: false,
    [ARE_ASSUMPTIONS_READ_FIELD]: false,
    [DATE_OF_OWNER_BIRTH_FIELD]: customerData.dateOfBirth,
    [TITLE_FIELD]: needToCleanMockData ? null : customerData.title,
    [FIRST_NAME_FIELD]: customerData.firstName,
    [LAST_NAME_FIELD]: customerData.lastName,
    [PHONE_NUMBER_FIELD]: customerData.mobileNumber,
    [PAYMENT_TYPE_FIELD]: null,
  };

  return {
    ...customerFormValues,
    ...locallyStoredCustomerFormValues,
  };
}

export function getPolicyListFromQuote(quote: StorageQuoteDto): ProductQuoteSchema[] {
  return quote.petQuotes[0].productQuotes;
}

/**
 * Call a Cloud Function that will return quoteData for the particular customer and pet
 * @param customerDto
 * @param petPolicyDto
 * @param options
 */
export const invokeFetchQuoteWithPartialRequestDataApi = async ({
  customerDto,
  petPolicyDtos,
  options,
}: InvokeFetchQuoteWithPartialRequestDataApiParams): Promise<QuoteSchema> => {
  const fetchQuoteWithPartialRequestData = getHttpsCallableFunction(CloudFunction.fetchQuoteWithPartialRequestData);

  return fetchQuoteWithPartialRequestData({
    customer: customerDto,
    petPolicies: petPolicyDtos,
    options,
  }).then((result) => result.data as QuoteSchema);
};

export const invokeFetchPurchaseUrlApi = async (
  customerDto: CustomerDto,
  petPolicyDtos: PetPolicyDto[],
  existingQuoteId: string,
  minimalAnnualPrice: number,
  highestAnnualPrice: number,
  selectedPolicyCode: PolicyCodes,
  paymentType: PaymentType,
): Promise<string> => {
  const fetchPurchaseUrlFunction = getHttpsCallableFunction(CloudFunction.fetchPurchaseUrl);

  return fetchPurchaseUrlFunction({
    customer: customerDto,
    petPolicies: petPolicyDtos,
    existingQuoteId,
    minimalAnnualPrice,
    highestAnnualPrice,
    selectedPolicyCode,
    paymentType,
  }).then((result) => result.data as string);
};

/**
 *  Navigate to partner's purchase URL, with the monthly payment type parameter
 * @param purchaseUrlString
 */
export function navigateToQuotePaymentPage(purchaseUrlString: string, paymentType: PaymentType) {
  const purchaseUrl = new URL(purchaseUrlString);
  purchaseUrl.searchParams.append(paymentTypeParam, paymentType);

  localStorage.setItem(LocalStorageKey.SubmittedCustomerFormAt, moment().valueOf().toString());

  trackAdEvent(AdEventType.AddToCard);
  trackEvent({ eventName: AnalyticsEvent.QuoteNavigateToPartnersURL, redirectUrl: purchaseUrl.toString() });
  window.location.href = purchaseUrl.toString();
}

// Cleans the mock data, if needed, based on mockDataControl info check,
// returns needToCleanMockData: boolean to determine, if we need to clean the additional mock data separately
export function cleanStorageQuoteDtoMockData(storageQuoteDto: StorageQuoteDto): boolean {
  const needToCleanMockData =
    storageQuoteDto.customerData.firstName === MOCKED_DTO_FIELDS.mockDataControl.firstName.controlValue;
  if (needToCleanMockData) {
    MOCKED_DTO_FIELDS.customerData.forEach((keyToClean) => {
      storageQuoteDto.customerData[keyToClean] = '';
    });
  }
  // we need to handle mobile phone number separately
  if (storageQuoteDto.customerData.mobileNumber === MOCKED_DTO_FIELDS.mockDataControl.mobileNumber.controlValue) {
    storageQuoteDto.customerData.mobileNumber = '';
  }

  // we need to handle address1 separately as it lives in Quote form
  if (storageQuoteDto.customerData.address1 === MOCKED_DTO_FIELDS.mockDataControl.address1.controlValue) {
    storageQuoteDto.customerData.address1 = '';
  }

  return needToCleanMockData;
}

export function extractPetDataFromQuoteFormValues(quoteFormValues: QuoteFormValues) {
  return {
    [PET_NAME_FIELD]: quoteFormValues[PET_NAME_FIELD],
    [CAT_OR_DOG_FIELD]: quoteFormValues[CAT_OR_DOG_FIELD],
    [GENDER_FIELD]: quoteFormValues[GENDER_FIELD],
    [PET_BREED_FIELD]: quoteFormValues[PET_BREED_FIELD],
    [IS_NEUTERED_FIELD]: quoteFormValues[IS_NEUTERED_FIELD],
    [PET_VALUE_FIELD]: quoteFormValues[PET_VALUE_FIELD],
    [PET_DATE_OF_BIRTH_FIELD]: quoteFormValues[PET_DATE_OF_BIRTH_FIELD],
    [IS_MICROCHIPPED_FIELD]: quoteFormValues[IS_MICROCHIPPED_FIELD],
  };
}

export function extractCustomerDataFromQuoteFormValues(quoteFormValues: QuoteFormValues | FilledQuoteFormValuesSchema) {
  return {
    [ADDRESS_LINE_1_FIELD]: quoteFormValues[ADDRESS_LINE_1_FIELD],
    [ADDRESS_LINE_2_FIELD]: quoteFormValues[ADDRESS_LINE_2_FIELD],
    [ADDRESS_LINE_3_FIELD]: quoteFormValues[ADDRESS_LINE_3_FIELD],
    [POSTCODE_FIELD]: quoteFormValues[POSTCODE_FIELD],
    [EMAIL_FIELD]: quoteFormValues[EMAIL_FIELD],
    [IS_OVER_EIGHTEEN_FIELD]: quoteFormValues[IS_OVER_EIGHTEEN_FIELD],
    [HAS_ALREADY_INSURANCE_POLICY]: quoteFormValues[HAS_ALREADY_INSURANCE_POLICY],
    [HAS_MULTIPLE_PETS_FIELD]: quoteFormValues[HAS_MULTIPLE_PETS_FIELD],
    [CURRENT_QUESTION_STEP_IDX]: quoteFormValues[CURRENT_QUESTION_STEP_IDX],
    [POLICY_START_DATE_FIELD]: quoteFormValues[POLICY_START_DATE_FIELD],
    [CURRENT_PET_IDX_FIELD]: quoteFormValues[CURRENT_PET_IDX_FIELD],
    [OPTIONAL_PHONE_NUMBER_FIELD]: quoteFormValues[OPTIONAL_PHONE_NUMBER_FIELD],
    [EXISTING_POLICY_FINISH_DATE]: quoteFormValues[EXISTING_POLICY_FINISH_DATE],
    [PREFERRED_BENEFITS_LIST]: quoteFormValues[PREFERRED_BENEFITS_LIST],
  };
}
