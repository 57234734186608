import React from 'react';

import { Link, LinkProps } from '@chakra-ui/react';

export interface ExternalLinkProps extends React.PropsWithChildren {
  url: string;
  style?: LinkProps;
}

export const ExternalLink: React.FC<ExternalLinkProps> = function ExternalLink({ url, children, style }) {
  return (
    <Link href={url} isExternal textDecoration="underline" {...style}>
      {children}
    </Link>
  );
};
