import React, { ReactElement } from 'react';

import { isStepElement, StepProps } from '~/components/stepper/Step';

import type { QuoteFormQuestionType } from '~/components/quote-form/quote-form.schema';
import type { CustomerFormQuestionType } from '~/components/customer-form/customer-form.schema';

import { getValueWithFrameLimit } from '~/helpers/numbers.utils';

interface StepperProps {
  step: number | string;
  questionOrderConfig?: QuoteFormQuestionType[] | CustomerFormQuestionType[];
}

export const Stepper: React.FC<React.PropsWithChildren<StepperProps>> = function Stepper({
  children,
  questionOrderConfig,
  step,
}) {
  const childrenArray = React.Children.toArray(children) as ReactElement<StepProps>[];

  const findIndexWithStringStep = React.useCallback(
    (currentStep: string) => {
      const index = childrenArray.findIndex((child) => isStepElement(child) && child.props.step === currentStep);

      return getValueWithFrameLimit(0, Math.max(childrenArray.length - 1, 0), index);
    },
    [childrenArray],
  );

  const stepIndex = React.useMemo(() => {
    let index;
    if (questionOrderConfig && typeof step === 'number') {
      const currentQuestion = questionOrderConfig[step];

      return findIndexWithStringStep(currentQuestion);
    }
    if (typeof step === 'number') {
      index = step;

      return getValueWithFrameLimit(0, Math.max(childrenArray.length - 1, 0), index);
    }

    return findIndexWithStringStep(step);
  }, [questionOrderConfig, step, childrenArray, findIndexWithStringStep]);

  return <div key={stepIndex}>{childrenArray[stepIndex]}</div>;
};
