import React from 'react';
import { useField } from 'formik';
import { Stack } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';

import { FormikInput } from '~/components/form/formik-input/FormikInput';
import { ScreenWithTitleContainer } from '~/components/common/ScreenWithTitleContainer';
import { EMAIL_FIELD } from '~/components/quote-form/quote-form.schema';

import { useKeyEnterScreenHandler } from '~/hooks/common/useKeyEnterScreenHandler';

export interface EmailQuestionProps {
  openNextStep: () => void;
}

export const EmailQuestion: React.FC<EmailQuestionProps> = function EmailQuestion({ openNextStep }) {
  const [emailField, emailFieldMeta] = useField(EMAIL_FIELD);
  const isActionButtonEnabled = Boolean(emailField.value && !emailFieldMeta.error);

  const setSentryUserAndOpenNextStep = React.useCallback(() => {
    Sentry.setUser({
      email: emailField.value,
    });

    openNextStep();
  }, [emailField.value, openNextStep]);

  useKeyEnterScreenHandler({ actionToCall: setSentryUserAndOpenNextStep, isActionDisabled: !isActionButtonEnabled });

  return (
    <ScreenWithTitleContainer
      title="We’re nearly there! Please enter your email address."
      buttonLabel="Next step"
      isButtonDisabled={!isActionButtonEnabled}
      onActionButtonClick={setSentryUserAndOpenNextStep}
    >
      <Stack spacing="12px">
        <FormikInput name={EMAIL_FIELD} placeholder="Email" type="email" variant="outline" />
      </Stack>
    </ScreenWithTitleContainer>
  );
};
