import {
  BenefitInfo,
  FluffyAppBenefits,
  PerkInfoConfig,
  PolicyBenefits,
  ProductBenefitCode,
  ProductBenefitSchema,
} from '@joinfluffy/common';

export interface ProductBenefitInfo extends BenefitInfo {
  imgSrc: string;
  smallImgSrc: string;
}

// the list of perks, that we don't receive from the CoverMore's API
export const FluffyInternalBenefits: ProductBenefitSchema[] = [
  { name: PolicyBenefits.DentalTreatmentAccidentOnly, code: 'DENTAL_TREATMENT', amount: '750' },
  { name: PolicyBenefits.CtAndMriScans, code: 'CT_AND_MRI_SCAN', amount: '1000' },
];

export const ProductPerkInfoConfig: Record<ProductBenefitCode | FluffyAppBenefits, ProductBenefitInfo> = {
  VET_FEES: {
    ...PerkInfoConfig.VET_FEES,
    imgSrc: '/images/dogImages/08-heart-dog/heart-dog-1080.png',
    smallImgSrc: '/images/dogImages/08-heart-dog/heart-dog-208.png',
  },
  EXCESS: {
    ...PerkInfoConfig.EXCESS,
    imgSrc: '/images/dogImages/01-clever-dog/01-clever-dog-1080.png',
    smallImgSrc: '/images/dogImages/01-clever-dog/01-clever-dog-208.png',
  },
  ACCIDENT_DEATH: {
    ...PerkInfoConfig.ACCIDENT_DEATH,
    imgSrc: '/images/dogImages/20-jumping-dog/20-jumping-dog-1080.png',
    smallImgSrc: '/images/dogImages/20-jumping-dog/20-jumping-dog-200.png',
  },
  THIRD_PARTY_LIABILITY: {
    ...PerkInfoConfig.THIRD_PARTY_LIABILITY,
    imgSrc: '/images/dogImages/23-hazard-dog/23-hazard-dog-1080.png',
    smallImgSrc: '/images/dogImages/23-hazard-dog/23-hazard-dog-208.png',
  },
  DENTAL_TREATMENT: {
    ...PerkInfoConfig.DENTAL_TREATMENT,
    imgSrc: '/images/dogImages/22-barking-dog/22-barking-dog-1080.png',
    smallImgSrc: '/images/dogImages/22-barking-dog/22-barking-dog-208.png',
  },
  CT_AND_MRI_SCAN: {
    ...PerkInfoConfig.CT_AND_MRI_SCAN,
    description:
      'CT, MRI Scans and associated costs are covered up to £1,000 and forms part of the overall vet fee limit.',
    imgSrc: '/images/dogImages/09-dreamer-dog/09-dreamer-dog-1080.png',
    smallImgSrc: '/images/dogImages/09-dreamer-dog/09-dreamer-dog-208.png',
  },
  ILLNESS_DEATH: {
    ...PerkInfoConfig.ILLNESS_DEATH,
    imgSrc: '/images/dogImages/38-dog-bubbles/38-dog-bubbles-1080.png',
    smallImgSrc: '/images/dogImages/38-dog-bubbles/38-dog-bubbles-200.png',
  },
  BOARDING: {
    ...PerkInfoConfig.BOARDING,
    imgSrc: '/images/dogImages/33-lead-handling-dog/33-lead-handling-dog-1080.png',
    smallImgSrc: '/images/dogImages/33-lead-handling-dog/33-lead-handling-dog-208.png',
  },
  THEFT: {
    ...PerkInfoConfig.THEFT,
    imgSrc: '/images/dogImages/04-searching-dog/04-searching-dog-1080.png',
    smallImgSrc: '/images/dogImages/04-searching-dog/04-searching-dog-500.png',
  },
  HOLIDAY_CANCELLATION: {
    ...PerkInfoConfig.HOLIDAY_CANCELLATION,
    imgSrc: '/images/dogImages/25-blanket-dog/blanket-dog-1080.png',
    smallImgSrc: '/images/dogImages/25-blanket-dog/blanket-dog-208.png',
  },
  ADVERTISING: {
    ...PerkInfoConfig.ADVERTISING,
    imgSrc: '/images/dogImages/45-search-dog/45-search-dog-1080.png',
    smallImgSrc: '/images/dogImages/45-search-dog/45-search-dog-208.png',
  },
  PET_PASSPORT: {
    ...PerkInfoConfig.PET_PASSPORT,
    imgSrc: '/images/dogImages/04-searching-dog/04-searching-dog-1080.png',
    smallImgSrc: '/images/dogImages/04-searching-dog/04-searching-dog-500.png',
  },
  OVERSEAS_TRAVEL: {
    ...PerkInfoConfig.OVERSEAS_TRAVEL,
    imgSrc: '/images/dogImages/03-goal-achieving-dog/03-goal-achieving-dog-1080.png',
    smallImgSrc: '/images/dogImages/03-goal-achieving-dog/03-goal-achieving-dog-208.png',
  },
  QUARANTINE: {
    ...PerkInfoConfig.QUARANTINE,
    imgSrc: '/images/dogImages/29-jumping-over-dog/29-jumping-over-dog-1080.png',
    smallImgSrc: '/images/dogImages/29-jumping-over-dog/29-jumping-over-dog-208.png',
  },
  EMERGENCY_ABROAD: {
    ...PerkInfoConfig.EMERGENCY_ABROAD,
    imgSrc: '/images/dogImages/11-welcome-dog/11-welcome-dog-1080.png',
    smallImgSrc: '/images/dogImages/11-welcome-dog/11-welcome-dog-208.png',
  },
  ACCIDENTAL_DAMAGE: {
    ...PerkInfoConfig.ACCIDENTAL_DAMAGE,
    imgSrc: '/images/dogImages/23-hazard-dog/23-hazard-dog-1080.png',
    smallImgSrc: '/images/dogImages/23-hazard-dog/23-hazard-dog-208.png',
  },
  VET_HELPLINE: {
    ...PerkInfoConfig.VET_HELPLINE,
    imgSrc: '/images/dogImages/07-health-dog/07-health-dog-1080.png',
    smallImgSrc: '/images/dogImages/07-health-dog/07-health-dog-208.png',
  },
  [FluffyAppBenefits.FreeDogTraining]: {
    ...PerkInfoConfig[FluffyAppBenefits.FreeDogTraining],
    imgSrc: '/images/dogImages/01-clever-dog/01-clever-dog-1080.png',
    smallImgSrc: '/images/dogImages/01-clever-dog/01-clever-dog-208.png',
  },
  [FluffyAppBenefits.VetAndBehaviouristSupport]: {
    ...PerkInfoConfig[FluffyAppBenefits.VetAndBehaviouristSupport],
    imgSrc: '/images/dogImages/07-health-dog/07-health-dog-1080.png',
    smallImgSrc: '/images/dogImages/07-health-dog/07-health-dog-208.png',
  },
  [FluffyAppBenefits.FoodDiscount]: {
    ...PerkInfoConfig[FluffyAppBenefits.FoodDiscount],
    imgSrc: '/images/dogImages/14-eating-dog/14-eating-dog-1080.png',
    smallImgSrc: '/images/dogImages/14-eating-dog/14-eating-dog-208.png',
  },
  [FluffyAppBenefits.AccessToExclusiveCommunity]: {
    ...PerkInfoConfig[FluffyAppBenefits.AccessToExclusiveCommunity],
    imgSrc: '/images/dogImages/09-dreamer-dog/09-dreamer-dog-1080.png',
    smallImgSrc: '/images/dogImages/09-dreamer-dog/09-dreamer-dog-208.png',
  },
  [FluffyAppBenefits.FreePersonalizedPuppyGrowthPlans]: {
    ...PerkInfoConfig[FluffyAppBenefits.FreePersonalizedPuppyGrowthPlans],
    imgSrc: '/images/dogImages/03-goal-achieving-dog/03-goal-achieving-dog-1080.png',
    smallImgSrc: '/images/dogImages/03-goal-achieving-dog/03-goal-achieving-dog-208.png',
  },
  [FluffyAppBenefits.DiscountOnPetServices]: {
    ...PerkInfoConfig[FluffyAppBenefits.DiscountOnPetServices],
    imgSrc: '/images/dogImages/33-lead-handling-dog/33-lead-handling-dog-1080.png',
    smallImgSrc: '/images/dogImages/33-lead-handling-dog/33-lead-handling-dog-208.png',
  },
};

export const AppPerkImageConfig: Record<FluffyAppBenefits, string> = {
  [FluffyAppBenefits.FreeDogTraining]: '/images/dogImages/01-clever-dog/01-clever-dog-208.png',
  [FluffyAppBenefits.VetAndBehaviouristSupport]: '/images/dogImages/07-health-dog/07-health-dog-208.png',
  [FluffyAppBenefits.FoodDiscount]: '/images/dogImages/14-eating-dog/14-eating-dog-208.png',
  [FluffyAppBenefits.AccessToExclusiveCommunity]: '/images/dogImages/09-dreamer-dog/09-dreamer-dog-208.png',
  [FluffyAppBenefits.FreePersonalizedPuppyGrowthPlans]:
    '/images/dogImages/03-goal-achieving-dog/03-goal-achieving-dog-208.png',
  [FluffyAppBenefits.DiscountOnPetServices]: '/images/dogImages/33-lead-handling-dog/33-lead-handling-dog-208.png',
};
