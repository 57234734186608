import React from 'react';
import { useField } from 'formik';
import { Stack, Text } from '@chakra-ui/react';

import { PetAgeQuestionProvider } from '~/components/questions/pet-age-question/PetAgeQuestionContext';
import { ActionButton } from '~/components/common/ActionButton';
import { QuoteFormValues, PET_DATE_OF_BIRTH_FIELD } from '~/components/quote-form/quote-form.schema';
import { DateInputQuestion } from '~/components/questions/DateInputQuestion';
import { ChooseOptionItem } from '~/components/questions/ChooseOptionItem';

import { useFullDateOfBirthTransformation } from '~/components/questions/pet-age-question/useFullDateOfBirthTransformation';

import { useFormikInsuranceFormDisplayFormatPetName } from '~/hooks/useFormikInsuranceFormDisplayFormatPetName';
import { usePetBirthDateMissedFields } from '~/hooks/quote-form/usePetBirthDateMissedFields';
import { usePetDateOfBirthSubmitHandler } from '~/hooks/quote-form/usePetDateOfBirthSubmitHandler';
import { useQuoteFormBackPressHandler } from '~/hooks/useQuoteFormBackPressHandler';

import type { CalendarUnitsToPick } from '~/constants/calendar';
import { Colors } from '~/constants/colors';

import '~/components/questions/pet-age-question/PetAgeQuestion.scss';

interface PetAgeQuestionProps {
  actionButtonLabel: string;
  onActionButtonClick: () => void;
}

export const PetAgeQuestion: React.FC<PetAgeQuestionProps> = function PetAgeQuestion(props) {
  return (
    <PetAgeQuestionProvider>
      <PetAgeQuestionInner {...props} />
    </PetAgeQuestionProvider>
  );
};

const PetAgeQuestionInner: React.FC<PetAgeQuestionProps> = function PetAgeQuestion({
  actionButtonLabel,
  onActionButtonClick,
}) {
  const { isDdMissed, setIsDdMissed, isDdMmMissed, setIsDdMmMissed } = usePetBirthDateMissedFields();
  const [isIncompleteDate, setIsIncompleteDate] = React.useState<boolean>(isDdMissed || isDdMmMissed);

  useFullDateOfBirthTransformation({ setIsIncompleteDate });

  const [petDateOfBirthField, , petDateOfBirthHelper] =
    useField<QuoteFormValues[typeof PET_DATE_OF_BIRTH_FIELD]>(PET_DATE_OF_BIRTH_FIELD);

  // don't need to wrap with useCallback, because we have Formik helper as a dependency
  const cleanIncompleteDateData = () => {
    // reset form's flag with missed data
    setIsDdMissed(false);
    setIsDdMmMissed(false);

    petDateOfBirthHelper.setValue('');
  };

  // don't need to wrap with useCallback, because depends on cleanIncompleteDateData
  const openFullDoBInput = () => {
    cleanIncompleteDateData();
    setIsIncompleteDate(false);
  };

  // don't need to wrap with useCallback, because depends on cleanIncompleteDateData
  const toggleDateIncomplete = () => {
    cleanIncompleteDateData();
    setIsIncompleteDate((prevState) => !prevState);
  };

  useQuoteFormBackPressHandler({
    shouldExecuteCustomHandler: isIncompleteDate && !petDateOfBirthField.value,
    customHandler: openFullDoBInput,
  });

  const incompleteDateSwitcher = (
    <ActionButton
      label={isIncompleteDate ? `I know the exact date of birth` : `I don't know the exact date of birth`}
      onPress={toggleDateIncomplete}
      isSecondary
    />
  );

  return (
    <Stack spacing={2} className="pet-age-question">
      {isIncompleteDate ? (
        <IncompleteDatePetAgeQuestion actionButtonLabel={actionButtonLabel} onActionButtonClick={onActionButtonClick}>
          <>
            <Stack spacing="24px">
              <Text textAlign="center" fontSize="13px" lineHeight="16px" color={Colors.Grey500}>
                Any missing date components (such as day or month) will be automatically filled in according to the
                guidelines provided in the information popup at the top-right corner of the page.
              </Text>
              {incompleteDateSwitcher}
            </Stack>
          </>
        </IncompleteDatePetAgeQuestion>
      ) : (
        <PetAgeInput unitsToPick="day" actionButtonLabel={actionButtonLabel} onActionButtonClick={onActionButtonClick}>
          {incompleteDateSwitcher}
        </PetAgeInput>
      )}
    </Stack>
  );
};

const IncompleteDatePetAgeQuestion: React.FC<React.PropsWithChildren<PetAgeQuestionProps>> =
  function IncompleteDatePetAgeQuestion({ actionButtonLabel, onActionButtonClick, children }) {
    const { isDdMissed, setIsDdMissed, isDdMmMissed, setIsDdMmMissed } = usePetBirthDateMissedFields();

    const pickKnowMmYyyyOnly = React.useCallback(() => {
      setIsDdMissed(true);
      setIsDdMmMissed(false);
    }, [setIsDdMissed, setIsDdMmMissed]);

    const pickKnowYyyyOnly = React.useCallback(() => {
      setIsDdMissed(false);
      setIsDdMmMissed(true);
    }, [setIsDdMissed, setIsDdMmMissed]);

    // user hasn't picked what piece of info they know
    if (!isDdMissed && !isDdMmMissed) {
      return (
        <Stack spacing="24px" alignItems="center">
          <Stack spacing={2} alignItems="center">
            <ChooseOptionItem label="I know the month and year" onSelect={pickKnowMmYyyyOnly} />
            <ChooseOptionItem label="I only know the year" onSelect={pickKnowYyyyOnly} />
          </Stack>
          {children}
        </Stack>
      );
    }

    const unitToPick: CalendarUnitsToPick = isDdMmMissed
      ? // user knows YYYY only
        'year'
      : // user knows MM/YYYY only
        'month';

    return (
      <PetAgeInput
        unitsToPick={unitToPick}
        actionButtonLabel={actionButtonLabel}
        onActionButtonClick={onActionButtonClick}
      >
        {children}
      </PetAgeInput>
    );
  };

const PetAgeInput: React.FC<
  React.PropsWithChildren<
    {
      unitsToPick: CalendarUnitsToPick;
    } & PetAgeQuestionProps
  >
> = function PetAgeInput({ unitsToPick, children, actionButtonLabel, onActionButtonClick }) {
  const petName = useFormikInsuranceFormDisplayFormatPetName();

  const { onDateInputSubmit } = usePetDateOfBirthSubmitHandler({ onActionButtonClick });

  return (
    <DateInputQuestion
      name={PET_DATE_OF_BIRTH_FIELD}
      unitsToPick={unitsToPick}
      questionText={`What's ${petName}'s date of birth?`}
      actionButtonLabel={actionButtonLabel}
      onActionButtonClick={onDateInputSubmit}
    >
      {children}
    </DateInputQuestion>
  );
};
