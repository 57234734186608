import { Currency } from '@shared/constants/currency';

export function currencyFormatter(input: string) {
  return addPrefixToInput(Currency.GBP, input).replace(/(?!^£)[^\d]+/g, '');
}

export function uppercaseFormatter(input: string): string {
  return input.toUpperCase();
}

function addPrefixToInput(prefix: string, input: string) {
  if (input.length < prefix.length) {
    return prefix;
  }

  return `${prefix}${input.startsWith(prefix) ? input.substring(prefix.length) : input}`;
}
