import React from 'react';

export function useKeyEnterScreenHandler(params?: { actionToCall?: () => void; isActionDisabled?: boolean }) {
  const isActionCalled = React.useRef<boolean>(false);

  const { actionToCall, isActionDisabled = false } = params || {};

  const keyboardEventHandler = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.key !== 'Enter') {
        return;
      }

      if (isActionDisabled || !actionToCall || isActionCalled.current) {
        return;
      }

      actionToCall();

      isActionCalled.current = true;
    },
    [actionToCall, isActionCalled, isActionDisabled],
  );

  React.useEffect(() => {
    document.addEventListener('keydown', keyboardEventHandler);

    return () => {
      document.removeEventListener('keydown', keyboardEventHandler);
    };
  }, [keyboardEventHandler]);
}
