import React from 'react';
import { ProductBenefitCode } from '@joinfluffy/common';

import { BaseModal } from '~/components/common/BaseModal';

import { ModalTypes } from '~/constants/modalTypes';

import { ProductPerkInfoConfig } from '~/configs/perkInfoConfig';

interface ErrorInfoModalProps {
  isOpen: boolean;
  code: ProductBenefitCode;
  onClose: () => void;
}

export const PerkInfoModal: React.FC<ErrorInfoModalProps> = function PerkInfoModal({ isOpen, code, onClose }) {
  const { title, description, imgSrc } = ProductPerkInfoConfig[code];

  return (
    <BaseModal
      type={ModalTypes.PerkInfo}
      isOpen={isOpen}
      imgSrc={imgSrc}
      title={title}
      description={description}
      onClose={onClose}
      actionButtonLabel="Close"
      onActionButtonPress={onClose}
    />
  );
};
