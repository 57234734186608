import React from 'react';
import { useField } from 'formik';

import { INTERNAL_DATE_FORMAT } from '@joinfluffy/common';

import { DEFAULT_DD_PREFIX } from '@shared/constants/date';

import { CAT_OR_DOG_FIELD, PET_DATE_OF_BIRTH_FIELD, QuoteFormValues } from '~/components/quote-form/quote-form.schema';
import {
  getIsPetTooYoungByYear,
  getShouldConfirmPetIsOverEightWeeks,
} from '~/components/questions/pet-age-question/petAge.helpers';

import { useInvokeModal } from '~/hooks/useInvokeModal';
import { usePetBirthDateMissedFields } from '~/hooks/quote-form/usePetBirthDateMissedFields';

import { getFullPetDateOfBirthByYear } from '~/helpers/pet/petDateOfBirth';

import { ModalTypes } from '~/constants/modalTypes';

// todo [INSR-40]: add unit tests
//  also need to have a Cypress test to be sure this place is safe even if we changed format of data
export function useFullDateOfBirthTransformation({
  setIsIncompleteDate,
}: {
  setIsIncompleteDate: (nextValue: boolean) => void;
}) {
  const { invokeModal } = useInvokeModal();
  const { isDdMissed, setIsDdMissed, isDdMmMissed, setIsDdMmMissed } = usePetBirthDateMissedFields();

  const [catOrDogFormikField] = useField<QuoteFormValues[typeof CAT_OR_DOG_FIELD]>(CAT_OR_DOG_FIELD);
  const [petDateOfBirthField, , petDateOfBirthHelper] =
    useField<QuoteFormValues[typeof PET_DATE_OF_BIRTH_FIELD]>(PET_DATE_OF_BIRTH_FIELD);

  const isValueUpdatingRef = React.useRef<boolean>(false);

  // don't need to wrap with useCallback, because we have Formik helper as a dependency
  const setFullDateBasedOnYear = () => {
    // user provided only YYYY of their pet's date of birth
    // we set the pet age as `today - 8 weeks`
    isValueUpdatingRef.current = true;
    petDateOfBirthHelper.setValue(getFullPetDateOfBirthByYear(petDateOfBirthField.value).format(INTERNAL_DATE_FORMAT));
    setTimeout(() => {
      setIsDdMmMissed(false);
      setIsIncompleteDate(false);
      isValueUpdatingRef.current = false;
    }, 0);
  };

  // listen to DoB changes
  React.useEffect(() => {
    if (isValueUpdatingRef.current) {
      return;
    }

    if (!petDateOfBirthField.value || (!isDdMissed && !isDdMmMissed)) {
      // do nothing if the value is empty, or we have full DoB format
      return;
    }

    if (isDdMissed) {
      // user provided only MM/YYYY of their pet's date of birth
      // we set the 1st date of the selected month
      isValueUpdatingRef.current = true;
      petDateOfBirthHelper.setValue(`${DEFAULT_DD_PREFIX}${petDateOfBirthField.value}`);
      setTimeout(() => {
        setIsDdMissed(false);
        setIsIncompleteDate(false);
        isValueUpdatingRef.current = false;
      }, 0);
    }

    if (isDdMmMissed) {
      // user provided only YYYY of their pet's date of birth
      const isPetTooYoung = getIsPetTooYoungByYear(petDateOfBirthField.value);
      const shouldConfirmPetIsOverEightWeeks = getShouldConfirmPetIsOverEightWeeks(petDateOfBirthField.value);

      if (isPetTooYoung) {
        invokeModal(ModalTypes.NotSupportedPetAge, {
          petTypeValue: catOrDogFormikField.value,
          isPetTooYoung,
          isPetTooOld: false,
        });

        return;
      }

      // the date of birth is fine, but the user selected current or previous year only,
      // so they need to confirm the pet is old enough
      if (shouldConfirmPetIsOverEightWeeks) {
        invokeModal(ModalTypes.ConfirmPetIsOldEnough, {
          petTypeValue: catOrDogFormikField.value,
          confirmationHandler: setFullDateBasedOnYear,
          rejectionHandler: () => petDateOfBirthHelper.setValue(''),
        });

        return;
      }

      setFullDateBasedOnYear();
    }

    // Don't need to have Formik helper in deps, not to trigger the effect on its change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petDateOfBirthField.value, isDdMissed, isDdMmMissed]);
}
