import moment from 'moment-timezone';
import * as yup from 'yup';

import { INTERNAL_DATE_FORMAT } from '@joinfluffy/common';

import { titles } from '@shared/models/customer';
import { DD_MM_YYYY_REGEXP } from '@shared/constants/regexp';
import {
  DEFAULT_MAX_STRING_LENGTH,
  PHONE_NUMBER_MAX_STRING_LENGTH,
  PHONE_NUMBER_MIN_STRING_LENGTH,
  POLICY_OWNER_MAX_AGE_IN_YEARS,
  POLICY_OWNER_MIN_AGE_IN_YEARS,
} from '@shared/constants/validation';

import {
  DATE_OF_OWNER_BIRTH_FIELD,
  FIRST_NAME_FIELD,
  LAST_NAME_FIELD,
  PHONE_NUMBER_FIELD,
  TITLE_FIELD,
} from '~/components/customer-form/customer-form.schema';

import { getTodaysDate } from '~/helpers/date/date';

export const customerFormValidationSchema = yup.object({
  [DATE_OF_OWNER_BIRTH_FIELD]: yup
    .string()
    .trim()
    .required('Please enter your date of birth')
    .matches(DD_MM_YYYY_REGEXP, 'Date format should be: DD/MM/YYYY')
    .test('Check that string is a valid date', 'Please enter valid date of birth', (value?: string) => {
      const dateOfBirth = moment(value, INTERNAL_DATE_FORMAT);

      return dateOfBirth.isValid() && !dateOfBirth.isAfter(getTodaysDate());
    })
    .test('Check that user is not older then 200', 'Please enter valid date of birth', (value?: string) =>
      moment(value, INTERNAL_DATE_FORMAT).isAfter(getTodaysDate().subtract(POLICY_OWNER_MAX_AGE_IN_YEARS, 'years')),
    )
    .test('Check that user is older than 18', 'You need to be over 18 to purchase the policy', (value?: string) =>
      moment(value, INTERNAL_DATE_FORMAT).isSameOrBefore(
        getTodaysDate().subtract(POLICY_OWNER_MIN_AGE_IN_YEARS, 'years'),
      ),
    ),
  [TITLE_FIELD]: yup.string().trim().nullable().oneOf(titles).required('Please choose your title'),
  [FIRST_NAME_FIELD]: yup
    .string()
    .trim()
    .max(DEFAULT_MAX_STRING_LENGTH, `First name should not be longer than ${DEFAULT_MAX_STRING_LENGTH} symbols`)
    .required('Please provide your first name'),
  [LAST_NAME_FIELD]: yup
    .string()
    .trim()
    .max(DEFAULT_MAX_STRING_LENGTH, `Last name should not be longer than ${DEFAULT_MAX_STRING_LENGTH} symbols`)
    .required('Please provide your last name'),
  [PHONE_NUMBER_FIELD]: yup
    .string()
    .trim()
    .min(PHONE_NUMBER_MIN_STRING_LENGTH, `Phone number should have at least ${PHONE_NUMBER_MIN_STRING_LENGTH} symbols`)
    .max(
      PHONE_NUMBER_MAX_STRING_LENGTH,
      `Phone number should not be longer than ${PHONE_NUMBER_MAX_STRING_LENGTH} symbols`,
    )
    .required('Please provide your phone number'),
});
