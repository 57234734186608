import React from 'react';

import {
  AddressLookupResponse,
  fetchAddressByLookupId,
} from '~/components/questions/lookup-address-question/addressLookup';

import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';

import { useInvokeErrorModal } from '~/hooks/useInvokeErrorModal';

export function useAddressLookup() {
  const { invokeErrorModal } = useInvokeErrorModal();
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchAddress = React.useCallback(
    async (lookupId: string) => {
      let result: AddressLookupResponse | null = null;
      try {
        setIsLoading(true);
        result = await fetchAddressByLookupId(lookupId);
      } catch (err) {
        const errorMessage = `Error while fetching address by lookup id: "${JSON.stringify(err)}"`;
        captureAndLogException(errorMessage, 'Error');
        invokeErrorModal({
          error: err instanceof Error ? err : new Error(errorMessage),
        });
      } finally {
        setIsLoading(false);
      }

      return result;
    },
    [invokeErrorModal],
  );

  return {
    fetchAddress,
    isLoading,
  };
}
