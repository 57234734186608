import { INTERNAL_DATE_FORMAT } from '@joinfluffy/common';

import { getTodaysDate, isStringValidDate, getDateStartOfDay, getUTCDateISOFormat } from '~/helpers/date/date';
import { trackEvent } from '~/helpers/analytics/trackEvent';

import { AnalyticsEvent } from '~/constants/analyticsEvent';

interface OptionsArgument {
  callbackAfterChanges?: () => void; // will be called in case at least one record was changed
}

// if the policy start date is valid but before today -> set today date
// (for example, when the user selected a date, then closed the web-page and reopened it to continue the flow)
export function ensurePolicyStartDateIsNotInPast(
  petPolicies: { policyStartDate: string }[],
  options?: OptionsArgument,
) {
  let changesCount = 0;

  petPolicies.forEach((petPolicy) => {
    if (
      isStringValidDate(petPolicy.policyStartDate) &&
      getDateStartOfDay(petPolicy.policyStartDate).isBefore(getTodaysDate())
    ) {
      // trackEvent should be first to contain the original data before changes
      trackEvent({
        eventName: AnalyticsEvent.QuotePolicyStartDateWasInThePast,
        dateValue: getUTCDateISOFormat(petPolicy.policyStartDate),
      });

      changesCount++;
      petPolicy.policyStartDate = getTodaysDate().format(INTERNAL_DATE_FORMAT);
    }
  });

  if (changesCount && options?.callbackAfterChanges) {
    options.callbackAfterChanges();
  }

  return Boolean(changesCount);
}
