import React from 'react';
import { Formik, useField, useFormikContext } from 'formik';

import { Box } from '@chakra-ui/react';

import type { PetGender } from '@joinfluffy/common';

import { Header } from '~/components/common/Header';
import { Stepper } from '~/components/stepper/Stepper';
import { Step } from '~/components/stepper/Step';
import { BinaryQuestion } from '~/components/questions/BinaryQuestion';
import { BreedQuestion } from '~/components/questions/BreedQuestion';
import { PetAgeQuestion } from '~/components/questions/pet-age-question/PetAgeQuestion';
import { PetValueQuestion } from '~/components/questions/PetValueQuestion';
import { LookupAddressQuestion } from '~/components/questions/lookup-address-question/LookupAddressQuestion';
import { TextInputQuestion } from '~/components/questions/TextInputQuestion';
import { PolicyStartDateQuestionView } from '~/components/quote-form/policy-start-date-question-view/PolicyStartDateQuestionView';
import { EmailQuestion } from '~/components/questions/EmailQuestion';
import { OptionalPhoneNumberQuestion } from '~/components/customer-form/insurance-phone-number-question/OptionalPhoneNumberQuestion';
import { PetTypeQuestion } from '~/components/questions/PetTypeQuestion';
import { MultiPetQuestion } from '~/components/questions/MultiPetQuestion';
import { HasAlreadyAPolicyQuestion } from '~/components/questions/HasAlreadyAPolicyQuestion';
import { DateInputQuestion } from '~/components/questions/DateInputQuestion';
import { PreferredBenefitsQuestion } from '~/components/questions/PreferredBenefitsQuestion/PreferredBenefitsQuestion';

import { quoteFormValidationSchema } from '~/components/quote-form/validation/quote-form.validation';

import {
  CAT_OR_DOG_FIELD,
  CURRENT_QUESTION_STEP_IDX,
  EMAIL_FIELD,
  GENDER_FIELD,
  QuoteFormQuestionType,
  QuoteFormValues,
  IS_MICROCHIPPED_FIELD,
  IS_NEUTERED_FIELD,
  IS_OVER_EIGHTEEN_FIELD,
  LAST_QUESTION_STEP_IDX,
  PET_BREED_FIELD,
  PET_NAME_FIELD,
  quoteFormPetQuestions,
  CURRENT_PET_IDX_FIELD,
  QuoteFormPetQuestionType,
  EXISTING_POLICY_FINISH_DATE,
} from './quote-form.schema';

import { useFormikInsuranceFormDisplayFormatPetName } from '~/hooks/useFormikInsuranceFormDisplayFormatPetName';
import { useUiStateContextFormBackButtonPressHandler } from '~/hooks/useUiStateContextFormBackButtonPressHandler';
import { useFormikInstrumentation } from '~/hooks/formik-common/useFormikInstrumentation';
import { useInvokeErrorModal } from '~/hooks/useInvokeErrorModal';
import { useCurrentQuestionInstrumentation } from '~/hooks/formik-common/useCurrentQuestionInstrumentation';
import { useFormikOnSubmitValidationErrorHandling } from '~/hooks/formik-common/useFormikOnSubmitValidationErrorHandling';
import { useQuoteFormSubmitHandler } from '~/hooks/insurance-data-provider/useQuoteFormSubmitHandler';

import { useFilledQuoteFormValuesCurrentPetFormValues } from '~/hooks/insurance-data-provider/useFilledQuoteFormValuesCurrentPetFormValues';
import { useFillingMultipetDataHelpers } from '~/hooks/insurance-data-provider/useFillingMultipetDataHelpers';
import { useQuoteFormPreSubmitValidationHandler } from '~/hooks/quote-form/useQuoteFormPreSubmitValidationHandler';

import { debounce } from '~/helpers/common';
import { identify } from '~/helpers/analytics/identify';
import { trackAdEvent, trackEvent, trackEventAndIdentify } from '~/helpers/analytics/trackEvent';
import {
  getCurrentQuoteFormQuestionTraits,
  getUserQuoteFormEmail,
  isSingleFieldQuestion,
  normalizeIdentityObject,
} from '~/helpers/analytics/userAnalyticsIdentity';
import { addSentryBreadcrumb, startSentryTransaction } from '~/helpers/monitoring/common';

import { AdEventType, AnalyticsEvent } from '~/constants/analyticsEvent';
import { NOT_MICROCHIPPED_ERROR_MESSAGE, OWNER_UNDER_EIGHTEEN_ERROR_MESSAGE } from '~/constants/errorMessage';
import { AnalyticsEventFields } from '~/constants/analyticsEventField';

import { QUOTE_FORM_TRAIT_FIELDS_CONFIG, QUOTE_FORM_TRACKING_EVENTS_CONFIG } from '~/configs/tracking';
import {
  GetQuoteFormStepIdxToOpenCurrentBinaryAnswerParam,
  QUOTE_FORM_QUESTION_ORDER_CONFIG,
  getQuoteFormStepIdxToOpen,
} from '~/configs/questionsOrder/questionsOrder';
import { QUOTE_FORM_QUESTION_POPOVER_CONTENT_CONFIG } from '~/configs/questionsPopoverContent';

import '~/components/quote-form/QuoteForm.scss';

const FIRST_QUESTION_IDX = 0;
const DEBOUNCE_TIMEOUT = 300;

const FORM_LABEL = 'quote_form';

export interface TrackAnalyticsParams {
  currentQuestionType: QuoteFormQuestionType;
  currentQuestionTraits?: object;
  skipIdentify?: boolean;
}

export const QuoteForm: React.FC = function QuoteForm() {
  const initialFormValues = useFilledQuoteFormValuesCurrentPetFormValues();
  const formSubmitHandler = useQuoteFormSubmitHandler();

  const handleSubmit = React.useCallback(
    async (values: QuoteFormValues) => {
      const formSubmitTransaction = startSentryTransaction('quote_form_submit_transaction');

      addSentryBreadcrumb({
        category: 'event_breadcrumb',
        message: `[Form ${FORM_LABEL}"]: run onSubmit handler with values: `,
        data: { values },
        level: 'info',
      });

      const identityValues = normalizeIdentityObject(values);
      // don't need to wait for the identify() Promise to be resolved, not to block the main flow
      identify(values[EMAIL_FIELD], identityValues);

      await formSubmitHandler(values);

      formSubmitTransaction?.finish();
    },
    [formSubmitHandler],
  );

  React.useEffect(() => {
    trackAdEvent(AdEventType.Lead);

    trackEvent({ eventName: AnalyticsEvent.QuoteQuotingOpen });
  }, []);

  return (
    <Formik initialValues={initialFormValues} validationSchema={quoteFormValidationSchema} onSubmit={handleSubmit}>
      <QuoteFormSteps />
    </Formik>
  );
};

const QuoteFormSteps = function QuoteFormSteps() {
  useFormikInstrumentation(FORM_LABEL);
  useFormikOnSubmitValidationErrorHandling(FORM_LABEL);

  const formik = useFormikContext<QuoteFormValues>();

  const formikCurrentQuestionStepIdx = formik.values[CURRENT_QUESTION_STEP_IDX];

  useCurrentQuestionInstrumentation(QUOTE_FORM_QUESTION_ORDER_CONFIG[formikCurrentQuestionStepIdx]);

  // we use this ref for all our debounced handler
  const isCurrentStepInProgress = React.useRef<boolean>(false);

  const [catOrDogFormikField] = useField<QuoteFormValues[typeof CAT_OR_DOG_FIELD]>(CAT_OR_DOG_FIELD);
  const [currentPetIdxField] = useField<QuoteFormValues[typeof CURRENT_PET_IDX_FIELD]>(CURRENT_PET_IDX_FIELD);

  const email = getUserQuoteFormEmail(formik);

  const { invokeErrorModal } = useInvokeErrorModal();

  const backButtonPressHandler = useUiStateContextFormBackButtonPressHandler();
  const { pets } = useFillingMultipetDataHelpers();

  const trackAnalytics = ({ currentQuestionType, currentQuestionTraits, skipIdentify }: TrackAnalyticsParams) => {
    const eventName = QUOTE_FORM_TRACKING_EVENTS_CONFIG[currentQuestionType];
    trackEventAndIdentify({ eventName, email, traits: currentQuestionTraits, skipIdentify });
  };

  const setCurrentPetIdxToTraits = (
    currentQuestionType: QuoteFormQuestionType,
    traits?: Record<string, unknown> | { [x: string]: string | boolean },
  ) => {
    if (traits && quoteFormPetQuestions.includes(currentQuestionType as QuoteFormPetQuestionType)) {
      traits[AnalyticsEventFields.QuotingPetIdx] = currentPetIdxField.value;
    }
  };

  const { verifyQuoteFormPreSubmitStateValidOrShowErrorModal } = useQuoteFormPreSubmitValidationHandler();

  const openNextQuestion =
    ({ currentQuestionType }: { currentQuestionType: QuoteFormQuestionType }) =>
    (answerValue?: string) => {
      const { fieldName: fields, shouldTrackToIdentity } = QUOTE_FORM_TRAIT_FIELDS_CONFIG[currentQuestionType];

      const traits = getCurrentQuoteFormQuestionTraits([...fields], formik, answerValue);

      setCurrentPetIdxToTraits(currentQuestionType, traits);

      trackAnalytics({ currentQuestionType, currentQuestionTraits: traits, skipIdentify: !shouldTrackToIdentity });

      openNextStep();
    };

  const petName = useFormikInsuranceFormDisplayFormatPetName();

  const addressLookupQuestionText = React.useMemo(
    () => (pets.length > 1 ? `Where do your pets live?` : `Where does ${petName} live?`),
    [petName, pets.length],
  );

  const openNextStep = React.useCallback(
    (currentQuestionSingleResult?: GetQuoteFormStepIdxToOpenCurrentBinaryAnswerParam) => {
      if (formikCurrentQuestionStepIdx < LAST_QUESTION_STEP_IDX) {
        const nextQuestionIdxToOpen = getQuoteFormStepIdxToOpen({
          currentQuestionIdx: formikCurrentQuestionStepIdx,
          formValues: formik.values,
          currentBinaryQuestionAnswer: currentQuestionSingleResult,
          direction: 'NEXT',
        });

        console.log('Moving to the question with index: ', nextQuestionIdxToOpen);

        formik.setFieldValue(CURRENT_QUESTION_STEP_IDX, nextQuestionIdxToOpen);
      } else {
        const isQuoteFormValid = verifyQuoteFormPreSubmitStateValidOrShowErrorModal();

        if (isQuoteFormValid) {
          console.log('Submitting the form');

          formik.submitForm();
        }
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formikCurrentQuestionStepIdx, verifyQuoteFormPreSubmitStateValidOrShowErrorModal],
  );

  const openNextStepDebouncedOneTime = React.useCallback(
    (currentQuestionSingleResult?: GetQuoteFormStepIdxToOpenCurrentBinaryAnswerParam) => {
      if (isCurrentStepInProgress.current) {
        // handling of the current step is already in progress
        return;
      }

      isCurrentStepInProgress.current = true;
      debounce(() => {
        openNextStep(currentQuestionSingleResult);
        isCurrentStepInProgress.current = false;
      }, DEBOUNCE_TIMEOUT)();
    },
    [openNextStep],
  );

  const openPrevStep = React.useCallback(
    () => {
      const prevStep = getQuoteFormStepIdxToOpen({
        currentQuestionIdx: formikCurrentQuestionStepIdx,
        formValues: formik.values,
        direction: 'PREVIOUS',
      });

      formik.setFieldValue(CURRENT_QUESTION_STEP_IDX, prevStep);

      console.log('Moving to the question with index: ', prevStep);
      return prevStep;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formikCurrentQuestionStepIdx],
  );

  const binaryQuestionOpenNextStep = (
    { currentQuestionType }: { currentQuestionType: QuoteFormQuestionType },
    value?: string | boolean,
  ) => {
    if (isCurrentStepInProgress.current) {
      // not to track analytics twice
      return;
    }

    const { fieldName: fields, shouldTrackToIdentity } = QUOTE_FORM_TRAIT_FIELDS_CONFIG[currentQuestionType];

    const traits = getCurrentQuoteFormQuestionTraits(fields, formik, value);

    setCurrentPetIdxToTraits(currentQuestionType, traits);

    trackAnalytics({ currentQuestionType, currentQuestionTraits: traits, skipIdentify: !shouldTrackToIdentity });

    const currentQuestionSingleResult = isSingleFieldQuestion(fields) ? { field: fields[0], value: value } : undefined;

    openNextStepDebouncedOneTime(currentQuestionSingleResult);
  };

  const isMicrochippedHandler = (isMicrochipped: boolean) => {
    const continueToNextQuestion = () =>
      binaryQuestionOpenNextStep({ currentQuestionType: 'IS_PET_MICROCHIPPED' }, isMicrochipped);

    if (!isMicrochipped && catOrDogFormikField.value === 'dog') {
      invokeErrorModal({ error: new Error(NOT_MICROCHIPPED_ERROR_MESSAGE), customAction: continueToNextQuestion });
    } else {
      continueToNextQuestion();
    }
  };

  React.useEffect(() => {
    // start the next question from the top of the page
    window.scrollTo(0, 0);
  }, [formikCurrentQuestionStepIdx]);

  return (
    <>
      <Header
        isBackButtonVisible={formikCurrentQuestionStepIdx !== FIRST_QUESTION_IDX}
        onBackButtonPress={backButtonPressHandler ?? openPrevStep}
        popoverTextContent={
          QUOTE_FORM_QUESTION_POPOVER_CONTENT_CONFIG[QUOTE_FORM_QUESTION_ORDER_CONFIG[formikCurrentQuestionStepIdx]]
        }
      />
      <Box
        maxWidth={{ base: '320px', lg: '800px' }}
        marginX="auto"
        alignSelf="center"
        marginTop={{ base: '40px', lg: '65px' }}
      >
        <Stepper step={formikCurrentQuestionStepIdx} questionOrderConfig={QUOTE_FORM_QUESTION_ORDER_CONFIG}>
          <Step step="PET_NAME">
            <TextInputQuestion
              name={PET_NAME_FIELD}
              questionText="What’s your pet’s name?"
              placeholder="Name of your pet"
              actionButtonLabel="Next step"
              onActionButtonClick={openNextQuestion({ currentQuestionType: 'PET_NAME' })}
            />
          </Step>

          <Step step="PET_TYPE">
            <PetTypeQuestion onSelect={binaryQuestionOpenNextStep} />
          </Step>

          <Step step="PET_GENDER">
            <BinaryQuestion<PetGender>
              name={GENDER_FIELD}
              questionText={`What is ${petName}’s gender?`}
              options={[
                { label: 'Boy', value: 'male' },
                { label: 'Girl', value: 'female' },
              ]}
              onSelect={(value?: string | boolean) =>
                binaryQuestionOpenNextStep({ currentQuestionType: 'PET_GENDER' }, value)
              }
            />
          </Step>

          <Step step="PET_BREED">
            <BreedQuestion
              formikName={PET_BREED_FIELD}
              fullListQuestionText={`What breed is ${petName}?`}
              petSizeQuestionText={`What size is ${petName}?`}
              placeholder="Pet breed"
              actionButtonLabel="Next step"
              onActionButtonClick={openNextQuestion({ currentQuestionType: 'PET_BREED' })}
            />
          </Step>

          <Step step="IS_PET_MICROCHIPPED">
            <BinaryQuestion<boolean>
              name={IS_MICROCHIPPED_FIELD}
              questionText={`Is ${petName} microchipped?`}
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
              onSelect={isMicrochippedHandler}
            />
          </Step>

          <Step step="PET_BIRTH_DATE">
            <PetAgeQuestion
              actionButtonLabel="Next step"
              onActionButtonClick={openNextQuestion({ currentQuestionType: 'PET_BIRTH_DATE' })}
            />
          </Step>

          <Step step="PET_VALUE">
            <PetValueQuestion
              actionButtonLabel="Next step"
              onActionButtonClick={openNextQuestion({ currentQuestionType: 'PET_VALUE' })}
            />
          </Step>

          <Step step="IS_PET_NEUTERED">
            <BinaryQuestion<boolean>
              name={IS_NEUTERED_FIELD}
              questionText={`Is ${petName} spayed/neutered?`}
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
              onSelect={(value?: string | boolean) =>
                binaryQuestionOpenNextStep({ currentQuestionType: 'IS_PET_NEUTERED' }, value)
              }
            />
          </Step>

          <Step step="PET_POLICY_START_DATE">
            <PolicyStartDateQuestionView
              onValueSelect={openNextQuestion({ currentQuestionType: 'PET_POLICY_START_DATE' })}
            />
          </Step>

          <Step step="HAS_MULTIPLE_PETS">
            <MultiPetQuestion openNextStep={openNextQuestion({ currentQuestionType: 'HAS_MULTIPLE_PETS' })} />
          </Step>

          <Step step="IS_PET_OWNER_OVER_EIGHTEEN">
            <BinaryQuestion<boolean>
              name={IS_OVER_EIGHTEEN_FIELD}
              questionText="Are you over 18 years old?"
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
              onSelect={(isOverEighteen) => {
                if (isOverEighteen) {
                  binaryQuestionOpenNextStep({ currentQuestionType: 'IS_PET_OWNER_OVER_EIGHTEEN' }, isOverEighteen);
                } else {
                  invokeErrorModal({ error: new Error(OWNER_UNDER_EIGHTEEN_ERROR_MESSAGE) });
                }
              }}
            />
          </Step>

          <Step step="PREFERRED_BENEFITS">
            <PreferredBenefitsQuestion
              onActionButtonClick={openNextQuestion({ currentQuestionType: 'PREFERRED_BENEFITS' })}
            />
          </Step>

          <Step step="HAS_ALREADY_A_POLICY">
            <HasAlreadyAPolicyQuestion
              onActionButtonPress={(value: boolean) =>
                binaryQuestionOpenNextStep({ currentQuestionType: 'HAS_ALREADY_A_POLICY' }, value)
              }
            />
          </Step>

          {/* Question will be opened in case the user answers "Yes" on previous question */}
          <Step step="EXISTING_POLICY_FINISH_DATE">
            <DateInputQuestion
              className="default-date-input"
              name={EXISTING_POLICY_FINISH_DATE}
              questionText="When does your policy expire?"
              defaultView="decade"
              actionButtonLabel="Next step"
              unitsToPick="month"
              isFieldOptional
              onActionButtonClick={openNextQuestion({ currentQuestionType: 'EXISTING_POLICY_FINISH_DATE' })}
            />
          </Step>

          <Step step="OPTIONAL_PHONE_NUMBER">
            <OptionalPhoneNumberQuestion
              openNextStep={openNextQuestion({ currentQuestionType: 'OPTIONAL_PHONE_NUMBER' })}
            />
          </Step>

          <Step step="LOOKUP_ADDRESS">
            <LookupAddressQuestion
              questionText={addressLookupQuestionText}
              onActionButtonClick={openNextQuestion({ currentQuestionType: 'LOOKUP_ADDRESS' })}
            />
          </Step>

          <Step step="EMAIL">
            <EmailQuestion openNextStep={openNextQuestion({ currentQuestionType: 'EMAIL' })} />
          </Step>
        </Stepper>
      </Box>
    </>
  );
};
