import React from 'react';

import { useInsuranceDataContext } from '~/hooks/insurance-data-provider/useInsuranceDataContext';

import { trackEvent } from '~/helpers/analytics/trackEvent';

import { AnalyticsEvent } from '~/constants/analyticsEvent';

export function useQuoteRestart(restartButtonHandler?: () => void) {
  const { restartQuoteForm } = useInsuranceDataContext();

  const restartQuote = React.useCallback(() => {
    trackEvent({ eventName: AnalyticsEvent.QuoteClickFormResetButton });

    restartQuoteForm();

    restartButtonHandler?.();
  }, [restartButtonHandler, restartQuoteForm]);

  return restartQuote;
}
