import React from 'react';
import { useFormikContext } from 'formik';

import { useInvokeErrorModal } from '~/hooks/useInvokeErrorModal';

import { FORM_VALIDATION } from '~/constants/errorMessage';

export function useFormikOnSubmitValidationErrorHandling(formLabel: string) {
  const formik = useFormikContext();

  const { invokeErrorModal } = useInvokeErrorModal();

  if (!formik) {
    throw new Error('Trying to use "useFormikOnSubmitValidationErrorHandling" without Formik provider');
  }

  const { isSubmitting, isValidating, isValid, errors, values } = formik;

  React.useEffect(() => {
    if (isSubmitting && !isValidating) {
      if (!isValid) {
        invokeErrorModal({
          error: { ...errors, message: FORM_VALIDATION } as Error,
          secondaryActionButtonLabel: 'Go back to the form',
        });
      }
    }
  }, [formLabel, isSubmitting, isValidating, isValid, values, errors, invokeErrorModal]);
}
