import { ProductBenefitCode } from '@joinfluffy/common';

import { FIRST_ITEM_INDEX } from '@shared/constants/common';
import { PetQuote } from '@shared/models/product';

const DOG_ONLY_BENEFIT_CODE: ProductBenefitCode = 'THIRD_PARTY_LIABILITY';

// If we have dog policy - return its index, otherwise the first pet index will be returned
export function findPolicyPetIndex(petQuotes: PetQuote[]) {
  let index = FIRST_ITEM_INDEX;

  for (let i = 0; i < petQuotes.length; i++) {
    const productQuotes = petQuotes[i].productQuotes;

    const hasDogOnlyBenefit = productQuotes.some((productQuote) =>
      productQuote.productBenefits.some((benefit) => benefit.code === DOG_ONLY_BENEFIT_CODE),
    );

    if (hasDogOnlyBenefit) {
      index = i;

      break;
    }
  }

  return index;
}
