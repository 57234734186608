import React from 'react';
import { useField } from 'formik';

import { ChooseOptionItem } from '~/components/questions/ChooseOptionItem';

interface ChooseOptionItemProps<OptionValueType extends string> {
  name: string;
  value: OptionValueType;
  label: string;
  badgeLabel?: string;
  onSelect?: (value: OptionValueType) => void;
}

export function ChooseOptionFormikItem<OptionValueType extends string>({
  name,
  value,
  label,
  badgeLabel,
  onSelect,
}: ChooseOptionItemProps<OptionValueType>) {
  // second parameter (meta) is not used now
  const [formikField, , formikHelper] = useField(name);

  const isSelected = React.useMemo(() => formikField.value === value, [formikField.value, value]);
  const selectItem = React.useCallback(async () => {
    formikHelper.setTouched(true);
    formikHelper.setValue(value);

    await Promise.resolve();

    onSelect && onSelect(value);
  }, [formikHelper, value, onSelect]);

  return <ChooseOptionItem label={label} badgeLabel={badgeLabel} isSelected={isSelected} onSelect={selectItem} />;
}
