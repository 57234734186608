import React from 'react';

import { ErrorInfoModal } from '~/components/modal/error-info-modal/ErrorInfoModal';
import { PerkInfoModal } from '~/components/modal/perk-info-modal/PerkInfoModal';
import { NotSupportedBreedModal } from '~/components/modal/NotSupportedBreedModal';
import { NotSupportedPetAgeModal } from '~/components/modal/NotSupportedPetAgeModal';
import { ConfirmPetIsOldEnoughModal } from '~/components/modal/ConfirmPetIsOldEnoughModal';
import { GeneralConfirmationModal } from '~/components/modal/GeneralConfirmationModal';
import { DiscountCodeModal } from '~/components/modal/discount-code-modal/DiscountCodeModal';
import { ReferralLinkModal } from '~/components/modal/referral-link-modal/ReferralLinkModal';

import { ModalTypes } from '~/constants/modalTypes';

export const MODAL_CONFIG: Record<ModalTypes | '', React.ComponentType<any> | null> = {
  [ModalTypes.ErrorInfo]: ErrorInfoModal,
  [ModalTypes.PerkInfo]: PerkInfoModal,
  [ModalTypes.NotSupportedBreed]: NotSupportedBreedModal,
  [ModalTypes.NotSupportedPetAge]: NotSupportedPetAgeModal,
  [ModalTypes.ConfirmPetIsOldEnough]: ConfirmPetIsOldEnoughModal,
  [ModalTypes.GeneralConfirmation]: GeneralConfirmationModal,
  [ModalTypes.DiscountCode]: DiscountCodeModal,
  [ModalTypes.ReferralLink]: ReferralLinkModal,
  '': null,
};
