import React, { SVGProps } from 'react';
import { Box, BoxProps, HStack, Image, Text } from '@chakra-ui/react';

import { Colors } from '~/constants/colors';

interface ShareButtonProps {
  Icon: React.FC<SVGProps<SVGSVGElement>> | string;
  label: string;
  iconContainerStyle: BoxProps;
}

export const ShareButton: React.FC<ShareButtonProps> = function ShareButton({ Icon, label, iconContainerStyle }) {
  return (
    <HStack
      spacing="6px"
      height="44px"
      alignItems="center"
      padding="0px 16px"
      borderRadius="20px"
      backgroundColor={Colors.Beige}
    >
      <Box {...iconContainerStyle}>
        {typeof Icon === 'string' ? (
          <Image src={Icon} width="100%" height="100%" />
        ) : (
          <Icon width="100%" height="100%" />
        )}
      </Box>

      <Box>
        <Text fontSize="20px" lineHeight="32px" fontWeight="700" color={Colors.Brown}>
          {label}
        </Text>
      </Box>
    </HStack>
  );
};
