import moment from 'moment';

import { YYYY_DATE_FORMAT } from '@shared/constants/date';
import { MIN_AGE_FOR_INSURANCE_IN_WEEKS } from '@shared/constants/validation';

import { CURRENT_YEAR } from '~/helpers/pet/petDateOfBirth';
import { getTodaysDate } from '~/helpers/date/date';

export function getShouldConfirmPetIsOverEightWeeks(petYearOfBirthValue: string) {
  const petDateOfBirthMomentDate = moment(petYearOfBirthValue, YYYY_DATE_FORMAT);
  const wasSelectedCurrentYear = petDateOfBirthMomentDate.year() === CURRENT_YEAR;
  const wasSelectedPreviousYear = petDateOfBirthMomentDate.year() === moment().subtract(1, 'years').year();

  let shouldConfirmPetIsOverEightWeeks = wasSelectedCurrentYear;
  if (wasSelectedPreviousYear) {
    if (getTodaysDate().subtract(MIN_AGE_FOR_INSURANCE_IN_WEEKS, 'weeks').year() !== CURRENT_YEAR) {
      shouldConfirmPetIsOverEightWeeks = true;
    }
  }

  return shouldConfirmPetIsOverEightWeeks;
}

export function getIsPetTooYoungByYear(petYearOfBirthValue: string) {
  const petDateOfBirthMomentDate = moment(petYearOfBirthValue, YYYY_DATE_FORMAT);
  const wasSelectedCurrentYear = petDateOfBirthMomentDate.year() === CURRENT_YEAR;

  if (!wasSelectedCurrentYear) {
    return false;
  }

  // in case the user selected current year, we use current date to check if the pet is not too young
  const todayDate = getTodaysDate();
  const startOfTheYear = moment().startOf('year');

  // here we check if the pet is old enough if it was born this year
  return todayDate.subtract(MIN_AGE_FOR_INSURANCE_IN_WEEKS, 'weeks').isBefore(startOfTheYear);
}
