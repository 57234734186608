import React from 'react';

import { BaseModal } from '~/components/common/BaseModal';

import { useErrorInfoContent } from '~/hooks/useErrorInfoContent';

import { ModalTypes } from '~/constants/modalTypes';

interface ErrorInfoModalProps {
  isOpen: boolean;
  error?: Error;
  secondaryActionButtonLabel?: string;
  onClose: () => void;
  customAction?: () => void;
}

const IMAGE_PATH = '/images/dogImages/30-chill-dog/30-chill-dog-800.png';

export const ErrorInfoModal: React.FC<ErrorInfoModalProps> = function ErrorInfoModal({
  isOpen,
  error,
  secondaryActionButtonLabel,
  onClose,
  customAction,
}) {
  const { title, subTitle, primaryButtonLabel, primaryButtonAction } = useErrorInfoContent(error);

  const performCustomActionAndClose = () => {
    customAction && customAction();
    onClose();
  };

  return (
    <BaseModal
      type={ModalTypes.ErrorInfo}
      isOpen={isOpen}
      imgSrc={IMAGE_PATH}
      title={title}
      description={subTitle}
      onClose={onClose}
      actionButtonLabel={primaryButtonLabel}
      onActionButtonPress={primaryButtonAction ?? performCustomActionAndClose}
      secondaryActionButtonLabel={secondaryActionButtonLabel ?? 'Close'}
      onSecondaryActionButtonPress={onClose}
    />
  );
};
