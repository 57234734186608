import React from 'react';
import { useFormikContext } from 'formik';

import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';
import { addSentryBreadcrumb, startSentrySpan } from '~/helpers/monitoring/common';

// The logic is based on the Sentry docs about form-submission: https://formik.org/docs/guides/form-submission
export function useFormikInstrumentation(formLabel: string) {
  const formik = useFormikContext();

  if (!formik) {
    throw new Error('Trying to use "useFormikInstrumentation" without Formik provider');
  }

  const { isSubmitting, isValidating, isValid, errors, values } = formik;

  React.useEffect(() => {
    let formValidationOnSubmitSpan;
    if (isSubmitting && isValidating) {
      formValidationOnSubmitSpan = startSentrySpan({
        op: `${formLabel}_submit_validation_span`,
        description: `${formLabel} validation on submit`,
      });

      // this is validation phase on submit
      addSentryBreadcrumb({
        category: 'event_breadcrumb',
        message: `[Form "${formLabel}"]: start validation on submit`,
        data: { values },
        level: 'info',
      });
    }

    if (isSubmitting && !isValidating) {
      // validation phase on submit just ended
      addSentryBreadcrumb({
        category: 'event_breadcrumb',
        message: `[Form "${formLabel}"]: finish validation on submit`,
        data: { isValid, errors, values },
        level: 'info',
      });

      if (!isValid) {
        captureAndLogException(`[Form "${formLabel}"]: Validation on submit is failed`, 'Error');
      }

      formValidationOnSubmitSpan?.finish();
    }
  }, [formLabel, isSubmitting, isValidating, isValid, values, errors]);
}
