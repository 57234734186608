import { SpanContext } from '@sentry/types';

import * as Sentry from '@sentry/react';

export function startSentryTransaction(name: string) {
  const transaction = Sentry.startTransaction({ op: name, name });

  Sentry.configureScope((scope) => scope.setSpan(transaction));

  return transaction;
}

export function addSentryBreadcrumb(data: object) {
  Sentry.addBreadcrumb(data);
}

export function startSentrySpan(context: SpanContext) {
  const transaction = getCurrentSentryScopeTransaction();

  if (transaction) {
    return transaction.startChild(context);
  }
}

export function getCurrentSentryScopeTransaction() {
  return Sentry.getCurrentHub().getScope()?.getTransaction();
}

export function finishCurrentSentryTransaction() {
  getCurrentSentryScopeTransaction()?.finish();
}
