import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import { Colors } from '~/constants/colors';

interface ChooseOptionItemProps {
  label: string;
  badgeLabel?: string;
  isSelected?: boolean;
  onSelect: () => void;
}

export const ChooseOptionItem: React.FC<ChooseOptionItemProps> = function ChooseOptionItem({
  label,
  isSelected,
  badgeLabel,
  onSelect,
}) {
  return (
    <Box
      position="relative"
      onClick={onSelect}
      minWidth="320px"
      minHeight="60px"
      padding="20px 24px"
      borderRadius="20px"
      border="1px"
      borderColor={isSelected ? Colors.Orange : Colors.Beige}
      backgroundColor={Colors.Beige}
      cursor="pointer"
      fontWeight="500"
      _hover={isSelected ? undefined : { borderColor: Colors.Orange }}
    >
      <Text align="left" fontSize="20px" lineHeight="26px" color={Colors.Brown}>
        {label}
      </Text>

      {badgeLabel && (
        <Box
          position="absolute"
          top="4px"
          bottom="4px"
          right="4px"
          padding="8px"
          borderRadius="20px"
          bg={Colors.Orange}
        >
          <Text
            align="left"
            fontSize="16px"
            lineHeight="20px"
            textAlign="center"
            maxW="80px"
            fontWeight="700"
            color={Colors.White}
          >
            {badgeLabel}
          </Text>
        </Box>
      )}
    </Box>
  );
};
