import React from 'react';
import { Center, Stack, Text } from '@chakra-ui/react';

import { Colors } from '~/constants/colors';

export interface PaymentTypeSelectionItemProps {
  price: string;
  oldPrice: string;
  label: string;
  isSelected?: boolean;
  onSelect: () => void;
}

export const PaymentTypeSelectionItem: React.FC<PaymentTypeSelectionItemProps> = function PaymentTypeSelectionItem({
  price,
  oldPrice,
  label,
  isSelected,
  onSelect,
}) {
  return (
    <Center
      onClick={onSelect}
      width="156px"
      height="216px"
      borderRadius="20px"
      bg={isSelected ? Colors.OrangePale : Colors.White}
      cursor="pointer"
      _hover={isSelected ? undefined : { borderColor: '#999' }}
    >
      <Stack spacing="12px">
        <Text fontSize="24px" lineHeight="24px" color={Colors.Orange} fontWeight="700">
          {price}
        </Text>
        {oldPrice !== price && (
          <Text fontSize="20px" lineHeight="24px" color={Colors.Brown} textDecoration="line-through" fontWeight="700">
            {oldPrice}
          </Text>
        )}

        <Text fontSize="20px" lineHeight="24px" color={Colors.Orange} fontWeight="500">
          {label}
        </Text>
      </Stack>
    </Center>
  );
};
