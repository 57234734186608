import { findPetBreedByName, DOG_PET_TYPE_ID, getProductBenefitData, isSupportedProductCode } from '@joinfluffy/common';

import { ProductQuoteSchema, PetQuote } from '@shared/models/product';

import { Currency } from '@shared/constants/currency';

import { PolicyPricingSummarySchema } from '~/components/customer-form/customer-form.schema';
import { CAT_OR_DOG_FIELD, PET_BREED_FIELD, QuoteFormPetValues } from '~/components/quote-form/quote-form.schema';

import { getPetTypeByPetTypeValue } from '~/helpers/pet';
import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';

import { FEE_CONFIG } from '~/configs/fee';

interface BuildProductQuotePricingSummaryParams {
  productQuote?: ProductQuoteSchema;
  isPayingAnnual?: boolean;
}

export function filterUnsupportedProducts(petQuotes: PetQuote[]) {
  return petQuotes.forEach((petQuote) => {
    petQuote.productQuotes = petQuote.productQuotes.filter((productQuote) =>
      isSupportedProductCode(productQuote.productCode),
    );
  });
}

export function normaliseProductOrderByVetFeeAmount(productQuotes: ProductQuoteSchema[]) {
  return productQuotes.sort((firstProduct, secondProduct) => {
    const firstProductVetFeeBenefit = getProductBenefitData(firstProduct.productBenefits, 'VET_FEES');
    const secondProductVetFeeBenefit = getProductBenefitData(secondProduct.productBenefits, 'VET_FEES');

    return Number(firstProductVetFeeBenefit?.amount) - Number(secondProductVetFeeBenefit?.amount);
  });
}

export function buildProductQuoteSummary(
  policyStartDate: string | null,
  petFormData?: QuoteFormPetValues,
  policyData?: ProductQuoteSchema,
) {
  if (!policyData || !petFormData) {
    return;
  }

  const policySummary = new Map<string, string>();

  const vetFeesAmount = getProductBenefitData(policyData.productBenefits, 'VET_FEES')?.amount;
  const petType = getPetTypeByPetTypeValue(petFormData[CAT_OR_DOG_FIELD]);

  if (!vetFeesAmount || !petType || !policyStartDate) {
    captureAndLogException('Unable to display policy summary - missing one of the mandatory fields', 'Error');

    return;
  }

  policySummary.set('Cover type', 'Lifetime');
  policySummary.set('Vet fees', `${Currency.GBP}${vetFeesAmount}`);
  policySummary.set('Start date', policyStartDate);
  policySummary.set('Pet type', getPetTypeLabel(petFormData[PET_BREED_FIELD], petType.id));
  policySummary.set('Breed', petFormData[PET_BREED_FIELD]);

  return policySummary;
}

export function buildProductQuotePricingSummary({
  productQuote,
  isPayingAnnual,
}: BuildProductQuotePricingSummaryParams): PolicyPricingSummarySchema | undefined {
  if (!productQuote) {
    return;
  }

  const monthlyPrice = productQuote.monthlyPrice;
  const annualPrice = productQuote.annualPrice;

  const feeConfig = FEE_CONFIG['ADMIN'];
  const currency = feeConfig.currency;
  const feeAmount = feeConfig.amount;

  const discount = getTotalDiscount(productQuote);

  return {
    ...(!isPayingAnnual && {
      monthlyPrice: {
        displayKey: 'Monthly Price:',
        value: `${currency}${monthlyPrice.toFixed(2)}`,
      },
    }),
    annualInsurancePrice: {
      displayKey: 'Annual insurance price:',
      value: buildAnnualInsurancePriceString(productQuote),
    },
    adminFee: {
      displayKey: 'Admin fee:',
      value: `${currency}${feeAmount} (non-refundable)`,
    },
    ...(discount > 0 && {
      discount: {
        displayKey: 'Discount:',
        value: `-${currency}${discount}`,
      },
    }),
    totalAnnualPrice: {
      displayKey: 'Total annual price:',
      value: `${currency}${annualPrice}`,
    },
  };
}

export function buildAnnualInsurancePriceString(policyData?: ProductQuoteSchema) {
  if (!policyData) {
    return '';
  }

  const totalAnnualPrice = policyData.annualPrice;
  const discountAmount = getTotalDiscount(policyData);

  if (!totalAnnualPrice) {
    return '';
  }
  const { currency } = FEE_CONFIG['ADMIN'];

  const insuranceAnnualPrice = getInsuranceAnnualPrice(totalAnnualPrice, discountAmount);

  return `${currency}${insuranceAnnualPrice} (incl 12% IPT)`;
}

export function getInsuranceAnnualPrice(totalAnnualPrice: number, discountAmount: number) {
  const { amount: feeAmount } = FEE_CONFIG['ADMIN'];

  return (totalAnnualPrice - feeAmount + discountAmount).toFixed(2);
}

function getPetTypeLabel(breedValue: string, petTypeId: string) {
  const breed = findPetBreedByName(breedValue);
  const isDog = petTypeId === DOG_PET_TYPE_ID;

  let type = 'Pedigree';
  if (breed?.isMixed) {
    type = 'Mix-breed';
  }
  if (breed?.isCrossBreed) {
    type = 'Cross-breed';
  }

  return `${type} ${isDog ? 'dog' : 'cat'}`;
}

export function getTotalDiscount(productQuote: ProductQuoteSchema): number {
  const discount = (productQuote.multipetDiscount ?? 0) + (productQuote.promotionDiscount ?? 0);

  return parseFloat(discount.toFixed(2));
}
