import React from 'react';

import type { CatOrDog } from '@joinfluffy/common';

import { BaseModal } from '~/components/common/BaseModal';

import { ModalTypes } from '~/constants/modalTypes';
import {
  CAT_MAX_AGE_FOR_INSURANCE_IN_YEARS,
  DOG_MAX_AGE_FOR_INSURANCE_IN_YEARS,
  MIN_AGE_FOR_INSURANCE_IN_WEEKS,
} from '@shared/constants/validation';

const IMAGE_PATH = '/images/dogImages/07-health-dog/07-health-dog-1080.png';

interface NotSupportedPetAgeModalProps {
  isOpen: boolean;
  onClose: () => void;
  petTypeValue: CatOrDog;
  isPetTooYoung: boolean;
  isPetTooOld: boolean;
}

export const NotSupportedPetAgeModal: React.FC<NotSupportedPetAgeModalProps> = function NotSupportedPetAgeModal({
  isOpen,
  onClose,
  petTypeValue,
  isPetTooYoung,
  isPetTooOld,
}) {
  let title;
  let description;

  if (isPetTooYoung) {
    title = `Your pet's age is below the minimum of ${MIN_AGE_FOR_INSURANCE_IN_WEEKS} weeks`;
    description = `Your pet is a bit too young for insurance with Fluffy, but we can protect your pet very soon. Please come back when your pet turns ${MIN_AGE_FOR_INSURANCE_IN_WEEKS} weeks old.`;
  } else if (isPetTooOld) {
    const maxAge = petTypeValue === 'dog' ? DOG_MAX_AGE_FOR_INSURANCE_IN_YEARS : CAT_MAX_AGE_FOR_INSURANCE_IN_YEARS;
    title = `Your pet's age is above the maximum age of ${maxAge} years.`;
    description = `At Fluffy, we want to protect both younger and older pets. Unfortunately, we can't cover pets who are older than ${maxAge} years at the moment, but we're working hard to change this!`;
  }

  if (!title || !description) {
    onClose();
    return null;
  }

  return (
    <BaseModal
      type={ModalTypes.NotSupportedPetAge}
      isOpen={isOpen}
      imgSrc={IMAGE_PATH}
      title={title}
      description={description}
      onClose={onClose}
      actionButtonLabel="Close"
      onActionButtonPress={onClose}
    />
  );
};
