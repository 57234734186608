import React from 'react';
import { useFormikContext } from 'formik';
import { Box, Input, Spinner, Stack, Text } from '@chakra-ui/react';

import { FormikInput } from '~/components/form/formik-input/FormikInput';
import { ActionButton } from '~/components/common/ActionButton';
import { QuestionStack } from '~/components/common/QuestionStack';
import { TitleText } from '~/components/common/TitleText';
import { OverlayLoader } from '~/components/common/OverlayLoader';
import { useAddressAutocomplete } from '~/components/questions/lookup-address-question/useAddressAutocomplete';
import { useAddressLookup } from '~/components/questions/lookup-address-question/useAddressLookup';
import type { QuoteFormValues } from '~/components/quote-form/quote-form.schema';
import {
  POSTCODE_FIELD,
  ADDRESS_LINE_1_FIELD,
  ADDRESS_LINE_2_FIELD,
  ADDRESS_LINE_3_FIELD,
} from '~/components/quote-form/quote-form.schema';
import { FLUSHED_INPUT_STYLES } from '~/components/form/formik.style';
import {
  AddressSearchMode,
  ADDRESS_LINE_PARAMS,
  SEARCH_MODE_TOGGLE_OPTION,
  SEARCH_MODE_TOGGLE_LABEL,
  MANDATORY_ADDRESS_LINE_PARAMS,
} from '~/components/questions/lookup-address-question/LookupAddressQuestion.const';

import { useKeyEnterScreenHandler } from '~/hooks/common/useKeyEnterScreenHandler';

import { trackEvent } from '~/helpers/analytics/trackEvent';

import { Colors } from '~/constants/colors';
import { AnalyticsEvent } from '~/constants/analyticsEvent';
import { GlobalStyleVariables } from '~/constants/globalStyleVariables';

interface LookupAddressQuestionProps {
  questionText: string;
  onActionButtonClick: () => void;
}

export const LookupAddressQuestion: React.FC<LookupAddressQuestionProps> = function LookupAddressQuestion({
  questionText,
  onActionButtonClick,
}) {
  const formik = useFormikContext<QuoteFormValues>();

  // Action button is disabled if at least one field is empty or contains an error
  const isActionButtonDisabled = MANDATORY_ADDRESS_LINE_PARAMS.some(
    ({ formikName }) => !formik.values[formikName] || formik.errors[formikName],
  );

  useKeyEnterScreenHandler({ actionToCall: onActionButtonClick, isActionDisabled: isActionButtonDisabled });

  const [addressSearchMode, setAddressSearchMode] = React.useState<AddressSearchMode>(() => {
    if (MANDATORY_ADDRESS_LINE_PARAMS.every(({ formikName }) => formik.values[formikName])) {
      return 'manual';
    }
    return 'lookup';
  });

  const [lookupInputValue, setLookupInputValue] = React.useState('');
  const inputChangeHandler = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setLookupInputValue(value);
  }, []);

  const suggestsContainerRef = React.useRef<HTMLDivElement | null>(null);
  const [isFocused, setIsFocused] = React.useState(false);

  const inputFocusHandler = React.useCallback(() => setIsFocused(true), []);
  const inputBlurHandler = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    if (suggestsContainerRef.current?.contains(event.relatedTarget)) {
      // click inside container with the suggested options
      return;
    }

    setIsFocused(false);
  }, []);

  const toggleAddressSearchMode = React.useCallback(() => {
    const mode = SEARCH_MODE_TOGGLE_OPTION[addressSearchMode];

    if (mode === 'manual') {
      trackEvent({ eventName: AnalyticsEvent.QuoteToggleManualAddressSearch });
    }

    setAddressSearchMode(mode);
  }, [addressSearchMode]);

  const {
    lastResultSuggestions,
    lastInput,
    isLoading: isAddressAutocompleteLoading,
  } = useAddressAutocomplete(lookupInputValue);
  const { fetchAddress, isLoading: isAddressLookupLoading } = useAddressLookup();

  // depends on formik here - no need to wrap with useCallback
  const getSelectSuggestionHandler = (suggestionId: string) => async () => {
    const addressInfo = await fetchAddress(suggestionId);
    if (!addressInfo) {
      return;
    }

    formik.setTouched({
      ...formik.touched,
      [POSTCODE_FIELD]: true,
      [ADDRESS_LINE_1_FIELD]: true,
      [ADDRESS_LINE_2_FIELD]: true,
      [ADDRESS_LINE_3_FIELD]: true,
    });

    formik.setValues({
      ...formik.values,
      [POSTCODE_FIELD]: addressInfo.postcode,
      [ADDRESS_LINE_1_FIELD]: addressInfo.line_1,
      [ADDRESS_LINE_2_FIELD]: addressInfo.line_2,
      [ADDRESS_LINE_3_FIELD]: [addressInfo.line_3, addressInfo.line_4].filter(Boolean).join(', '),
    });

    setIsFocused(false);
    setAddressSearchMode('manual');
  };

  return (
    <QuestionStack minW={320}>
      <TitleText text={questionText} />

      <Stack spacing="20px">
        {addressSearchMode === 'lookup' && (
          <Stack spacing={1} width={{ base: '327px', lg: '400px' }}>
            <Box position="relative">
              <Input
                variant="flushed"
                size="md"
                {...FLUSHED_INPUT_STYLES}
                placeholder="Start typing your address"
                value={lookupInputValue}
                onChange={inputChangeHandler}
                onFocus={inputFocusHandler}
                onBlur={inputBlurHandler}
                disabled={isAddressLookupLoading}
                autoComplete="off"
              />

              {isAddressAutocompleteLoading && (
                <Spinner position="absolute" bottom="8px" right="4px" color={Colors.Orange} speed="0.45s" size="sm" />
              )}

              {isFocused && lastInput && !isAddressAutocompleteLoading && (
                <Stack
                  spacing={0}
                  position="absolute"
                  top="120%"
                  left={0}
                  right={0}
                  padding="8px"
                  borderRadius="8px"
                  backgroundColor={Colors.Beige}
                  zIndex={GlobalStyleVariables.PopoverContentZIndex}
                  textAlign="left"
                  ref={(current) => (suggestsContainerRef.current = current)}
                >
                  {lastResultSuggestions?.length ? (
                    lastResultSuggestions.map((option) => (
                      <Text
                        tabIndex={0} // to be "focusable"
                        key={option.id}
                        onClick={getSelectSuggestionHandler(option.id)}
                        fontSize="16px"
                        lineHeight="20px"
                        padding="6px 8px"
                        borderRadius="4px"
                        color={Colors.SecondaryBrown}
                        _hover={{ opacity: 0.5, cursor: 'pointer' }}
                      >
                        {option.address}
                      </Text>
                    ))
                  ) : (
                    <Text fontSize="13px" lineHeight="15px" color={Colors.Brown} paddingLeft="8px" paddingTop={0}>
                      No results
                    </Text>
                  )}

                  {isAddressLookupLoading && <OverlayLoader coverMode="container" />}
                </Stack>
              )}
            </Box>

            <Box height={{ base: '24px', lg: '36px' }} paddingTop={{ base: '4px' }}>
              <Text textAlign="left" fontSize="13px" lineHeight="16px" color={Colors.Grey500}>
                Please add the postcode or the first line of your address
              </Text>
            </Box>
          </Stack>
        )}

        {addressSearchMode === 'manual' && (
          <>
            {ADDRESS_LINE_PARAMS.map(({ formikName, placeholder }, idx) => (
              <FormikInput key={idx} name={formikName} placeholder={placeholder} variant="flushed" size="md" />
            ))}
          </>
        )}

        <ActionButton
          onPress={toggleAddressSearchMode}
          label={SEARCH_MODE_TOGGLE_LABEL[addressSearchMode]}
          isSecondary
        />
      </Stack>

      <ActionButton onPress={onActionButtonClick} label="Next step" disabled={isActionButtonDisabled} />
    </QuestionStack>
  );
};
