import { useSearchParams } from 'react-router-dom';

import { ExternalPurchaseUrlSearchParams } from '@shared/constants/urlSearchParams';

export function useCleanSearchParamsByKey() {
  const [searchParams, setSearchParams] = useSearchParams();

  const cleanSearchParamsByKey = (paramToDelete: ExternalPurchaseUrlSearchParams) => {
    if (searchParams.has(paramToDelete)) {
      searchParams.delete(paramToDelete);

      setSearchParams(searchParams, { replace: true });
    }
  };

  return cleanSearchParamsByKey;
}
