import { MM_YYYY_DATE_FORMAT } from '@shared/constants/date';

import { CustomerFormField, CustomerFormQuestionType } from '~/components/customer-form/customer-form.schema';
import { QuoteFormField, QuoteFormQuestionType } from '~/components/quote-form/quote-form.schema';

import { FilledQuoteFormValuesSchema } from '~/providers/insurance-data-provider/types';

import { AnalyticsEventWithTraitsName } from '~/models/analyticsParams';

import { AnalyticsEvent } from '~/constants/analyticsEvent';

export const FILLED_QUOTE_FORM_VALUES_TRAITS_SKIPPED_FIELD: FilledQuoteFormValuesSchema[keyof FilledQuoteFormValuesSchema] =
  'pets';

export const DATE_FORM_FIELDS_DATE_FORMAT: Partial<Record<QuoteFormField | CustomerFormField, string>> = {
  existingPolicyFinishDate: MM_YYYY_DATE_FORMAT,
};

const DATE_FORM_FIELDS = [
  'dateOfBirth',
  'dateOfOwnerBirth',
  'policyStartDateField',
  'existingPolicyFinishDate',
] satisfies (QuoteFormField | CustomerFormField)[];

type DateFormField = (typeof DATE_FORM_FIELDS)[number];

export function isDateFormFields(maybeDate: string): maybeDate is DateFormField {
  return DATE_FORM_FIELDS.some((dateField) => dateField === maybeDate);
}

export interface QuoteFormTraitFieldConfig {
  fieldName: QuoteFormField[];
  shouldTrackToIdentity?: boolean;
}

export const QUOTE_FORM_TRACKING_EVENTS_CONFIG: Record<
  QuoteFormQuestionType,
  AnalyticsEventWithTraitsName | null // it could be null in case we handle one particular question separately
> = {
  HAS_ALREADY_A_POLICY: AnalyticsEvent.QuoteHasAlreadyInsurancePolicy,
  PET_NAME: AnalyticsEvent.QuotePetNameConfirmed,
  PET_TYPE: AnalyticsEvent.QuotePetTypeConfirmed,
  PET_GENDER: AnalyticsEvent.QuotePetGenderConfirmed,
  PET_BREED: AnalyticsEvent.QuotePetBreedConfirmed,
  PET_BIRTH_DATE: null, // handled separately as AnalyticsEvent.QuotePetAgeFullConfirmed with rich params
  PET_VALUE: AnalyticsEvent.QuotePetPurchaseValueConfirmed,
  IS_PET_NEUTERED: AnalyticsEvent.QuotePetIsSpayedConfirmed,
  LOOKUP_ADDRESS: AnalyticsEvent.QuoteOwnerAddressConfirmed,
  EMAIL: AnalyticsEvent.QuoteOwnerEmailConfirmed,
  PET_POLICY_START_DATE: AnalyticsEvent.QuotePolicyStartDateConfirmed,
  IS_PET_OWNER_OVER_EIGHTEEN: AnalyticsEvent.QuoteOwnerOverEighteenConfirmed,
  IS_PET_MICROCHIPPED: AnalyticsEvent.QuotePetIsMicrochipedConfirmed,
  OPTIONAL_PHONE_NUMBER: AnalyticsEvent.QuoteOptionalPhoneNumberConfirmed,
  HAS_MULTIPLE_PETS: null, // handled separately as AnalyticsEvent.QuoteNumOfPetsConfirmed
  EXISTING_POLICY_FINISH_DATE: AnalyticsEvent.QuoteExistingPolicyFinishDateConfirmed,
  PREFERRED_BENEFITS: AnalyticsEvent.QuotePreferredBenefitsConfirmed,
};

export const CUSTOMER_FORM_TRACKING_EVENTS_CONFIG: Record<
  CustomerFormQuestionType,
  AnalyticsEventWithTraitsName | null
> = {
  POLICY_DISPLAY_VIEW: null,
  POLICY_DECLARATION: AnalyticsEvent.QuotePolicyDeclarationConfirmed,
  OWNER_INFO: AnalyticsEvent.QuoteOwnerInfoConfirmed,
  DATE_OF_OWNER_BIRTH: AnalyticsEvent.QuoteOwnerDateOfBirthConfirmed,
  PAYMENT_TYPE_SELECTION: null,
  PAYMENT_PAGE_LOADER: null,
};

export const QUOTE_FORM_TRAIT_FIELDS_CONFIG: Record<QuoteFormQuestionType, QuoteFormTraitFieldConfig> = {
  HAS_ALREADY_A_POLICY: { fieldName: ['hasAlreadyInsurancePolicy'], shouldTrackToIdentity: true },
  PET_NAME: { fieldName: ['petName'] },
  PET_TYPE: { fieldName: ['catOrDog'] },
  PET_GENDER: { fieldName: ['gender'] },
  PET_BREED: { fieldName: ['petBreed'] },
  PET_BIRTH_DATE: { fieldName: ['dateOfBirth'] },
  IS_PET_NEUTERED: { fieldName: ['isNeutered'] },
  PET_VALUE: { fieldName: ['petValue'] },
  LOOKUP_ADDRESS: { fieldName: ['postCode', 'addressLine1'], shouldTrackToIdentity: true },
  EMAIL: { fieldName: ['email'], shouldTrackToIdentity: true },
  IS_PET_OWNER_OVER_EIGHTEEN: { fieldName: ['isOverEighteen'], shouldTrackToIdentity: true },
  PET_POLICY_START_DATE: { fieldName: ['policyStartDateField'] },
  IS_PET_MICROCHIPPED: { fieldName: ['isMicroChipped'] },
  OPTIONAL_PHONE_NUMBER: { fieldName: ['optionalPhoneNumber'], shouldTrackToIdentity: true },
  HAS_MULTIPLE_PETS: { fieldName: ['hasMultiplePets'], shouldTrackToIdentity: true },
  EXISTING_POLICY_FINISH_DATE: { fieldName: ['existingPolicyFinishDate'] },
  PREFERRED_BENEFITS: { fieldName: ['preferredBenefitsList'] },
};

export const CUSTOMER_FORM_IDENTITY_FIELDS_CONFIG: Record<CustomerFormQuestionType, CustomerFormField[] | null> = {
  POLICY_DISPLAY_VIEW: null,
  POLICY_DECLARATION: ['arePolicyAndBusinessDocumentRead'],
  OWNER_INFO: ['firstName', 'lastName', 'title', 'phoneNumber'],
  DATE_OF_OWNER_BIRTH: ['dateOfOwnerBirth'],
  PAYMENT_PAGE_LOADER: null,
  PAYMENT_TYPE_SELECTION: null,
};
