import React from 'react';
import moment from 'moment-timezone';

import { useNavigate, useParams } from 'react-router-dom';

import { getSubmittedQuoteFormAt } from '~/helpers/quote/locallyStoredQuoteFormValues';
import { cleanLocallyStoredAllFormData } from '~/helpers/quote/locallyStoredValues';

import { LocalStorageKey } from '~/constants/localStorageKey';

export function useCheckForExistingQuote() {
  const navigate = useNavigate();

  const { quoteId } = useParams();

  const wasHandledRef = React.useRef<boolean>(false);

  React.useEffect(() => {
    const savedQuoteId = localStorage.getItem(LocalStorageKey.SavedQuoteId);

    // in case we have received a quote ID as a query parameter, we don't do anything
    // as the quote will be fetched from DB via useExternalQuoteRedirect hook
    if (quoteId) {
      if (savedQuoteId !== quoteId) {
        cleanLocallyStoredAllFormData();
      }

      return;
    }

    if (wasHandledRef.current) {
      return;
    }

    const submittedQuoteFormAt = getSubmittedQuoteFormAt();

    if (!savedQuoteId || !submittedQuoteFormAt) {
      // Do nothing
      return;
    }

    // TTL for the saved form data is 10 days
    const isOldFormData = moment(submittedQuoteFormAt).isBefore(moment().subtract(10, 'days'));

    if (isOldFormData) {
      // Something is wrong with the data we have set OR the form data is old enough
      // Need to clean this data
      cleanLocallyStoredAllFormData();

      return;
    }

    wasHandledRef.current = true;
    navigate(`/quote/${savedQuoteId}`, { replace: true });
  }, [navigate, quoteId]);
}
