import React from 'react';
import { Box, Flex, Stack, Text } from '@chakra-ui/react';

import { PolicyBenefitParagraphItem } from '~/components/customer-form/policy-display-plans-view/plan-benefits-section/policy-benefit-paragraph-item/PolicyBenefitParagraphItem';
import { ProductButtons } from '~/components/customer-form/product-buttons/ProductButtons';
import { PartnersBenefitsSection } from '~/components/customer-form/policy-display-plans-view/partners-benefits-section/PartnersBenefitsSection';

import { Colors } from '~/constants/colors';

interface PlanBenefitsSectionProps {
  benefits: string[];
}

export const PlanBenefitsSection: React.FC<PlanBenefitsSectionProps> = function PlanBenefitsSection({ benefits }) {
  return (
    <Flex
      direction="column"
      alignItems="center"
      height="100%"
      justify="space-between"
      width={{
        base: '332px',
        lg: '520px',
      }}
    >
      <Box>
        <Text fontSize="18px" lineHeight="32px" color={Colors.Brown} fontWeight="900">
          We&#39;ve got you covered
        </Text>

        <Stack spacing="8px" marginTop="20px" alignItems="flex-start">
          {benefits.map((benefit, idx) => (
            <PolicyBenefitParagraphItem key={idx} text={benefit} />
          ))}
          <Box paddingTop="12px">
            <ProductButtons style={{ justify: { base: 'center', lg: 'left' } }} />
          </Box>
        </Stack>
      </Box>

      <PartnersBenefitsSection style={{ display: { base: 'none', lg: 'none' } }} />
    </Flex>
  );
};
