import React from 'react';
import { useField } from 'formik';

import { PolicyCodes, ProductInfoLinksConfig, VetFees, getProductBenefitData } from '@joinfluffy/common';

import { POLICY_CODE_FIELD } from '~/components/customer-form/customer-form.schema';
import { useSelectedPolicyData } from '~/hooks/insurance-data-provider/policy/useSelectedPolicyData';

export function useInsuranceProductInformationLink() {
  const [selectedPolicyCodeField] = useField<PolicyCodes>(POLICY_CODE_FIELD);
  const selectedPlanData = useSelectedPolicyData(selectedPolicyCodeField.value);

  const productInformationLink = React.useMemo(() => {
    if (!selectedPlanData) {
      return;
    }

    const vetFeeValue = getProductBenefitData(selectedPlanData.productBenefits, 'VET_FEES');

    if (!vetFeeValue) {
      return;
    }

    return ProductInfoLinksConfig[vetFeeValue.amount as VetFees];
  }, [selectedPlanData]);

  return { productInformationLink };
}
