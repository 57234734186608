import React from 'react';
import { Stack } from '@chakra-ui/react';
import { useField } from 'formik';

import { FormikInput } from '~/components/form/formik-input/FormikInput';
import { ScreenWithTitleContainer } from '~/components/common/ScreenWithTitleContainer';
import { QuoteFormValues, OPTIONAL_PHONE_NUMBER_FIELD } from '~/components/quote-form/quote-form.schema';
import { quoteFormValidationSchema } from '~/components/quote-form/validation/quote-form.validation';

import { useKeyEnterScreenHandler } from '~/hooks/common/useKeyEnterScreenHandler';

export interface OptionalPhoneNumberQuestion {
  openNextStep: () => void;
}

export const OptionalPhoneNumberQuestion: React.FC<OptionalPhoneNumberQuestion> = function OptionalPhoneNumberQuestion({
  openNextStep,
}) {
  const [field, , formikHelper] =
    useField<QuoteFormValues[typeof OPTIONAL_PHONE_NUMBER_FIELD]>(OPTIONAL_PHONE_NUMBER_FIELD);

  const onNextQuestionOpen = React.useCallback(() => {
    // before moving onto the next question, clean up the phone number field if it's invalid
    try {
      quoteFormValidationSchema.validateSyncAt(OPTIONAL_PHONE_NUMBER_FIELD, {
        [OPTIONAL_PHONE_NUMBER_FIELD]: field.value,
      });
    } catch (e) {
      formikHelper.setValue('');
    }

    openNextStep();
  }, [field.value, formikHelper, openNextStep]);

  useKeyEnterScreenHandler({ actionToCall: onNextQuestionOpen });

  return (
    <ScreenWithTitleContainer
      title="Please provide your phone number (optional)"
      buttonLabel="Next step"
      onActionButtonClick={onNextQuestionOpen}
    >
      <Stack spacing="12px">
        <FormikInput
          name={OPTIONAL_PHONE_NUMBER_FIELD}
          placeholder="Phone number (optional)"
          type="text"
          variant="outline"
        />
      </Stack>
    </ScreenWithTitleContainer>
  );
};
