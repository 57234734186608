import { PolicyCodes } from '@joinfluffy/common';

import { Title } from '@shared/models/customer';
import { PaymentType } from '@shared/constants/paymentType';

import { CUSTOMER_TITLE_LIST } from '@shared/data/customer';

import { SelectOption } from '~/components/form/formik.schema';

export const CUSTOMER_TITLE_SELECTION_OPTION_LIST: SelectOption<Title>[] = CUSTOMER_TITLE_LIST.map((title) => {
  return {
    value: title,
    label: title,
  };
});

export interface PolicySummaryParameterSchema {
  displayKey: string;
  value: string;
}

export interface PolicyPricingSummarySchema {
  annualInsurancePrice: PolicySummaryParameterSchema;
  adminFee: PolicySummaryParameterSchema;
  totalAnnualPrice: PolicySummaryParameterSchema;
  discount?: PolicySummaryParameterSchema;
  monthlyPrice?: PolicySummaryParameterSchema;
}

export type CustomerFormQuestionType =
  | 'POLICY_DISPLAY_VIEW'
  | 'OWNER_INFO'
  | 'DATE_OF_OWNER_BIRTH'
  | 'POLICY_DECLARATION'
  | 'PAYMENT_TYPE_SELECTION'
  | 'PAYMENT_PAGE_LOADER';

export type CustomerFormField =
  | typeof POLICY_CODE_FIELD
  | typeof ARE_POLICY_AND_BUSINESS_DOCUMENTS_READ_FIELD
  | typeof ARE_ASSUMPTIONS_READ_FIELD
  | typeof TITLE_FIELD
  | typeof FIRST_NAME_FIELD
  | typeof LAST_NAME_FIELD
  | typeof PHONE_NUMBER_FIELD
  | typeof DATE_OF_OWNER_BIRTH_FIELD
  | typeof PAYMENT_TYPE_FIELD;

export const POLICY_CODE_FIELD = 'policyCode';
export const ARE_POLICY_AND_BUSINESS_DOCUMENTS_READ_FIELD = 'arePolicyAndBusinessDocumentRead';
export const ARE_ASSUMPTIONS_READ_FIELD = 'areAssumptionsRead';
export const TITLE_FIELD = 'title';
export const FIRST_NAME_FIELD = 'firstName';
export const LAST_NAME_FIELD = 'lastName';
export const PHONE_NUMBER_FIELD = 'phoneNumber';
export const DATE_OF_OWNER_BIRTH_FIELD = 'dateOfOwnerBirth';
export const PAYMENT_TYPE_FIELD = 'paymentType';

export interface CustomerFormValues {
  [POLICY_CODE_FIELD]: PolicyCodes;
  [ARE_POLICY_AND_BUSINESS_DOCUMENTS_READ_FIELD]: boolean;
  [ARE_ASSUMPTIONS_READ_FIELD]: boolean;
  [DATE_OF_OWNER_BIRTH_FIELD]: string;
  [TITLE_FIELD]: Title | null;
  [FIRST_NAME_FIELD]: string;
  [LAST_NAME_FIELD]: string;
  [PHONE_NUMBER_FIELD]: string;
  [PAYMENT_TYPE_FIELD]: PaymentType | null;
}

export const CUSTOMER_FORM_INITIAL_VALUE: CustomerFormValues = {
  [POLICY_CODE_FIELD]: PolicyCodes.LT2,
  [ARE_POLICY_AND_BUSINESS_DOCUMENTS_READ_FIELD]: false,
  [ARE_ASSUMPTIONS_READ_FIELD]: false,
  [DATE_OF_OWNER_BIRTH_FIELD]: '',
  [TITLE_FIELD]: null,
  [FIRST_NAME_FIELD]: '',
  [LAST_NAME_FIELD]: '',
  [PHONE_NUMBER_FIELD]: '',
  [PAYMENT_TYPE_FIELD]: null,
};

export type LocallyStoredCustomerFormValues = CustomerFormValues;
