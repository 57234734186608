import React from 'react';

import * as Sentry from '@sentry/react';

import { useNavigate } from 'react-router-dom';

import { ExternalPurchaseUrlSearchParams } from '@shared/constants/urlSearchParams';

import { ReferralCodeView } from '~/components/pages/thank-you-page/ReferralCodeView/ReferralCodeView';
import { MobileAppInfoView } from '~/components/pages/thank-you-page/MobileAppInfoView/MobileAppInfoView';

import { trackAdEvent, trackEvent } from '~/helpers/analytics/trackEvent';
import { fetchReferralCode } from '~/helpers/referralCode/fetchReferralCode';

import { useSendPurchaseConfirmationToAwin } from '~/hooks/awin/useSendPurchaseConfirmationToAwin';
import { useCleanSearchParamsByKey } from '~/hooks/useCleanSearchParamsByKey';
import { useQuoteRestart } from '~/hooks/useQuoteRestart';
import { useUiStateUpdateIsLoading } from '~/hooks/useUiStateUpdateIsLoading';
import { useInvokeModal } from '~/hooks/useInvokeModal';

import { AdEventType, AnalyticsEvent } from '~/constants/analyticsEvent';
import { LocalStorageKey } from '~/constants/localStorageKey';
import { Routes } from '~/constants/routes';
import { ModalTypes } from '~/constants/modalTypes';

export const ThankYouPage: React.FC = function ThankYouPage() {
  const [isRestartFormButtonDisabled, setIsRestartFormButtonDisabled] = React.useState<boolean>(true);
  const [userReferralLink, setUserReferralLink] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const updateUiIsLoading = useUiStateUpdateIsLoading();
  const { invokeModal } = useInvokeModal();

  React.useEffect(() => {
    const customerEmail = localStorage.getItem(LocalStorageKey.SavedCustomerEmail);

    if (!customerEmail) {
      setIsLoading(false);

      return;
    }

    updateUiIsLoading(true);

    Sentry.setUser({ email: customerEmail });

    fetchReferralCode(customerEmail)
      .then((link) => {
        if (link) {
          setUserReferralLink(link);

          trackEvent({ eventName: AnalyticsEvent.QuoteReferralLinkGenerated });
        }
      })
      .finally(() => {
        updateUiIsLoading(false);
        setIsLoading(false);
      });
  }, [updateUiIsLoading]);

  const openReferralLinkModal = React.useCallback(() => {
    trackEvent({ eventName: AnalyticsEvent.QuoteOpenReferralLinkModalButtonClicked });

    invokeModal(ModalTypes.ReferralLink, { link: userReferralLink });
  }, [invokeModal, userReferralLink]);

  const navigate = useNavigate();

  const cleanSearchParams = useCleanSearchParamsByKey();

  const openQuoting = React.useCallback(() => {
    navigate(Routes.Quote, { replace: true });
  }, [navigate]);

  const restartQuote = useQuoteRestart(openQuoting);

  const onAwinTrackingFinish = React.useCallback(() => {
    setIsRestartFormButtonDisabled(false);
    cleanSearchParams(ExternalPurchaseUrlSearchParams.PolicyNo);
  }, [cleanSearchParams]);

  useSendPurchaseConfirmationToAwin({ onFinish: onAwinTrackingFinish });

  React.useEffect(() => {
    if (localStorage.getItem(LocalStorageKey.SubmittedCustomerFormAt)) {
      trackAdEvent(AdEventType.Purchase);
      trackEvent({ eventName: AnalyticsEvent.QuoteNavigatedBackToThankYouPage });
      localStorage.removeItem(LocalStorageKey.SubmittedCustomerFormAt);
    }
  }, []);

  if (isLoading) {
    return null;
  }

  return userReferralLink ? (
    <ReferralCodeView
      isRestartFormButtonDisabled={isRestartFormButtonDisabled}
      restartQuote={restartQuote}
      openReferralLinkModal={openReferralLinkModal}
    />
  ) : (
    <MobileAppInfoView restartQuote={restartQuote} isRestartFormButtonDisabled={isRestartFormButtonDisabled} />
  );
};
