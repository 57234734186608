export enum Colors {
  Brown = '#593822',
  SecondaryBrown = 'rgba(89, 56, 34, 0.8)',
  Beige = '#fdecdd',
  Black = '#000',
  White = '#fff',
  Orange = '#e98310',
  OrangePale = 'rgba(233, 131, 16, 0.12)',
  GreyLight = 'rgba(89, 56, 34, 0.06)',
  GreySemiLight = 'rgba(89, 56, 34, 0.32)',
  Grey500 = '#737373',
  GreyPale = '#af9b8f',
  Grey = 'rgba(255, 255, 255, 0.64)',
  PrimaryBackground = '#fff7f4',
  Red = '#ff5016',
  DarkOverlay = 'rgba(9, 0, 0, 0.44)',
  Transparent = 'transparent',
  PaleBrow = 'rgba(89, 56, 34, 0.48)',
}
