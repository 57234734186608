import React from 'react';

import { getAnalyticsBrowser } from '~/helpers/analytics/analytics';
import { trackVwoExperimentVariation } from '~/helpers/analytics/trackVwoExperimentVariation';
import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';
import { wait } from '~/helpers/common/wait';

const VWO_INIT_TIME_MS = 4000;

/**
 * **Important**:
 * After updates here, don't forget to update WIX template: "static-templates/wix/vwo-experiments-tracking.js"
 */
export const VwoTrackingProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  React.useEffect(() => {
    async function trackAllRunningExperiments() {
      await wait(VWO_INIT_TIME_MS);

      const expIds = window._vwo_exp_ids;
      if (!expIds || !window._vwo_exp) {
        return;
      }

      const analyticsBrowser = getAnalyticsBrowser();
      if (!analyticsBrowser) {
        captureAndLogException('[VWO tracking]: Analytics browser is not initialized', 'Warn');
        return;
      }

      const user = await analyticsBrowser.user();
      const userId = user.id() ?? user.anonymousId();

      expIds.forEach((expId) => {
        const expDetails = window._vwo_exp![expId];
        if (!expDetails || expDetails.status !== 'RUNNING' || !expDetails.combination_chosen) {
          // Experiment does not exist, is not running or have no chosen combination
          return;
        }

        const expName = expDetails.name;
        const variationId = expDetails.combination_chosen;
        const variationName = expDetails.comb_n[variationId];

        trackVwoExperimentVariation(analyticsBrowser, userId, { id: expId, name: expName, variationId, variationName });
      });
    }

    trackAllRunningExperiments();
  }, []);

  return <>{children}</>;
};
