import React from 'react';
import { useField } from 'formik';

import { InputProps } from '@chakra-ui/input/dist/input';

import { ActionButton } from '~/components/common/ActionButton';
import { FormikInput } from '~/components/form/formik-input/FormikInput';
import { TitleText } from '~/components/common/TitleText';
import { QuestionStack } from '~/components/common/QuestionStack';

interface TextInputQuestionProps extends InputProps {
  name: string; // is used to bind this question with Formik
  questionText: string; // label for question input
  placeholder?: string;
  actionButtonLabel: string;
  type?: InputProps['type'];
  onActionButtonClick: () => void;
  inputFormatter?: (input: string) => string;
}

export const TextInputQuestion: React.FC<TextInputQuestionProps> = function TextInputQuestion({
  name,
  questionText,
  placeholder,
  actionButtonLabel,
  type = 'text',
  onActionButtonClick,
  inputFormatter,
  ...inputProps
}) {
  const [, meta] = useField(name);

  // todo [INSR-67]: Refactor Text input components
  return (
    <QuestionStack>
      <TitleText text={questionText} />
      <FormikInput
        name={name}
        placeholder={placeholder}
        type={type}
        variant="flushed"
        size="md"
        {...inputProps}
        inputFormatter={inputFormatter}
        keyEnterHandler={onActionButtonClick}
      />

      <ActionButton
        label={actionButtonLabel}
        onPress={onActionButtonClick}
        disabled={Boolean(!meta.value || meta.error)}
      />
    </QuestionStack>
  );
};
