import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';

import { LocallyStoredCustomerFormValues } from '~/components/customer-form/customer-form.schema';

import { LocalStorageKey } from '~/constants/localStorageKey';

export function saveCustomerFormValuesLocally(formValues: LocallyStoredCustomerFormValues) {
  localStorage.setItem(LocalStorageKey.SavedCustomerFormValues, JSON.stringify(formValues));
}

export function getCustomerFormValuesStoredLocally(): LocallyStoredCustomerFormValues | null {
  let result: LocallyStoredCustomerFormValues | null = null;
  try {
    const storageItem = localStorage.getItem(LocalStorageKey.SavedCustomerFormValues);

    if (storageItem) {
      result = JSON.parse(storageItem);
    }
  } catch (err) {
    captureAndLogException(
      `Error while parsing stored locally customer form: "${JSON.stringify(err instanceof Error ? err.message : err)}"`,
      'Error',
    );
  }

  return result;
}

export function cleanLocallyStoredCustomerFormData() {
  localStorage.removeItem(LocalStorageKey.SubmittedCustomerFormAt);
  localStorage.removeItem(LocalStorageKey.SavedCustomerFormValues);
  localStorage.removeItem(LocalStorageKey.SelectedProductAnnualPremium);
}
