import React from 'react';

import { ErrorEvent as SentryErrorEvent } from '@sentry/types';

import * as Sentry from '@sentry/react';

import { BrowserTracing } from '@sentry/tracing';

const BROWSER_TRACING = new BrowserTracing();
const DSN = 'https://2a5bd861f0c24096b37573b29cd67d65@o1361432.ingest.sentry.io/4504672202981376';

const UNHANLED_REJECTION_ERROR_TYPE_VALUE = 'UnhandledRejection';
const UNHANLED_REJECTION_ERROR_VALUE = 'Non-Error promise rejection captured with value: ';

const beforeSentryEventSend = (event: SentryErrorEvent) => {
  if (event && event.exception && event.exception.values && event.exception.values.length == 1) {
    const e = event.exception.values[0];

    if (e.type === UNHANLED_REJECTION_ERROR_TYPE_VALUE && e.value === UNHANLED_REJECTION_ERROR_VALUE) {
      return null;
    }
  }

  return event;
};

export function useInitMonitoring() {
  const [initFinished, setInitFinished] = React.useState<boolean>(false);

  React.useEffect(() => {
    Sentry.init({
      dsn: DSN,
      integrations: [BROWSER_TRACING],
      tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
      environment: process.env.REACT_APP_ENVIRONMENT,
      beforeSend: beforeSentryEventSend,
    });

    setInitFinished(true);
  }, []);

  return { initFinished };
}
