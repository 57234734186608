import type { CatOrDog, PetGender } from '@joinfluffy/common';

import { PreferredBenefitCode } from '~/configs/preferredBenefits';

import { QUOTE_FORM_QUESTION_ORDER_CONFIG } from '~/configs/questionsOrder/questionsOrder';

export const LAST_QUESTION_STEP_IDX = QUOTE_FORM_QUESTION_ORDER_CONFIG.length - 1;

export const quoteFormPetQuestions = [
  'PET_NAME',
  'PET_TYPE',
  'PET_GENDER',
  'PET_BREED',
  'PET_BIRTH_DATE',
  'IS_PET_NEUTERED',
  'PET_VALUE',
  'IS_PET_MICROCHIPPED',
] as const;

export const quoteFormCustomerQuestions = [
  'EMAIL',
  'LOOKUP_ADDRESS',
  'HAS_ALREADY_A_POLICY',
  'IS_PET_OWNER_OVER_EIGHTEEN',
  'PREFERRED_BENEFITS',
  'OPTIONAL_PHONE_NUMBER',
  'HAS_MULTIPLE_PETS',
  'PET_POLICY_START_DATE',
  'EXISTING_POLICY_FINISH_DATE',
] as const;

export type QuoteFormPetQuestionType = (typeof quoteFormPetQuestions)[number];
export type QuoteFormCustomerQuestionType = (typeof quoteFormCustomerQuestions)[number];

export type QuoteFormQuestionType = QuoteFormPetQuestionType | QuoteFormCustomerQuestionType;

export const HAS_ALREADY_INSURANCE_POLICY = 'hasAlreadyInsurancePolicy';
export const POLICY_START_DATE_FIELD = 'policyStartDateField';
export const EMAIL_FIELD = 'email';
export const HAS_MULTIPLE_PETS_FIELD = 'hasMultiplePets';
export const EXISTING_POLICY_FINISH_DATE = 'existingPolicyFinishDate';
export const PET_DATE_OF_BIRTH_FIELD = 'dateOfBirth';
export const PET_NAME_FIELD = 'petName';
export const CAT_OR_DOG_FIELD = 'catOrDog';
export const GENDER_FIELD = 'gender';
export const PET_BREED_FIELD = 'petBreed';
export const IS_NEUTERED_FIELD = 'isNeutered';
export const IS_OVER_EIGHTEEN_FIELD = 'isOverEighteen';
export const PET_VALUE_FIELD = 'petValue';
export const POSTCODE_FIELD = 'postCode';
export const ADDRESS_LINE_1_FIELD = 'addressLine1';
export const ADDRESS_LINE_2_FIELD = 'addressLine2';
export const ADDRESS_LINE_3_FIELD = 'addressLine3';
export const OPTIONAL_PHONE_NUMBER_FIELD = 'optionalPhoneNumber';
export const IS_MICROCHIPPED_FIELD = 'isMicroChipped';
export const CURRENT_PET_IDX_FIELD = 'currentPetIdx';
export const PREFERRED_BENEFITS_LIST = 'preferredBenefitsList';

export type QuoteFormPetField =
  | typeof PET_DATE_OF_BIRTH_FIELD
  | typeof PET_NAME_FIELD
  | typeof CAT_OR_DOG_FIELD
  | typeof GENDER_FIELD
  | typeof PET_BREED_FIELD
  | typeof IS_NEUTERED_FIELD
  | typeof PET_VALUE_FIELD
  | typeof IS_MICROCHIPPED_FIELD;

export type QuoteFormCustomerField =
  | typeof EMAIL_FIELD
  | typeof POLICY_START_DATE_FIELD
  | typeof POSTCODE_FIELD
  | typeof ADDRESS_LINE_1_FIELD
  | typeof ADDRESS_LINE_2_FIELD
  | typeof ADDRESS_LINE_3_FIELD
  | typeof IS_OVER_EIGHTEEN_FIELD
  | typeof HAS_ALREADY_INSURANCE_POLICY
  | typeof OPTIONAL_PHONE_NUMBER_FIELD
  | typeof HAS_MULTIPLE_PETS_FIELD
  | typeof EXISTING_POLICY_FINISH_DATE
  | typeof PREFERRED_BENEFITS_LIST;

export type QuoteFormField = QuoteFormPetField | QuoteFormCustomerField;

export const CURRENT_QUESTION_STEP_IDX = 'currentQuestionStepIdx';

export type QuoteFormPetValues = {
  [PET_NAME_FIELD]: string;
  [CAT_OR_DOG_FIELD]: CatOrDog | null;
  [GENDER_FIELD]: PetGender | null;
  [PET_BREED_FIELD]: string;
  [IS_NEUTERED_FIELD]: boolean | null;
  [PET_VALUE_FIELD]: string;
  [PET_DATE_OF_BIRTH_FIELD]: string;
  [IS_MICROCHIPPED_FIELD]: boolean | null;
};

export type QuoteFormCustomerValues = {
  [POSTCODE_FIELD]: string; // example: E3 4EF
  [ADDRESS_LINE_1_FIELD]: string;
  [ADDRESS_LINE_2_FIELD]: string;
  [ADDRESS_LINE_3_FIELD]: string;
  [POLICY_START_DATE_FIELD]: string | null;
  [EMAIL_FIELD]: string;
  [IS_OVER_EIGHTEEN_FIELD]: boolean | null;
  [HAS_ALREADY_INSURANCE_POLICY]?: boolean;
  [HAS_MULTIPLE_PETS_FIELD]: string;
  [EXISTING_POLICY_FINISH_DATE]: string | null;
  [CURRENT_QUESTION_STEP_IDX]: number;
  [OPTIONAL_PHONE_NUMBER_FIELD]: string;
  [CURRENT_PET_IDX_FIELD]: number;
  [PREFERRED_BENEFITS_LIST]: PreferredBenefitCode[];
};

export type QuoteFormValues = QuoteFormPetValues & QuoteFormCustomerValues;

export const QUOTE_FORM_PET_INITIAL_VALUES: QuoteFormPetValues = {
  [PET_NAME_FIELD]: '',
  [CAT_OR_DOG_FIELD]: null,
  [GENDER_FIELD]: null,
  [PET_BREED_FIELD]: '',
  [IS_NEUTERED_FIELD]: null,
  [PET_VALUE_FIELD]: '',
  [PET_DATE_OF_BIRTH_FIELD]: '',
  [IS_MICROCHIPPED_FIELD]: null,
};

export const QUOTE_FORM_CUSTOMER_INITIAL_VALUES: QuoteFormCustomerValues = {
  [POSTCODE_FIELD]: '',
  [ADDRESS_LINE_1_FIELD]: '',
  [ADDRESS_LINE_2_FIELD]: '',
  [ADDRESS_LINE_3_FIELD]: '',
  [EMAIL_FIELD]: '',
  [IS_OVER_EIGHTEEN_FIELD]: null,
  [POLICY_START_DATE_FIELD]: null,
  [HAS_ALREADY_INSURANCE_POLICY]: undefined,
  [OPTIONAL_PHONE_NUMBER_FIELD]: '',
  [HAS_MULTIPLE_PETS_FIELD]: '',
  [EXISTING_POLICY_FINISH_DATE]: null,
  [CURRENT_QUESTION_STEP_IDX]: 0,
  [CURRENT_PET_IDX_FIELD]: 0,
  [PREFERRED_BENEFITS_LIST]: [],
};

export const FORM_INITIAL_VALUE: QuoteFormValues = {
  ...QUOTE_FORM_PET_INITIAL_VALUES,
  ...QUOTE_FORM_CUSTOMER_INITIAL_VALUES,
};

export const FilledPreviousluQuoteFormCustomerNecessaryFields: Partial<QuoteFormCustomerField>[] = [
  POSTCODE_FIELD,
  ADDRESS_LINE_1_FIELD,
  EMAIL_FIELD,
  POLICY_START_DATE_FIELD,
];

export const FilledPreviousluQuoteFormPetNecessaryFields: Partial<QuoteFormPetField>[] = [
  PET_NAME_FIELD,
  PET_BREED_FIELD,
  CAT_OR_DOG_FIELD,
  GENDER_FIELD,
  IS_NEUTERED_FIELD,
  PET_VALUE_FIELD,
  PET_DATE_OF_BIRTH_FIELD,
  IS_MICROCHIPPED_FIELD,
];
