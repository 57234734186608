import React, { SVGProps } from 'react';
import DatePicker from 'react-date-picker/dist/entry.nostyle';
import { useField } from 'formik';
import { Box } from '@chakra-ui/react';

import moment from 'moment-timezone';

import { INTERNAL_DATE_FORMAT } from '@joinfluffy/common';

import { QuoteFormValues, POLICY_START_DATE_FIELD } from '~/components/quote-form/quote-form.schema';

import { Colors } from '~/constants/colors';

import { getMomentFromLocaleDate, getTodaysDate } from '~/helpers/date/date';

import { ReactComponent as ArrowRightIcon } from '~/assets/icons/arrow-right.svg';
import { ReactComponent as ArrowLeftIcon } from '~/assets/icons/arrow-left.svg';

import '~/components/quote-form/policy-start-date-question-view/policy-start-date-calendar-input/policyStartDateCalendarInput.scss';

const DAY_NAMES_LABEL_LIST = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
const TODAY_DATE = getTodaysDate();
const MIN_CALENDAR_DATE = TODAY_DATE.toDate();

interface PickerControlButtonProps {
  Icon: React.FC<SVGProps<SVGSVGElement>>;
}

interface PolicyStartDateCalendarInput {
  maxValue: Date;
}

export const PolicyStartDateCalendarInput: React.FC<PolicyStartDateCalendarInput> =
  function PolicyStartDateCalendarInput({ maxValue }) {
    const [field, , formikHelper] = useField<QuoteFormValues[typeof POLICY_START_DATE_FIELD]>(POLICY_START_DATE_FIELD);

    const setStartPolicyDate = React.useCallback(
      (date: Date) => {
        formikHelper.setTouched(true);
        formikHelper.setValue(getMomentFromLocaleDate(date).format(INTERNAL_DATE_FORMAT));
      },
      [formikHelper],
    );

    const formatShortNameWeekday = React.useCallback((_: string, date: Date) => {
      return DAY_NAMES_LABEL_LIST[date.getDay()];
    }, []);

    return (
      <Box className="policy-start-date" width="336px" height="337px">
        <DatePicker
          isOpen
          value={field.value ? moment(field.value, INTERNAL_DATE_FORMAT).toDate() : null}
          onChange={setStartPolicyDate}
          minDate={MIN_CALENDAR_DATE}
          maxDate={maxValue}
          prevLabel={<PickerControlButton Icon={ArrowLeftIcon} />}
          nextLabel={<PickerControlButton Icon={ArrowRightIcon} />}
          formatShortWeekday={formatShortNameWeekday}
        />
      </Box>
    );
  };

const PickerControlButton: React.FC<PickerControlButtonProps> = function PickerControlButton({ Icon }) {
  return (
    <Box width="9px" height="17px">
      <Icon width="100%" height="100%" color={Colors.Black} />
    </Box>
  );
};
