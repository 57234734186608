import React from 'react';

import { BaseModal } from '~/components/common/BaseModal';

import { ModalTypes } from '~/constants/modalTypes';

const IMAGE_PATH = '/images/dogImages/12-playful-dog/12-playful-dog-1080.png';

interface GeneralConfirmationModalProps {
  isOpen: boolean;
  title: string;
  description: string;
  actionButtonLabel?: string;
  hideSecondaryOption?: boolean;
  imgSrc?: string;
  onClose: () => void;
  confirmationHandler?: () => void;
}

export const GeneralConfirmationModal: React.FC<GeneralConfirmationModalProps> = function GeneralConfirmationModal({
  isOpen,
  title,
  description,
  actionButtonLabel,
  hideSecondaryOption,
  imgSrc,
  onClose,
  confirmationHandler,
}) {
  const onActionButtonPress = React.useCallback(() => {
    confirmationHandler?.();
    onClose();
  }, [confirmationHandler, onClose]);

  return (
    <BaseModal
      type={ModalTypes.GeneralConfirmation}
      isOpen={isOpen}
      imgSrc={imgSrc ?? IMAGE_PATH}
      title={title}
      description={description}
      onClose={onClose}
      actionButtonLabel={actionButtonLabel ?? 'Confirm'}
      onActionButtonPress={onActionButtonPress}
      secondaryActionButtonLabel={hideSecondaryOption ? undefined : 'Close'}
      onSecondaryActionButtonPress={hideSecondaryOption ? undefined : onClose}
    />
  );
};
