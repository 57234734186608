import type { CustomerFormQuestionType } from '~/components/customer-form/customer-form.schema';
import {
  type QuoteFormField,
  type QuoteFormQuestionType,
  type QuoteFormValues,
} from '~/components/quote-form/quote-form.schema';

export interface GetQuoteFormStepIdxToOpenParams {
  currentQuestionIdx: number;
  formValues: QuoteFormValues;
  direction: MovementDirection;
  currentBinaryQuestionAnswer?: GetQuoteFormStepIdxToOpenCurrentBinaryAnswerParam;
}

export interface GetQuoteFormStepIdxToOpenCurrentBinaryAnswerParam {
  field: QuoteFormField;
  value: QuoteFormValues[keyof QuoteFormValues];
}

interface DependentQuestionVisibilityConfig {
  dependentOnFieldName: QuoteFormField;
  dependentOnFieldValueToBeVisible: QuoteFormValues[keyof QuoteFormValues];
}

type MovementDirection = 'NEXT' | 'PREVIOUS';

export const QUOTE_FORM_QUESTION_ORDER_CONFIG: QuoteFormQuestionType[] = [
  'PET_NAME',
  'PET_TYPE',
  'PET_GENDER',
  'PET_BREED',
  'IS_PET_MICROCHIPPED',
  'PET_BIRTH_DATE',
  'PET_VALUE',
  'IS_PET_NEUTERED',
  'HAS_MULTIPLE_PETS',
  'LOOKUP_ADDRESS',
  'PET_POLICY_START_DATE',
  'IS_PET_OWNER_OVER_EIGHTEEN',
  'PREFERRED_BENEFITS',
  'HAS_ALREADY_A_POLICY',
  'EXISTING_POLICY_FINISH_DATE',
  'OPTIONAL_PHONE_NUMBER',
  'EMAIL',
];

export const DEPENDENT_QUESTIONS_VISIBILITY_CONFIG: Partial<
  Record<QuoteFormQuestionType, DependentQuestionVisibilityConfig>
> = {
  EXISTING_POLICY_FINISH_DATE: {
    dependentOnFieldName: 'hasAlreadyInsurancePolicy',
    dependentOnFieldValueToBeVisible: true,
  },
};

export const CUSTOMER_FORM_QUESTION_ORDER_CONFIG: CustomerFormQuestionType[] = [
  'POLICY_DISPLAY_VIEW',
  'OWNER_INFO',
  'DATE_OF_OWNER_BIRTH',
  'POLICY_DECLARATION',
  'PAYMENT_TYPE_SELECTION',
  'PAYMENT_PAGE_LOADER',
];

export function getQuoteFormStepIdxToOpen({
  currentQuestionIdx,
  formValues,
  currentBinaryQuestionAnswer,
  direction,
}: GetQuoteFormStepIdxToOpenParams) {
  let nextQuestionIdxToOpen = currentQuestionIdx;

  if (direction === 'NEXT') {
    nextQuestionIdxToOpen++;
  } else if (direction === 'PREVIOUS') {
    nextQuestionIdxToOpen--;
  }

  // Loop through the question order config to find the first applicable index
  while (nextQuestionIdxToOpen >= 0 && nextQuestionIdxToOpen < QUOTE_FORM_QUESTION_ORDER_CONFIG.length) {
    const nextQuestionName = QUOTE_FORM_QUESTION_ORDER_CONFIG[nextQuestionIdxToOpen];
    const nextQuestionDependencyConfig = DEPENDENT_QUESTIONS_VISIBILITY_CONFIG[nextQuestionName];

    if (!nextQuestionDependencyConfig) {
      return nextQuestionIdxToOpen;
    }

    const { dependentOnFieldName, dependentOnFieldValueToBeVisible } = nextQuestionDependencyConfig;

    const dendentOnFieldValue =
      direction === 'NEXT' && currentBinaryQuestionAnswer?.field === dependentOnFieldName
        ? currentBinaryQuestionAnswer.value
        : formValues[dependentOnFieldName];

    if (dendentOnFieldValue === dependentOnFieldValueToBeVisible) {
      return nextQuestionIdxToOpen;
    }

    if (direction === 'PREVIOUS') {
      nextQuestionIdxToOpen--;
    } else {
      nextQuestionIdxToOpen++;
    }
  }

  return nextQuestionIdxToOpen;
}
