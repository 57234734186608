import { CAT_OR_DOG_FIELD, QuoteFormPetValues } from '~/components/quote-form/quote-form.schema';

const CHAMPION_DOG_IMAGE_PATH = '/images/dogImages/24-champion-dog/24-champion-dog-500.png';
const CLEVER_DOG_IMAGE_PATH = '/images/dogImages/01-clever-dog/01-clever-dog-208.png';
const WELCOME_DOG_IMAGE_PATH = '/images/dogImages/11-welcome-dog/11-welcome-dog-208.png';
const LEAD_HANDLING_DOG_IMAGE_PATH = '/images/dogImages/33-lead-handling-dog/33-lead-handling-dog-208.png';

const CLEVER_CAT_IMAGE_PATH = '/images/cat-images/8.png';
const SITTING_CAT_IMAGE_PATH = '/images/cat-images/3.png';
const JUMPING_CAT_IMAGE_PATH = '/images/cat-images/5.png';
const NOT_REALLY_CUTE_CAT_IMAGE_PATH = '/images/cat-images/1.png';

const DOG_IMAGES = [
  CHAMPION_DOG_IMAGE_PATH,
  CLEVER_DOG_IMAGE_PATH,
  WELCOME_DOG_IMAGE_PATH,
  LEAD_HANDLING_DOG_IMAGE_PATH,
];
const CAT_IMAGES = [
  CLEVER_CAT_IMAGE_PATH,
  SITTING_CAT_IMAGE_PATH,
  JUMPING_CAT_IMAGE_PATH,
  NOT_REALLY_CUTE_CAT_IMAGE_PATH,
];

export function getPetIconPath(pets: QuoteFormPetValues[], currentPetIdx: number) {
  const pet = pets[currentPetIdx];
  const petType = pet[CAT_OR_DOG_FIELD];

  if (!pet || !petType) {
    return;
  }

  let currentPetIdxWithinPetType = 0;

  for (let idx = 0; idx <= currentPetIdx; idx++) {
    if (pets[idx][CAT_OR_DOG_FIELD] === petType) {
      currentPetIdxWithinPetType++;
    }
  }

  const imagePathSource = pet[CAT_OR_DOG_FIELD] === 'cat' ? CAT_IMAGES : DOG_IMAGES;

  const selectedImageIndex = (currentPetIdxWithinPetType - 1) % 4;

  return imagePathSource[selectedImageIndex];
}
